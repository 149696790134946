import React, { useState } from 'react';
import axios from 'axios';

const DeleteAccount = () => {
  const [reason, setReason] = useState('');
  const [userId, setUserId] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.greattr.com/api/deleteAccount', {
        userId,
        reason
      });

      if (response.status === 200) {
        setSubmitted(true);
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('Something went wrong. Please try again later.');
    }
  };

  if (submitted) {
    return <p>Thank you! Your account deletion request has been submitted.</p>;
  }

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h2>Delete Account</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="userId" style={{ display: 'block', marginBottom: '5px' }}>User ID:</label>
          <input
            type="text"
            id="userId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
            required
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="reason" style={{ display: 'block', marginBottom: '5px' }}>Reason for deletion:</label>
          <textarea
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
            required
          ></textarea>
        </div>
        <button type="submit" style={{ padding: '10px 20px', cursor: 'pointer' }}>Submit</button>
      </form>
    </div>
  );
};

export default DeleteAccount;
