import React, { useState } from "react";
import "./DistCompanyInfo.scss";
import greattrlogo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GESRecords } from "../../Utilsx/Encrypt";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const DistCompanyInfo = () => {
  const location = useLocation();

  const [locationState, setLocationState] = useState(() => {
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  const [agencyName, setAgencyName] = useState("");
  const [parentCompanyName, setParentCompanyName] = useState(
    locationState.parentCompany || ""
  );
  const [subEntity, setSubEntity] = useState("");
  const [merchantDistId, setMerchantDistId] = useState("");
  const [greattrDistributorID, setgreattrDistributorID] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  console.log(locationState);

  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission
    console.log({
      agencyName,
      subEntity,
      merchantDistId,
      parentCompanyName,
    });

    const submitData = {
      mobile: locationState?.MobileNumber ?? "8779341486",
      GreattrDistID: merchantDistId
        ? "GR" + parentCompanyName.slice(0, 2) + merchantDistId
        : "",
      agencyName,
      subEntity,
      merchantDistId,
      parentCompanyName,
      LinkingDistID: greattrDistributorID,
    };
    const EncrytedSubmitData = GESRecords(submitData, apiEN);
    try {
      setisLoading(true);
      let route =
        subEntity === "Dealer" || subEntity === "Urjadevi"
          ? "/CreateMerchantProfileDealer"
          : "/CreateMerchantProfileDistributor";
      // alert(route);
      let role =
        subEntity === "Dealer" || subEntity === "Urjadevi" ? "Dealer" : "Dist";
      // alert(role);

      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}${route}`,
        EncrytedSubmitData
      );
      if (response.data.duplicate) {
        alert(response.data.message);
      } else {
        alert(response.data.message);

        navigate("/DistBusinessDetails", {
          state: {
            ...locationState,
            role: role,
            agencyName,
            parentCompanyName: parentCompanyName,
            GreattrDealerID: response?.data?.GreattrDealerID,
            GreattrDistID: response?.data?.GreattrDistID,
          },
        });
      }
    } catch (error) {
      console.log(
        error.response?.data?.message ||
          "An error occurred while submitting the form."
      );
    } finally {
      setisLoading(false);
    }
  };

  return (
    <div className="DistCompanyInfo-company-info">
      <div className="DistCompanyInfo-company-info-container">
        <img className="greattrlogo" src={greattrlogo} alt="" />
        <p>1 / 6</p>
        <h3>Company Information</h3>
        <h4>Please enter your company information</h4>
        <form onSubmit={handleSubmit}>
          <div className="DistCompanyInfo-form-group">
            <label htmlFor="parentCompanyName">Parent Company Name</label>
            <select
              id="parentCompanyName"
              name="parentCompanyName"
              value={parentCompanyName}
              onChange={(e) => setParentCompanyName(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Company Name
              </option>
              <option value="BPCL">Bharat Petroleum</option>
              <option value="HPCL">Hindustan Petroleum</option>
              <option value="TOTAL">Total Energy</option>
              <option value="IOCL">Indian Oil</option>

              {/* Add more options as needed */}
            </select>
          </div>
          <div className="DistCompanyInfo-form-group">
            <label htmlFor="subEntity">Sub - Entity*</label>
            <select
              id="subEntity"
              name="subEntity"
              value={subEntity}
              onChange={(e) => setSubEntity(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Sub - Entity
              </option>
              <option value="Dist">Distributor</option>
              {parentCompanyName !== "BPCL" && (
                <option value="Dealer">Dealer</option>
              )}
              {parentCompanyName === "BPCL" && (
                <option value="Urjadevi">Urjadevi</option>
              )}

              {/* Add more options as needed */}
            </select>
          </div>
          {subEntity === "Dist" && parentCompanyName !== "TOTAL" && (
            <div className="DistCompanyInfo-form-group">
              <label htmlFor="merchantDistId">Merchant/Distributor ID</label>
              <input
                type="text"
                id="merchantDistId"
                name="merchantDistId"
                placeholder="Enter Merchant/Distributor ID"
                value={merchantDistId}
                onChange={(e) => setMerchantDistId(e.target.value)}
                required
              />
            </div>
          )}
          <div className="DistCompanyInfo-form-group">
            <label htmlFor="agencyName">Agency Name</label>
            <input
              type="text"
              id="agencyName"
              name="agencyName"
              placeholder="Enter Agency Name"
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
              required
            />
          </div>

          {subEntity !== "Dist" && (
            <div className="DistCompanyInfo-form-group">
              <label htmlFor="merchantDistId">Greattr Distributor ID</label>
              <input
                type="text"
                id="merchantDistId"
                name="merchantDistId"
                placeholder="Enter Greattr Distributor ID"
                value={greattrDistributorID}
                onChange={(e) => setgreattrDistributorID(e.target.value)}
                required
              />
            </div>
          )}

          <button className="DistCompanyInfoBtn" type="submit">
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Continue"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DistCompanyInfo;
