import "./OperatorCustomerList.scss";

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
// import { Bar } from 'react-chartjs-2';
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { GDSRecords } from "../Utilsx/Encrypt";
const apiEN = process.env.REACT_APP_API_apiEN;
const apiDE = process.env.REACT_APP_API_apiDE;
const CustomerCard = ({ customer }) => {
  return (
    <div className="customer-card">
      <div className="customer-card-header">
        <h2 style={{ textAlign: "start" }}>{customer["Consumer Name"]}</h2>
        <span className={`status ${customer?.status?.toLowerCase()}`}>
          {customer.status}
        </span>
      </div>
      <div className="customer-card-body">
        <div className="rowx">
          <p className="ptagx">
            <strong>Mob No:</strong> {customer["Mobile No"]}
          </p>
          <p className="ptagx">
            <strong>LPG ID:</strong> {customer["LPGID"]}
          </p>
        </div>
        <div className="rowx">
          <p className="ptagx">
            <strong>Area Name:</strong> {customer["Area Name"]}
          </p>
          <p className="ptagx">
            <strong>Area ID:</strong> {customer["Area ID"]}
          </p>
        </div>
        <p className="ptagx">
          <strong>Address:</strong>{" "}
          {customer.Address1 +
            " " +
            customer.Address2 +
            " " +
            customer.Address3}
        </p>
      </div>
    </div>
  );
};
const OperatorCustomerList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("pending");
  const [data, setdata] = useState([
    // Add more customer objects here
  ]);
  const [checkedItems, setCheckedItems] = useState({
    safetyCheck: false,
    eKYC: false,
    hoseReplacement: false,
  });
  console.log(checkedItems);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const [Areas, setAreas] = useState([]);

  const [show, setShow] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const startSurvey = () => {
    navigate("/survey", {
      state: {
        ...location.state,
        checkedItems,
        selectedCustomer,
      },
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = (customer) => {
    setShow(true);
    setSelectedCustomer(customer);
  };

  const filtereddata = data;
  const [SelectedArea, setSelectedArea] = useState("");

  const handleSelect = (event) => {
    const value = event.target.value;
    if (value) {
      setSelectedArea(value);
      fetchData(status, value);
    } else {
      setSelectedArea(null);
      fetchData(status, null);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [columns, setcolumns] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pendingCount, setpendingCount] = useState(0);
  const [completedCount, setcompletedCount] = useState(0);
  const fetchData = async (statusx, area, searchx) => {
    setIsLoading(true);
    console.log(process.env.REACT_APP_API_LINK);
    console.log(process.env.REACT_APP_API_apiDE);

    await axios({
      url: `${process.env.REACT_APP_API_LINK}/prtp/getSpecificOperatorCustomerList`,
      method: "POST",
      responseType: "json",
      data: {
        GreattrOperatorId: localStorage.getItem("GreattrOperatorId"),
        status: statusx ? statusx : status,
        search: searchx ? searchx : search,
        area: area ? area : SelectedArea,
      },
    }).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        console.log(Object.keys(GDSRecords(response?.data?.data, apiDE)[0]));
        setTotalCount(response.data.totalCount);
        setAreas(response.data.Areas);
        setdata(GDSRecords(response.data.data, apiDE));
        console.log(GDSRecords(response.data.data, apiDE));
        var cols = Object.keys(GDSRecords(response?.data?.data, apiDE)[0]);
        console.log(cols);
        console.log(typeof cols);
        setcolumns(cols);
        // Update the 'data' state with the newly fetched data

        // Initialize variables for each status count
        let completedCountx = 0;
        let pendingCountx = 0;

        // Loop through the array and assign the counts to the variables
        response.data.StatusCount.forEach((item) => {
          if (item.status === "completed") {
            completedCountx = item.status_count;
          } else if (item.status === "pending") {
            pendingCountx = item.status_count;
          }
        });
        setcompletedCount(completedCountx);
        setpendingCount(pendingCountx);
      } else {
        //500 recieved handle request server error
      }
      console.log(response);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Create a debounced version of fetchData

  const debouncedFetchData = useCallback(
    _.debounce((searchTerm) => {
      fetchData(status, SelectedArea, searchTerm);
    }, 500),
    []
  );

  useEffect(() => {
    if (search) {
      debouncedFetchData(search);
    }
  }, [search, debouncedFetchData]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered // This prop centers the modal
        style={{ opacity: show ? 1 : 0, height: "100%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="ModalTitle">Begin Survey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modalMessage">
            Please confirm completion of the following before initiating the
            survey.
          </p>
          <div className="checklist">
            <label className="checklist-item">
              <input
                type="checkbox"
                name="safetyCheck"
                checked={checkedItems.safetyCheck}
                onChange={handleChange}
              />
              BPCL Suraksha Safety Check Survey
            </label>
            <label className="checklist-item">
              <input
                type="checkbox"
                name="eKYC"
                checked={checkedItems.eKYC}
                onChange={handleChange}
              />
              eKYC
            </label>
            <label className="checklist-item">
              <input
                type="checkbox"
                name="hoseReplacement"
                checked={checkedItems.hoseReplacement}
                onChange={handleChange}
              />
              LPG Hose Replacement
            </label>
          </div>

          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button className="CloseModalBtn" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="StartSurveyNowBtn" onClick={startSurvey}>
            Start Survey
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="customer-list">
        <div className="logo">
          <img
            src={process.env.PUBLIC_URL + "/img/svg/Logo.svg"}
            alt="Greattr Logo"
          />
        </div>
        <br />
        <div className="header">
          <div className="status-tabs">
            <button
              className={status === "pending" ? "active" : ""}
              onClick={() => {
                setStatus("pending");

                fetchData("pending");
              }}
            >
              Pending
              <br />({pendingCount})
            </button>
            <button
              className={status === "completed" ? "active" : ""}
              onClick={() => {
                setStatus("completed");
                fetchData("completed");
              }}
            >
              Completed
              <br />({completedCount})
            </button>
          </div>
        </div>

        <div className="selected-area-container">
          <select
            value={SelectedArea}
            onChange={handleSelect}
            style={{ color: "black" }}
          >
            <option value={null} disabled>
              Select an area
            </option>
            {Areas.map((option, index) => (
              <option key={index} value={option["Area ID"] + ""}>
                {option["Area Name"]}
                {"           "}
                {option["Area ID"]}
              </option>
            ))}
          </select>
        </div>
        <input
          className="inputx"
          type="text"
          placeholder="Enter Customer’s Phone Number or LPG ID"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button className={"refresh"} onClick={() => fetchData()}>
          Refresh
        </button>
        <br />

        <div className="customer-cards">
          {filtereddata.map((customer, index) => (
            <div key={index} x onClick={() => handleShow(customer)}>
              <CustomerCard key={index} customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OperatorCustomerList;
