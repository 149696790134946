import React from "react";
import styled, { css } from "styled-components";
import FrameComponent from "./components/FrameComponent";
import Features from "./components/Features";
import Apply from "./components/Apply";
import ConsumerMerchantItems from "./components/ConsumerMerchantItems";
import FeaturesContainer from "./components/FeaturesContainer";
import FeatureGrid from "./components/FeatureGrid";
import BenefitTitleContainer from "./components/BenefitTitleContainer";
import Footer from "./components/Footer";
import DreamsWithin from "./components/DreamsWithin";
// import Navbar from "./components/Navbar";
import Header from "./components/Header";

const WebsiteContainer = styled.div`
  width: 100%;
  position: relative;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;
  letter-spacing: normal;

  @media (max-width: 768px) {
    padding: 0 0rem;
  }
`;

const HeroSection = styled.section`
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  padding: 0 0 2rem 0;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 2rem;
  color: #fff;
  font-family: var(--heading-h6);

  @media (max-width: 768px) {
    font-size: 1.5rem;
    padding: 1rem 0;
    flex-direction: column;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0.2 0rem;
    height: 100vh;
  }
  object-fit: cover; /* Ensures the video covers the entire area */
  z-index: -1; /* Places the video behind the content */
`;

const HeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1.25rem; /* Reduced padding for mobile */
  box-sizing: border-box;
  max-width: 100%;
  height: 100vh; /* Changed to auto for better mobile view */
  overflow: hidden; /* Ensures that the video does not overflow the container */

  @media (max-width: 768px) {
    padding: 2rem 0rem;
    height: 100vh;
  }
`;

const HeroTitleText = styled.div`
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 4rem;

  @media (max-width: 768px) {
    padding-top: 1rem;
    font-size: 2rem;
    font-family: "DM Sans";
    font-weight: 500;
  }
`;

const HeroSubtitleText = styled.div`
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 7rem;

  @media (max-width: 768px) {
    font-size: 3rem;
    font-family: "DM Sans";
    font-weight: 500;
  }
`;

const HeroTitle = styled.div`
  width: 100%; /* Full width on mobile */
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const HeroSubtitle = styled.div`
  width: 100%; /* Full width on mobile */
  font-size: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BenefitsSection = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column; /* Changed to column for better stacking on mobile */
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  padding: 2rem 1rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 2rem; /* Adjusted for mobile */
  color: #fff;
  font-family: var(--heading-h6);
  margin-top: 2rem; /* Adjusted margin for mobile */

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
`;

const BenefitsContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  gap: 1rem;
  max-width: 100%;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const BenefitsHeader = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column; /* Changed to column for better stacking on mobile */
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  padding: 1rem;
  box-sizing: border-box;
  max-width: 100%;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const BenefitsTitle = styled.div`
  width: 100%; /* Full width on mobile */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  gap: 1rem;
  max-width: 100%;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const TitleText = styled.div`
  margin-top: 0.5rem;
  text-align: "center";
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 2rem;

  @media (max-width: 768px) {
    text-align: "center";
    font-size: 1rem;
    font-family: "DM Sans";
    font-weight: 500;
  }
`;

const Website = () => {
  return (
    <WebsiteContainer>
      {/* <Navbar /> */}
      <Header />
      <HeroSection>
        <HeroContent>
          <VideoBackground autoPlay loop muted playsInline>
            <source src="../video/Greattr_Video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </VideoBackground>
          <HeroTitle>
            <HeroTitleText>Empower Your Future with Greattr</HeroTitleText>
            <HeroSubtitleText>Small Loans, Big Changes</HeroSubtitleText>
          </HeroTitle>
          <HeroSubtitle>
            <b
              style={{
                height: "auto" /* Adjusted for mobile */,
                width: "100%" /* Full width on mobile */,
                position: "relative",
                lineHeight: "1.5",
                display: "inline-block",
                flexShrink: 0,
                textAlign: "center",
              }}
            >
              <TitleText>
                We're Different. Greattr Brings Financial Freedom to Your
                Doorstep.
              </TitleText>
              <TitleText>
                Transforming Lives with Seamless, Accessible Financial Solutions
                for All.
              </TitleText>
            </b>
          </HeroSubtitle>
        </HeroContent>
      </HeroSection>
      <FrameComponent />
      <DreamsWithin />
      <Features />
      <Apply />
      <BenefitsSection>
        <BenefitsContent>
          <BenefitsHeader>
            <BenefitsTitle>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.03em",
                  lineHeight: "1.1",
                  fontWeight: 500,
                  fontSize: "4.1rem",
                  color: "black",
                  marginBottom: "10px",
                }}
              >
                Discover the Benefits
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "1rem" /* Adjusted for mobile */,
                  lineHeight: "1.8",
                  fontWeight: 500,
                  color: "black",
                  fontFamily: "var(--text-small)",
                }}
              >
                Unlock Your Financial Potential with Greattr
              </div>
            </BenefitsTitle>
          </BenefitsHeader>
          <ConsumerMerchantItems />
          <FeaturesContainer />
        </BenefitsContent>
      </BenefitsSection>
      <FeatureGrid />
      <BenefitTitleContainer />
      <Footer />
    </WebsiteContainer>
  );
};

export default Website;
