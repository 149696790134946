import styled from "styled-components";
import PropTypes from "prop-types";
import Card1 from "./Card1";

const FeaturesSection = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 11.425rem 1.25rem 11.431rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 5.713rem;
  color: var(--text-default);
  font-family: var(--font-heading-h6);

  @media (max-width: 1250px) {
    padding-top: 7.438rem;
    padding-bottom: 7.438rem;
  }

  @media (max-width: 825px) {
    padding-top: 4.813rem;
    padding-bottom: 4.813rem;
  }
`;

const ContentWrapper = styled.div`
  width: 94.788rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 8.856rem;
  max-width: 100%;

  @media (max-width: 1575px) {
    flex-wrap: wrap;
  }

  @media (max-width: 1250px) {
    gap: 4.438rem;
  }

  @media (max-width: 825px) {
    gap: 2.188rem;
  }

  @media (max-width: 450px) {
    gap: 1.125rem;
  }
`;

const FeatureImage = styled.img`
  width: 30rem;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;

  @media (max-width: 1575px) {
    // flex: 1;
  }
`;

const TextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.3rem;
  min-width: 34.438rem;
  max-width: 100%;

  @media (max-width: 825px) {
    gap: 2.625rem;
    min-width: 100%;
  }

  @media (max-width: 450px) {
    gap: 1.313rem;
  }
`;

const Title = styled.div`
  width: max-content;
  align-self: auto;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 110%;
  font-weight: 600;

  @media (max-width: 825px) {
    padding-left: 1rem;
    font-size: 2.875rem;
    font-weight: 600;
    line-height: 3.75rem;
  }

  @media (max-width: 450px) {
    padding-left: 1rem;
    font-size: 1.688rem;
    font-weight: 600;
    line-height: 2.5rem;
  }
`;

const CardContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.806rem;
  max-width: 100%;
  font-size: 1.756rem;

  @media (max-width: 450px) {
    gap: 1.375rem;
  }
`;

const CardRow = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.806rem;
  max-width: 100%;

  @media (max-width: 825px) {
    flex-wrap: wrap;
  }

  @media (max-width: 450px) {
    gap: 1.375rem;
  }
`;

const Features = ({ className = "" }) => {
  return (
    <FeaturesSection id="features" className={className}>
      <ContentWrapper>
        <FeatureImage loading="lazy" alt="" src="/featureimage.svg" />
        <TextContent>
          <Title>
            One App,
            <br />
            Endless Possibilities.
          </Title>
          <CardContainer>
            <CardRow>
              <Card1
                riflashlightLine="/riflashlightline.svg"
                instant="Instant"
                loanApproval="Loan Approval"
                getQuickAccessToMicroLoan="Get quick access to micro-loans with our streamlined application process."
              />
              <Card1
                riflashlightLine="/risafe2fill.svg"
                instant="Secure"
                loanApproval="Transactions"
                getQuickAccessToMicroLoan="Enjoy peace of mind with our robust security and confidential transactions."
              />
            </CardRow>
            <CardRow>
              <Card1
                riflashlightLine="/rismartphoneline.svg"
                instant="User-Friendly"
                loanApproval="Interface"
                getQuickAccessToMicroLoan="Navigate our app with ease, whether you're a customer or a merchant."
              />
              <Card1
                riflashlightLine="/rilinechartline.svg"
                instant="Dedicated"
                loanApproval="Merchant Support"
                getQuickAccessToMicroLoan="Merchants can assist customers in real-time, simplifying the loan process."
              />
            </CardRow>
          </CardContainer>
        </TextContent>
      </ContentWrapper>
    </FeaturesSection>
  );
};

Features.propTypes = {
  className: PropTypes.string,
};

export default Features;
