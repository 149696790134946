import { useEffect } from "react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AllSet = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const language = location.state?.language || 'English';

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            console.log('Back button pressed, navigating to /survey');
            navigate('/survey', { replace: true });
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            console.log('Cleaning up event listener');
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);

    const handleBackToHome = () => {
        console.log('Back to Home button clicked');
        navigate('/survey');
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            padding: '5%'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                color: 'black'
            }}>
                <img
                    src={process.env.PUBLIC_URL + "/img/confetti.png"}
                    alt="SVG Icon"
                    width={150}
                    style={{ padding: '4%' }}
                />

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '5%',
                    justifyContent: 'space-evenly',
                    height: '30vh'
                }}>
                    <p
                        style={{
                            fontSize: '2.15rem',
                            fontStyle: 'Manrope-Regular',
                            fontWeight: "600"
                        }}
                    >
                        {language == 'English' ? `You're all set to apply for your loan with the Greattr App!` : `आप ग्रेटर ऐप के साथ अपने ऋण के लिए आवेदन करने के लिए पूरी तरह तैयार हैं!`}
                    </p>
                    <p
                        style={{
                            fontSize: '2.00rem',
                            fontStyle: 'Manrope-Regular',
                            fontWeight: '400',
                        }}
                    >
                        {language == 'English' ? `You can start right away with the app you downloaded using the QR code.` : `आप QR कोड का उपयोग करके डाउनलोड किए गए ऐप से तुरंत शुरुआत कर सकते हैं।`}
                    </p>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "5%",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <img
                        src={process.env.PUBLIC_URL + "/img/svg/GreattrQRCode.svg"}
                        height={200}
                        width={200}
                        alt="QR Code"
                    />
                </div>

            </div>
            <button
                onClick={handleBackToHome}
                style={{
                    backgroundColor: '#573A87',
                    color: 'white',
                    padding: '4%',
                    fontWeight: '600',
                    width: '100%',
                    borderWidth: 1,
                    borderColor: 'white',
                    borderRadius: 6,
                }}
            >Back To Home</button>
        </div >
    )
}

export default AllSet;