import React, { useState } from "react";
import styled from "styled-components";
import Column from "./Column";
import PropTypes from "prop-types";

const Section = styled.section`
  align-self: stretch;
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem 1.25rem 11.425rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 1.288rem;
  color: var(--text-default);
  font-family: var(--heading-h6);

  @media (max-width: 825px) {
    padding-bottom: 7.438rem;
  }
`;

const Container = styled.div`
  width: 85.713rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 0rem 0.006rem;
  box-sizing: border-box;
  max-width: 100%;
  row-gap: 20px;
`;

const FAQContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.287rem;
  min-width: 27.875rem;
  max-width: 100%;
  font-size: 1.431rem;

  @media (max-width: 825px) {
    gap: 1.125rem;
    min-width: 100%;
  }
`;

const QuestionContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.143rem;
  max-width: 100%;
`;

const Question = styled.div`
  flex: 1;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 120%;
  font-weight: 700;
  display: inline-block;
  max-width: calc(100% - 46px);

  @media (max-width: 450px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

const ToggleIcon = styled.img`
  height: 1.713rem;
  width: 1.713rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`;

const Answer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 1.144rem;
  line-height: 160%;
  font-weight: 500;
  font-family: var(--text-small);
  color: var(--text-muted);
  display: inline-block;
  min-height: 5.438rem;
  z-index: 1;
`;

const Divider = styled.div`
  align-self: stretch;
  height: 0.069rem;
  position: relative;
  background-color: var(--border-default);
`;

const BenefitTitleContainer = ({ className = "" }) => {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is Greattr?",
      answer:
        "Greattr is an impact fintech offering small ticket loans (BNPL) and insurance to low-income individuals through our own NBFC & Affiliated NBFCs, Jainex Finance Limited.",
    },
    {
      question: "How does the Greattr customer app work?",
      answer:
        "Customers can easily apply for micro-loans and insurance through the app and manage repayments with flexible options.",
    },
    {
      question: "What support do merchants receive?",
      answer:
        "Merchants can assist customers with loan applications and repayments, boosting their sales and customer loyalty.",
    },
    {
      question: "Is my information secure with Greattr?",
      answer:
        "Absolutely! Greattr prioritizes security, implementing robust measures to safeguard all transactions and personal data. Your information remains confidential.",
    },
    {
      question: "Who can use Greattr?",
      answer:
        "Greattr caters to a wide audience, including individual consumers, small businesses, and anyone seeking flexible payment solutions for both purchases and bills.",
    },
  ];

  return (
    <Section id="contactDetails" className={className}>
      <Container>
        <Column riarrowDownLine="/riarrowdownline@2x.png" />
        <FAQContainer>
          {faqItems.map((item, index) => (
            <React.Fragment key={index}>
              <QuestionContainer>
                <Question>{item.question}</Question>
                <ToggleIcon
                  loading="lazy"
                  alt=""
                  src={
                    openItem === index ? "/riclosefill.svg" : "/riaddfill.svg"
                  }
                  onClick={() => toggleItem(index)}
                />
              </QuestionContainer>
              {openItem === index && <Answer>{item.answer}</Answer>}
              <Divider />
            </React.Fragment>
          ))}
        </FAQContainer>
      </Container>
    </Section>
  );
};

BenefitTitleContainer.propTypes = {
  className: PropTypes.string,
};

export default BenefitTitleContainer;
