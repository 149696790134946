import React from "react";
import "./LicenseTemplate.scss";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/logo-Colored2.png";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
const CreditLine = () => {
  return (
    <>

      <div className="licensetemplate">
        <img src={logo} style={{ width: "80%" }} />
        <br />
        <h1>
          <br />
          CREDIT LINE GENERAL TERMS AND CONDITIONS
          <br />
        </h1>
        <div className="licenseContainer">
          <h1>INTRODUCTION </h1>The Borrower is informed as follows: The
          Borrower (hereinafter defined) is requested to read these Credit Line
          general terms and conditions carefully. The Credit Line (hereinafter
          defined) from Lender(s) (hereinafter defined) shall be strictly
          subject to these Terms (hereinafter defined). The Credit Line and
          Drawdown (hereinafter defined) is at the sole discretion of the
          Lender(s). Anything contained in these Terms or any communications
          inter-se will be strictly subject to and without prejudice to the
          prerogative of the Lender(s) mentioned aforesaid and the other Credit
          Line Finance Parties (hereinafter defined). Greattr Terms (hereinafter
          defined) shall form an integral part of these Terms herein contained.
          It shall be deemed that the Borrower confirms and accept to such terms
          by accepting these Terms and/or by availing the Credit Limits or any
          services from the Credit Line Finance Parties. Submitting a Credit
          Line Application Form (hereinafter defined) does not entitle the
          Borrower to be eligible for sanction of Credit Line. The details
          represented by the Borrower to Credit Line Finance Parties will form
          the basis of approval of the Credit Line by the Lender(s). The
          Lender(s), at its sole discretion, will determine the Credit Line,
          which can be granted. Details of the approved Credit Line (including
          any variations) (increase or decrease or other terms and conditions)
          will be communicated to the Borrower through Greattr Platform (as
          defined below), and such other means as the Credit Line Finance
          Parties may decide from time to time. Acceptance of these Terms shall
          constitute a valid and binding legal contract between the Borrower and
          the Credit Line Finance Parties (hereinafter defined). These Terms may
          be varied (as required by the Credit Line Finance Parties), the
          Borrower is therefore requested to be updated, and any such changes
          will be applied prospectively. Further, these Terms, when
          electronically generated, are an electronic record under the
          provisions of the Information Technology Act, 2000. While applying for
          a Credit Line, the Borrower shall be deemed to have electronically
          accepted to these Terms and the Greattr Terms, and the same shall be
          binding on the Borrower. If you need any clarifications, please visit
          us at www.Greattr.com/contact-us and/or through the relevant link in
          ("Greattr Platform") and go through the illustrations/demonstrations.
          We will be available for services from 10 AM to 5 PM, Monday to Friday
          (excluding public holidays). <h1>DEFINITIONS</h1> Capitalized terms
          used in these Terms are defined below: "Availability Period" shall
          mean the period within which the Borrower can request a Drawdown from
          the Credit Line and shall be as detailed in the Credit Line Sanction
          Terms. The Availability Period may be extended at the sole discretion
          of the Lender(s). "Available Credit Line" means at any point of time
          the undrawn amount of the Credit Line available to a Borrower
          (including any amount of the Credit Line, which becomes available
          pursuant to any repayment or prepayment of all or part of any previous
          Drawdown provided the Lender(s) has/have granted a revolving Credit
          Line). "Borrower" means any person who has expressed interest in
          applying for a Credit Line by submitting a Credit Line Application.
          "Borrower's Dues" means all sums payable by the Borrower to the Credit
          Line Finance Parties, including outstanding Credit Line, Interest, all
          other charges, costs, and expenses. "Business Day" means normal
          working hours on a day on which scheduled banks are open for business
          in Mumbai. "Credit Line" means the maximum drawdown limit for
          loan/credit facility granted by the Lender(s) to the Borrower as per
          Credit Line Sanction Terms, Credit Line Details Sheet, and other
          Financing Documents. "Credit Line Application" means the application
          in the prescribed form submitted by the Borrower to the Credit Line
          Finance Parties through Greattr Platform for Credit Line for seeking
          the sanction of Credit Line from the Lender(s). "Credit Line Details
          Sheet" means documents submitted by the Borrower to the Lender(s) and
          other Credit Line Finance Parties as complete, irrevocable acceptance
          to the Credit Line Sanction Terms (hereinafter defined) for availing
          loan/credit facility as mentioned there from the Lender(s). “Credit
          Line Finance Parties” means and includes each of the Lender, Jainex
          Finance Limited, and or service providers of Lender(s)/Jainex provided
          such service providers are so designated by the Lender(s)/Jainex for
          the specific purpose. "Credit Line Sanction Terms" means communication
          from the Lender(s) through Greattr Platform to the Borrower with the
          details of the Lender(s) who have sanctioned the grant of the Credit
          Line for availing loan/credit facility by the Borrower, and respective
          Credit Line terms approved by each Lender(s), the Credit Terms and
          other terms applicable for availing the sanctioned Credit Line by the
          Borrower. "Credit Terms" are terms that will apply to a Credit Line.
          The same will be as communicated by the Credit Line Finance Parties
          through Greattr Platform or otherwise and may include the following:
          Interest Rate (if applicable) IRR (if applicable) Overdue Interest
          Rate Processing Fee Documentation Charges Default Charges Repayment
          Instrument swapping charges Fee for change of Due Date Indicative
          purposes for which the Borrower can make Drawdown' Schedule of Charges
          (may include charges forming part of the Financing Documents, Credit
          Terms, or other fees for specific services on Greattr Platform or by
          the Lender(s)) Terms specific to Lender(s) The Credit Terms are
          variable as decided by the Credit Line Finance Parties, and variations
          will be available on the web link at www.Greattr.com on the Greattr
          Platform. Any such alterations will be effective prospectively. On
          each occasion, when the Borrower submits the Credit Line Details Sheet
          and/or during each instance of Drawdown and/or by using the Greattr
          Platform, it shall be deemed that the Borrower confirms the Credit
          Terms and its variations. "Demand Notice" shall mean notice or
          intimations sent to the Borrower by the Credit Line Finance Parties
          seeking accelerated repayment of the Credit Line availed by the
          Borrower by way of any of the following: Email and/or to Borrower's
          account in the Greattr Platform; Through SMS or WhatsApp to Borrower's
          registered mobile number; Through voice mail to Borrower; Through the
          mail (courier or through Indian postal services); Any other electronic
          means including that by notification given in the User ID of the
          Borrower in the Greattr Platform; Such other means deemed fit by the
          Credit Line Finance Parties. In case there are multiple Borrowers,
          such notice issued to a Borrower shall be deemed to be issued and
          served on all the Borrowers. "Drawdown" shall mean each Drawdown of
          the Credit Line within the Availability Period and as per the terms of
          the Financing Documents. By availing the Drawdown, it shall be deemed
          that the Borrower confirms and accept each of the Greattr Terms and
          the Financing Documents. "Due Date" in respect of any payment means
          the date on which any amount is due from the Borrower to the Lender(s)
          or the other Credit Line Finance Parties. "EMI" means the equated
          monthly amount to be paid by the Borrower towards repayment of all
          outstanding Drawdowns and payment of Interest (if applicable) as per
          Financing Documents. "OneAgrow" means OneAgrow Fintech Pvt Ltd, a
          company under the provisions of the Companies Act, 2013, which will
          act as the Lender's Agent for the Lender(s) in relation to the Credit
          Line as per these Terms. Notwithstanding anything mentioned to the
          contrary in these presents, OneAgrow may perform any other business as
          may be permitted by law. "Financing Documents" means and includes the
          following (unless waived by the Credit Line Finance Parties): These
          Terms Each of the Greattr Terms Credit Terms Credit Line Application
          Form Credit Line Sanction Terms Demand Promissory Note (if any
          executed by the Borrower) Credit Line Details Sheet Payment
          undertaking (if any executed by the Borrower) Acknowledgment Any
          documents/terms and conditions executed/accepted by the Borrower as
          required by the Credit Line Finance Parties. Annexures and/or
          amendment and/or addendum to any of the above documents
          executed/accepted with the concurrence of the Credit Line Finance
          Parties. "Lender(s)" means Lender(s) whose name is referred/referenced
          in the Credit Line Sanction Terms, Credit Line Details Sheet, and
          other Financing Documents and has sanctioned a Credit Line as per
          terms hereof. To find details of the partner Lender(s) to Greattr,
          refer to the web link www.Greattr.com or on the Greattr Platform.
          "Lender’s Agent" means OneAgrow and Person(s) authorized by the
          Lender(s) from time to time for acting on their behalf which includes
          but is not limited to collection of documents from the Borrower,
          verification of details, sending a reminder for repayments, issuing
          notices, instructing lawyers, site visits, monitoring utilization of
          amounts drawn, representing in courts and legal proceedings and acting
          on their behalf, bureau reporting, reporting to other repositories,
          doing any acts per law to enforce any legal right or remedy of the
          Lender(s) and/or any acts as per these Terms by virtue of being a
          Credit Line Finance Party. “Material Adverse Effect” means any event
          which in the opinion of the Credit Line Finance Parties would have an
          adverse effect on: Borrower’s ability to pay the Borrower’s Dues;
          Recoverability of the Borrower’s Dues; Initiation of any insolvency or
          bankruptcy proceedings on the Borrower or Borrower’s business or
          Borrower’s employer; Initiation of any litigation or regulatory or
          investigative proceedings against the Borrower which in the opinion of
          the Credit Line Finance Parties affects the performance of any
          Obligation of the Borrower; The Borrower turns to non-responsive or is
          avoiding efforts of Credit Line Finance Parties to contact;
          “Obligation” means and includes the Borrower’s responsibility and
          liability towards the Lender(s) under the terms of Financing Documents
          which includes but is not limited to, paying amounts when due
          (including but not limited to paying the Processing Fee, reimburse
          Documentation Charges, stamp duty, legal expenses and other charges
          (including that in the schedule of charges as given in the Greattr
          Platform), pay any debts, principal, Interest Rate, Default Rate/Over
          Due Interest, expenses and other amounts which the Borrower owes to
          the Lender(s) now or later or to comply with such other terms and
          conditions, undertaking or documents, contract executed by the
          Borrower to avail the Credit Line or other loan from the Lender(s) or
          to or comply with any terms of the Financing Documents. “Overdue
          Interest Rate” means the default interest as prescribed in the Credit
          Line Sanction Terms, Credit Line Details Sheet, and other Financing
          Documents, which is payable by the Borrower on all amounts which are
          not paid on their respective Due Dates. “Repayment Instrument” means
          to include instruments inter alia Post Dated Cheques (“PDC”), Undated
          Cheques (“UDC”), Whole Amount Cheques (“WAC”), (collectively,
          “Cheques”) or Electronic Clearing Service (“ECS”) mandates or Standing
          Instructions (“SI”) or National Automated Clearing House Mandate
          (“NACH”), Electronic Instructions, Payment Instruments, Payment
          Instructions, Debit Instructions and/or such other instruments as may
          be prescribed by the Lender(s) and issued by the Borrower for
          facilitating repayments of the Credit Line. “Greattr Platform” means a
          designated office of OneAgrow (or its service providers) or the
          website www.Greattr.com and Greattr mobile App and internet and
          technology-based platform which can be downloaded and used by the
          Borrowers: As per terms of use for the same; To apply for Credit Line
          or for Drawdown of Credit Line (as may be applicable); To use the same
          in such manner as permitted by the Greattr Platform from time to time.
          “Greattr Terms” means and include, those in Greattr Platform for the
          following: General terms & conditions for the use of Greattr Platform
          Privacy policy of Greattr Platform End-user terms for Greattr User ID
          Terms of other service providers as provided in Greattr Platform These
          Terms Credit Terms Other terms, general disclaimers, etc. “Terms”
          means these Credit Line General Terms and Conditions and documents
          referred here as may be modified and updated. “User ID" means the
          unique identity of the Borrower, which is created and used by the
          Borrower to access the Greattr Platform for availing the Credit Line
          from the Lender(s) and/or availing any services from the Credit Line
          Finance Parties. The User ID shall be subject to Greattr Terms and
          Financing Documents. In this Terms, unless the context otherwise
          requires: the words importing singular shall include the plural and
          vice versa. The words denoting natural persons shall, where the
          context admits, include partnerships, firms, companies, corporations,
          associations, organizations, or other entities (whether having a
          separate entity). GENERAL DISCLAIMER ONEAGROW FINTECH PRIVATE LIMITED
          IS: NOT A FINANCIAL INSTITUTION OR A NON-BANKING FINANCIAL SERVICES
          COMPANY AND THEREFORE SHALL NOT BE PROVIDING ANY FINANCIAL SERVICES OR
          CREDIT FACILITIES. IT IS ACTING AS A LENDER’S AGENT AND ALSO OFFER
          INTERNET-BASED TECHNOLOGY SUPPORT AS FOUND IN THE GREATTR WEBSITE
          (WITHOUT ANY WARRANTIES) FOR FACILITATING BORROWING PROCESS MORE
          TECHNOLOGY BASED. THE LENDER(S) MAY HAVE THEIR TERMS AND CONDITIONS
          OVER AND ABOVE GREATTR TERMS AND ALSO SHALL BE THE PERSON WHO HAS/HAVE
          THE SOLE DISCRETIONARY AUTHORITY TO GRANT OR REJECT A CREDIT LINE
          APPLICATION FORM OR PERMIT A DRAWDOWN FROM THE CREDIT LINE.
          <h1> CREDIT LINE</h1>
          The terms of the Credit Line approved to a Borrower will be subject to
          the Terms hereof and as per the Credit Line Sanction Terms, Credit
          Line Details Sheet, other Financing Documents, and Greattr Terms. Any
          use of the Credit Line by the Borrower or any request to avail of
          Credit Line shall be subject to applicable law, the purpose for which
          a Borrower seeks Drawdown shall not contravene the law or public
          policy, and the Borrower shall ensure the same without fail. The
          Credit Line may be in the nature of revolving credit or nonrevolving
          nature, as per the discretion of the Lender(s). In the case of a
          Revolving Credit Line, the Available Credit Line Amount may change
          during the Availability Period on account of prepayments/ repayments
          of earlier Drawdowns. For a Credit Line to be revolving in nature, the
          same must be mentioned explicitly in the respective Credit Line
          Sanction Terms and the Credit Line Details Sheet. If the Credit Line
          is not revolving as per Credit Line Sanction Terms and the Credit Line
          Details Sheet, the Borrower will not be permitted to re-borrow the
          amount repaid as part of the same Credit Line. Notwithstanding
          anything contained in these Terms and the Financing Documents, the
          Lender(s) shall have the absolute right to cancel or refuse any
          further Drawdown from the Credit Line at their discretion as it may
          deem fit, including on account of any change in credit evaluation of
          the Borrower. Subject to these Terms and the Financing Documents, the
          Credit Line shall be available for Drawdown during the Availability
          Period Only. The Borrower shall execute all documents and amendments
          and cooperate as required by the Credit Line Finance Parties from time
          to time: To comply with any RBI guidelines/directives, or For
          providing the Lender(s) and other Credit Line Finance Parties full
          benefit of rights under the Financing Documents and Greattr Terms.
          Without prejudice to the aforesaid, the Borrower hereby irrevocably
          consents that on its failure to do so, all such documents and
          amendments and terms of cooperation as required by the Credit Line
          Finance Parties from time to time shall be deemed to be incorporated
          in the Financing Documents and/or Greattr Terms and shall be binding
          on the Borrower.<h1> DISBURSEMENT</h1> At any time during the
          Availability Period, the Borrower may request disbursement of any
          amount to the extent of the available Credit Line. The Lender(s) shall
          have the sole and absolute discretion to allow or reject Drawdown
          against such request. The other Credit Line Financing Parties may
          also, at their discretion, may request the Lender(s) to deny any
          Drawdown request for reasons deemed fit by them. Details of the
          Lender(s) extending the Credit Line and/or permitting the Drawdown
          towards the Credit Line will be available to the Borrower in
          Borrower’s User ID on the Greattr Platform. Disbursement of any
          Drawdown directly to a third party, as mentioned in the Credit Line
          Sanction Terms and Credit Line Details Sheet, shall be treated as
          having been disbursed to the Borrower. The Borrower shall pay
          non-refundable processing charges and documentation charges as stated
          in the Credit Line Sanction Terms, Credit Line Details Sheet, Credit
          Terms (including the schedule of charges) and other Financing
          Documents, along with goods and services tax (“GST”) thereof, which
          may be added as a deemed Drawdown from the Credit Line by the
          Borrower. The Borrower will accordingly be liable for the entire
          Drawdown, including the amount towards the charges as aforesaid and as
          stated in the Credit Line Sanction Terms, Credit Line Details Sheet,
          Credit Terms (including the schedule of charges) and other Financing
          Documents, along with GST thereof and Interest (if any) thereon. The
          Borrower acknowledges that the Loan, if approved can be granted by
          Jainex Finance Limited or other lenders and mode of disbursement can
          be through Jainex or any other RBI approved payment
          platform/mechanism.
          <h1>AUTHORITY TO CHARGE</h1> The Borrower hereby unconditionally and
          irrevocably authorize the Credit Line Finance Parties to charge from
          the Credit Line, the amount due to it from the Borrower under the
          following heads (as may be applicable as per terms of the Financing
          Documents and Greattr Terms): Processing Fee; Documentation Charges;
          Charges as per the Credit Terms (including those in the Schedule of
          Charges); Other charges inter alia legal expenses and collection
          charges and any other charges as may be incurred by the Credit Line
          Finance Parties (as the case may be applicable). The charges shall
          form as an admitted liability of the Borrower and shall be payable by
          the Borrower. The Credit Line Finance Parties shall also be entitled
          to debit the Credit Line.
          <h1>INTEREST AND REPAYMENT</h1> The Borrower shall pay Interest (as
          applicable) on each Drawdown made by the Borrower from the Credit
          Line, and all other amounts due (as provided in Financing Documents
          and Greattr Terms). The Interest shall be computed on a monthly basis
          on the outstanding principal amount. The Borrower will be responsible
          and liable to repay the entire Drawdown amount and shall pay the full
          amount for each Drawdown together with the Interest and other charges
          (as provided in Financing Documents and Greattr Terms). In cases,
          where the instalment is not paid on the Due Date, all overdue amounts
          shall accrue Interest at the prescribed Overdue Interest Rate, which
          shall be computed from the respective Due Dates for payments, and the
          interest shall be compounded on a monthly basis. The Borrower
          acknowledges that in case of identified purpose, Drawdown may be
          allowed on the zero-interest basis (as provided in Financing Documents
          and Greattr Terms) and in such cases, the return may be made available
          to the Lender(s) and/or other Credit Line Finance Parties by way of
          one-time non-refundable upfront discount provided by the vendor on
          selected purposes as mutually accepted between the Lender(s) and/or
          other Credit Line Finance Parties and vendor / its authorized
          representative. The Borrower’s Obligation towards repayment by way of
          EMI (as provided in Financing Documents and Greattr Terms) shall be as
          calculated by the Lender(s) as required for amortization of Drawdowns
          within their respective tenure and Interest payable thereon. The
          Borrower confirms their understanding that the EMI schedule given by
          the Credit Line Finance Parties shall only be towards principal
          outstanding and Interest thereon and does not include any default
          interest or any other charges payable by the Borrower pursuant to
          Financing Documents. The Over Due Interest and other charges (as
          provided in Financing Documents and Greattr Terms) will be over and
          above the EMI. The payment of each EMI and other charges (as provided
          in Financing Documents and Greattr Terms) on time is the essence of
          the contract between the Borrower and the Credit Line Finance Parties.
          The Borrower acknowledges that s/he has understood the method of
          computation of EMI and shall not dispute the same. Notwithstanding
          anything to the contrary, all Borrower’s Dues, including EMI or other
          charges (as provided in Financing Documents and Greattr Terms), shall
          be payable by the Borrower to the Credit Line Finance Parties as and
          when demanded by the Credit Line Finance Parties, at their discretion
          and without the requirement of any reason being assigned. The Borrower
          shall pay such amounts, without any delay or demur, within fifteen
          (15) days of such demand. The Lender(s) and other Credit Line Finance
          Parties shall be entitled to revise the rate of Interest if required
          under any applicable law. The Lender(s) and other Credit Line Finance
          Parties may also re-compute the EMI / the number of EMI for repayment
          of outstanding Credit Line and Interest. Any such change as intimated
          by the Credit Line Finance Parties to the Borrower will be final and
          binding on the Borrower. In case of such revision, the Borrower shall
          be entitled to prepay within thirty (30) days of such revision, the
          entire outstanding Credit Line along with accrued Interest and charges
          (as provided in Financing Documents and Greattr Terms). In case of
          delayed payments, without prejudice to all other rights of the
          Lender(s) and other Credit Line Finance Parties, the Lender(s) and
          other Credit Line Finance Parties shall be entitled to Overdue
          Interest Rate and charges (as provided in Financing Documents and
          Greattr Terms) from the Borrower for the period of delay. The Borrower
          may prepay any Drawdown before its scheduled tenure only with the
          prior approval of Lender(s) and other Credit Line Finance Parties and
          subject to such conditions and prepayment charges, as provided in
          Financing Documents and Greattr Terms. The Borrower shall bear all
          interest, tax, duties, cess, and other forms of taxes including
          without limitation GST, value-added taxes, whether applicable now or
          in the future, payable under any law at any time in respect of any
          payments made to the Credit Line Finance Parties under the Financing
          Documents and Greattr Terms. If such payments are incurred by the
          Credit Line Finance Parties, the same shall be recoverable from the
          Borrower and will carry interest at the rate of Overdue Interest Rate
          from the date of payment till reimbursement. The amounts repaid by the
          Borrower shall be appropriated firstly towards the cost, charges,
          expenses, and other monies, secondly towards Overdue Interest Rate, if
          any, thirdly towards Interest, and lastly towards repayment of the
          principal amount of a Credit Line. Notwithstanding anything contained
          herein, the Lender(s) and other Credit Line Finance Parties shall be
          entitled to adjust any advance monthly instalment and/or security
          deposit/initial payment made by the Borrower towards any Borrower's
          Dues in such manner and at such time as the Lender(s) and other Credit
          Line Finance Parties may determine in their sole discretion. Interest
          (as applicable), Overdue Interest Rate, and all other charges shall
          accrue from day to day and shall be computed based on 365 days a year,
          and the actual number of days elapsed. If the due date for any payment
          is not a Business Day, the amount will be paid by Borrower on the
          immediately preceding Business Day. All sums payable by the Borrower
          to the Lender(s) and other Credit Line Finance Parties (unless
          specifically) shall be paid without any deductions whatsoever. Credit/
          discharge for payment will be given only on the realization of amounts
          due. The Borrower acknowledges that the rate of interest, penal
          charges, service charges, and other charges payable and or accepted to
          be paid by the Borrower under Financing Documents are reasonable and
          acceptable to him/ her.{" "}
          <h1>MODE OF PAYMENT, REPAYMENT, AND PREPAYMENT</h1> As required by the
          Lender(s) and other Credit Line Finance Parties from time to time, the
          Borrower shall provide Repayment Instruments for payment of Borrower’s
          Dues. The Borrower shall honour all payments without fail on the first
          presentation of Repayment Instruments on each Due Dates. The Repayment
          Instruments provided by the Borrower may be utilized by the Lender(s)
          and other Credit Line Finance Parties to realize any Borrower’s Dues.
          The Borrower hereby unconditionally and irrevocably authorizes the
          Lender(s) and other Credit Line Finance Parties to take all actions
          required for such realization. The Borrower shall promptly (and in any
          event within seven (7) days) replace Repayments Instrument and/or
          other documents executed for payment of Borrower’s Dues as may be
          required by the Lender(s) and other Credit Line Finance Parties from
          time to time at their absolute discretion. The Borrower shall always
          maintain sufficient funds in his/her/their bank account/s for the due
          payment of the Borrower’s Dues on respective Due Dates. The Borrower
          shall not close the bank account/s from which the Repayment
          Instruments have been issued or cancel or issues instructions to the
          bank or to the Lender(s) and other Credit Line Finance Parties to stop
          or delay payment under the Repayment Instruments, and the Lender(s)
          and other Credit Line Finance Parties are not bound to take notice of
          any such communication. The Borrower confirms and understands that the
          Lender(s) and other Credit Line Finance Parties at their discretion
          may retain the Credit Line Application Form, the photographs,
          information, and documents submitted by the Borrower. The Borrower
          also confirms that the Lender(s) and other Credit Line Finance Parties
          shall have all right to retain such documents for its internal records
          as per document retention policies adopted by the Lender(s) and other
          Credit Line Finance Parties. The Borrower confirms and acknowledges
          that the Repayment Instruments have been issued voluntarily in the
          discharge of the Borrower’s Dues and not by way of security for any
          purpose whatsoever. The Borrower shall be liable to pay dishonor
          charges for each Repayment Instrument dishonor (as prescribed in the
          Financing Documents and Greattr Terms). Any dispute or difference of
          any nature whatsoever shall not entitle the Borrower to withhold or
          delay payment of any EMIs or other sum, and the Lender(s) and other
          Credit Line Finance Parties shall be entitled to present the Repayment
          Instruments on the respective Due Dates. Notwithstanding the issuance
          of Repayment Instruments, the Borrower will be solely responsible for
          ensuring timely payment of dues. After due discharge of all the
          Obligations of the Borrower, the Lender(s) and other Credit Line
          Finance Parties shall have the right to destroy the same, as it may
          deem fit and proper subject to its document retention policy. The
          Borrower shall not have any right to demand the return of any
          documents.{" "}
          <h1>BORROWER’S COVENANTS, REPRESENTATIONS, AND WARRANTIES</h1> The
          Borrower undertakes/confirms that they shall: Observe and perform all
          its Obligations (including payment of Borrower’s Dues) under the
          Financing Documents and Greattr Terms. They shall on demand made by
          the Lender(s) and other Credit Line Finance Parties, pay the amount of
          any Increased Costs incurred by the Lender(s) and other Credit Line
          Finance Parties as a result of: The introduction of or any change in
          (or in the interpretation, administration, or application of) any law
          or regulation, or Compliance with any law or regulation made after the
          date of grant of the Credit Line. For the purpose of this clause,
          "Increased Cost" means: An additional or increased cost; A reduction
          in the rate of return from the Credit Line, or A reduction of an
          amount due and payable under or in relation to the Credit Line, which
          is incurred or suffered by the Lender(s) and other Credit Line Finance
          Parties but only to the extent attributable to the Lender(s) and other
          Credit Line Finance Parties. Immediately deliver to the Credit Line
          Finance Parties all documents/information, including bank account
          statements, as may be required by the Lender(s) and other Credit Line
          Finance Parties from time to time. The Borrower request and authorizes
          the Credit Line Finance Parties to communicate independently with, Any
          bank where the Borrower maintains an account and to seek details and
          statements in respect of such an account from the bank. With any
          employer of any Borrower as the Credit Line Finance Parties may deem
          necessary, including for monitoring Borrower’s creditworthiness.
          Family members, friends, and relatives of the Borrower, and Such other
          persons as deem fit by the Credit Line Finance Parties. Immediately
          notify the Credit Line Finance Parties of any litigations or legal
          proceedings against the Borrower. Notify the Credit Line Finance
          Parties of any Material Adverse Effect or Event of Default. Notify the
          Credit Line Finance Parties in writing of all changes in the location/
          address of office/ residence /place of business or any change/
          resignation/termination/closure of employment/ profession /business.
          Always comply with applicable laws, including, Prevention of Money
          Laundering Act, 2002. Utilize each Drawdown only for lawful and
          permitted purposes. The calculation with respect to the EMI, Interest
          Rate, Default Rate/Borrower’s Due, Processing Fee, Documentation
          Charges, legal expenses, collection charges, cost, and expenses, etc.
          shall be binding on the Borrower in the manner calculated by the
          Lender(s) and other Credit Line Finance Parties. Further, the Borrower
          understands and confirm that each Lender and other Credit Line Finance
          Parties will maintain in accordance with its usual practice, accounts
          in its books evidencing the amounts from time to time owed to it
          therein and a certificate in writing signed by an officer of the
          Lender(s) and other Credit Line Finance Parties stating the amount at
          any particular time due and payable to the Lender(s) or the other
          Credit Line Finance Parties shall be conclusive and binding on the
          Borrower. That in any legal action or proceeding arising out of or in
          connection with this contract, the entries made in the accounts
          maintained pursuant to this contract shall be prima facie evidence of
          the existence and amounts of the Obligations of the Borrower. That the
          Lender(s) and other Credit Line Finance Parties shall be entitled to
          serve Demand Notice directly or through other Credit Line Finance
          Parties or other service providers and the amount mentioned in such
          notice shall be deemed to be admitted liability of the Borrower. On
          request of the Credit Line Finance Parties, the Borrower shall
          intimate and instruct the employer/s of the Borrower to transfer every
          month from the Borrower' salary/emoluments a specific sum (being the
          Instalments) towards the repayment to the Lender(s) and other Credit
          Line Finance Parties as per terms of the Financing Documents and other
          Greattr Terms. The Borrower confirms that the Credit Line Finance
          Parties shall have the authority to approach the employer/s of the
          Borrower directly for repayment of instalments and/or any other
          charges/sums due from the Borrower to the Lender(s) and other Credit
          Line Finance Parties including but not limited to that of the
          Borrower's Due under Financing Documents and/or the Greattr Terms. The
          Borrower confirms to the Credit Line Finance Parties that the Credit
          Line Finance Parties shall be authorized to seek payments towards the
          Credit Line and other services as per the Financing Documents and/or
          the Greattr Terms from an employer of the Borrower or from any person
          from whom the Borrower is entitled to receive money (“Entitlement”).
          The Borrower confirm that such an employer or the person from whom the
          Borrower is entitled to receive money shall be entitled to deduct from
          Borrower’s Entitlements and transfer the said amount to the Credit
          Line Finance Parties for satisfaction of dues in part or full (as
          applicable). The Borrower also confirms that such an employer or the
          person from whom the Borrower is entitled to receive money is
          sufficiently notified to honour a request from the Credit Line Finance
          Parties for transfer of Borrower’s Entitlements in part or full as may
          be requested by the Credit Line Finance Parties and such persons have
          confirmed to the same. That the Borrower irrevocably authorizes the
          Credit Line Finance Parties to have the first charge on their assets,
          including amounts lying in their bank accounts, mutual fund,
          insurance, securities, receivables, etc., to the extent of dues owed
          by them and for the due performance of their Obligations. The charge
          on such assets and payment Obligations to the Credit Line Finance
          Parties shall not be subordinated to the rights of any third person.
          That the Borrower declares that they are not an employee or a
          contractor or relative to any employee or contractor of any of the
          Credit Line Finance Parties, in case they become an employee or a
          contractor or relative to any employee or contractor of any of the
          Credit Line Finance Parties they shall immediately repay the Credit
          Line. The expression relative shall have the meaning as defined in the
          Companies Act, 2013. Each Borrower represents and warrants to each of
          the Credit Line Finance Parties as under: All the information provided
          by Borrower in the Credit Line Application Form and any other
          document, whether relevant for ascertaining the Borrower's
          creditworthiness, is true and correct and not misleading in any
          manner. The Borrower is capable of and entitled under all applicable
          laws to execute and perform the Financing Documents and the
          transactions thereunder. The Borrower is above 18 years of age, and
          this contract is a legal, valid, and binding Obligation on him/her,
          enforceable against him/her in accordance with its terms. The Borrower
          declares that any law does not prohibit them from availing this Credit
          Line. No event has occurred that shall prejudicially affect the
          interest of the Credit Line Finance Parties or affect Borrower's
          financial conditions or affect his/her liability to perform all their
          Obligations under the Financing Documents. The Borrower is not in
          default of payment of any taxes or government dues. The Borrower will
          do all acts, deeds, and things, as required by the Credit Line Finance
          Parties, to affect these Terms. No bankruptcy and/or insolvency
          proceedings have been initiated/are pending against the Borrower. All
          the representations and warranties given by the Borrower shall subsist
          and have a continuing effect at the time of each Drawdown and during
          the Credit Line's currency. The Borrower hereby consents that the
          Credit Line Finance Parties and/or their authorized representative/s
          may communicate with the Borrower either by phone calls, SMS,
          electronic mails or through any other mode of communication available
          for the purpose of discussing the current status of his Credit Line or
          reminder/ collection of any dues in respect of any Credit Line or for
          any matter related to the Credit Line and such phone calls, SMS, etc.,
          shall not be covered under the purview of “Do Not Disturb” policy of
          the Telecom Regulatory Authority of India (TRAI). For this purpose,
          Borrower hereby grants permission to Credit Line Finance Parties, to
          contact him any time between 07.00 hours to 21.00 hours from Monday to
          Sunday. The Borrower confirms that, in the case of telephone
          communications (including AVR, SMS, mobile applications, etc.) or
          online customer portal, as the case may be, the Credit Line Finance
          Parties may require the Borrower to use/enter a password allotted by
          the Credit Line Finance Parties to such Borrower or may ask the
          Borrower questions about himself and about particulars of the
          Borrower's account(s) including a personal identification number in
          order to verify the Borrower's identity and/ or may require a
          call-back procedure, all as deemed appropriate by the Credit Line
          Finance Parties. The Borrower is obliged to keep any password and an
          identification number designated by or provided to him hereunder as
          confidential, and the Borrower shall be responsible for any
          consequence that may arise from the use of such a password by any
          other person. The Credit Line Finance Parties shall not be liable in
          any manner for access to the Borrower's account by use of the user
          password by any Person whomsoever. The Borrower irrevocably and
          unconditionally consents to Credit Line Finance Parties recording of
          all the Borrower's electronic communication (e.g., telephone calls,
          electronic mail, SMS, mobile application or other) and storage of
          electronic media by Credit Line Finance Parties and accepts such
          recordings and electronic media as evidence with regard to acceptance
          of all the terms of the Credit Line including, but not limited to, the
          grant of the Credit Line or loan, levy of any fee/ charges, Interest
          Amount over each such Credit Line(s), rate of Interest applicable on
          each such Credit Line, the period for each such Credit Line borrowed
          by Borrower and the number/ amount of monthly instalment that the
          Borrower has to pay to Credit Line Finance Parties. The Borrower
          further accepts that such a record may be used by Credit Line Finance
          Parties, as evidence in a court of law or any legal proceedings. The
          Borrower gives its consent to each of the Credit Line Finance Parties
          jointly and severally to use/store all the information provided by the
          Borrower or otherwise procured by the Credit Line Finance Parties in
          the manner they deem fit including for the purposes of this Credit
          Line or its business. The Borrower understands and confirms that the
          Credit Line Finance Parties may disclose such information to their
          contractors, agents, and any other third parties. The Borrower
          understands and confirms that any promotional schemes and/or contest
          being initiated by the Credit Line Finance Parties for its customers
          is independent of these Terms and the Credit Line, and such
          promotional schemes and/or contests do not constitute any kind of
          solicitation by the Credit Line Finance Parties. Such promotional
          schemes and/or contests shall be governed by the terms and conditions
          in relation thereto, which shall be carefully read by the Borrower in
          Case the Borrower decides to participate in such schemes/contests.
          Further, the Borrower confirms that the Credit Line Finance Parties
          shall have the right to withdraw any of the promotional schemes at
          their sole discretion as per its respective terms. The Borrower grants
          consent to the Credit Line Finance Parties to cross-sell its other
          products and services. The Borrower understands that their
          communications with the Credit Line Finance Parties may involve
          electronic means. The Borrower confirms that it shall be their
          responsibility to maintain their personal information such as
          passwords, user id, and such related information confidential to avoid
          any misuse. The Borrower confirms that interaction made by them
          through electronic means such as email, facsimile, SMS text messaging,
          Greattr Platform, online acceptance, etc. shall be legal and binding
          on the Borrower and the Credit Line Finance Parties shall be entitled
          to rely on the same. Therefore, the Borrower shall take adequate care
          while using the Greattr Platform or while giving instructions through
          the Greattr Platform through any electronic means. <h1>INSURANCE</h1>{" "}
          The Credit Line Finance Parties may at its own discretion and upon
          Borrower's request, also finance the Borrower for the insurance
          premium of insurance policy taken by Borrower as per Borrower's own
          wish from any insurance company of Borrower's choice, which sum(s)
          shall be added to the principal amount under the Credit Line and all
          the terms and conditions shall be additionally applicable thereto. All
          expenses, charges, fees, taxes, etc. as applicable on any such
          insurance shall be incurred and paid by the Borrower, however, in case
          paid by the Credit Line Finance Parties on Borrower's behalf, the
          Borrower shall reimburse the same to the Credit Line Finance Parties
          (as applicable) within 24 hours of the request made by the respective
          Credit Line Finance Party. The Borrower shall instruct the insurance
          company to add the Credit Line Finance Parties (as applicable) as loss
          payee in any such insurance policy and hand over a copy of the same in
          the manner required by the Credit Line Finance Parties (as
          applicable).
          <h1> EVENTS OF DEFAULTS</h1> The following acts/events, as set out
          below, shall each constitute an “Event of Default” by the Borrower for
          the purposes of each Credit Line: The Borrower fails to make payment
          of any Borrower’s Dues on Due Date or fails to perform any Obligation.
          Breach of any terms, covenants, representation, warranty, declaration
          or confirmation under any of the Financing Documents and/or Greattr
          Terms. Any fraud or misrepresentation or concealment of material
          information by Borrower which could have affected the decision of the
          Credit Line Finance Parties to grant any Credit Line or services (as
          applicable). Death, lunacy, or any other permanent disability of the
          Borrower. Change of residency status of the Borrower. Borrower
          utilizes the Drawdown for any purpose other than the permissible
          purposes. The occurrence of any events, conditions or circumstances
          (including any change in law) which in the independent and absolute
          opinion of the Credit Line Finance Parties could have a Material
          Adverse Effect, including limitation of any proceedings or action for
          bankruptcy/liquidation/ insolvency of the Borrower or
          attachment/restraint of any of its assets. Breach of any terms of the
          Financing Documents by the Borrower or performance of Obligations of
          the Borrower (including breach towards non-payment or Borrower’s Dues)
          as per terms of the Financing Documents in a manner not satisfactory
          to the Lender and other Credit Line Finance Parties. Any default by
          the Borrower under any other contract between the Borrower and any
          creditor or any other contract or indebtedness of the Borrower or the
          performance of any covenant, term or undertaking thereunder or any
          indebtedness of any of the Borrower is not paid when due or any
          creditor of the Borrower becomes entitled to declare any such
          indebtedness due and payable before the date on which it would
          otherwise have become due, or any guarantee or indemnity given by the
          Borrower is not honored, when due and called upon. Initiation of any
          insolvency/bankruptcy proceedings against the Borrower. The Credit
          Line Finance Parties' decision as to whether an Event of Default has
          occurred shall be binding upon the Borrower. Effect of Event of
          Default. Upon the occurrence of any Event of Default, all amounts
          payable under any of the Financing Documents and Greattr Terms shall
          be due and payable forthwith without any notice or further actions
          from the Credit Line Finance Parties. This shall be without prejudice
          to other legal rights and remedies which the Credit Line Finance
          Parties shall be entitled to pursue. <h1>CONSEQUENCES OF DEFAULT</h1>{" "}
          Upon the occurrence of any of the Events of Default and at any time
          thereafter, the Credit Line Finance Parties shall have the right, but
          not the obligation to declare all sums outstanding in respect of the
          Credit Line, whether due or not, immediately repayable and upon the
          Borrower fails to make the said payments within 15 (fifteen) days
          thereof, the Credit Line Finance Parties may look at their discretion
          exercise any other right or remedy which may be available to the
          Credit Line Finance Parties under any applicable law, including
          seeking any injunctive relief or attachment against the Borrower or
          their assets or initiate other civil and criminal proceedings (as may
          be required) which may include but not limited to the proceedings
          under section 138 of the Negotiable Instruments Act, 1881 and under
          section 25 of the Payments and Settlement Systems Act, 2007. The
          Borrower shall also be responsible and liable for payment of all legal
          and other costs and expenses resulting from the foregoing defaults or
          the exercise of the remedies of the Credit Line Finance Parties and
          towards any claims made by the Credit Line Finance Parties towards
          costs incurred by them.
          <h1> DISCLOSURES </h1>The Credit Line Finance Parties will take the
          best endeavour to maintain the confidentiality imposed on it by
          applicable law. The Borrower acknowledges/s, accept/s and consent/s
          that the Credit Line Finance Parties shall be entitled to disclose
          information: To third parties for the purposes of credit reference
          checks, verification, etc., disclose any information/documents
          relating to the Borrower under these Terms to, other Credit Line
          Finance Parties including Service Providers, Affiliates or to
          prospective transferees or purchasers of any interest in the Credit
          Line or as required by law or any government order or direction
          including disclosure as may be necessary to perform or fulfil any
          requirement specified by the Reserve Bank of India or as deemed
          necessary by the Credit Line Finance Parties while exercising its
          remedies under these Terms or the other Financing Documents.
          Information and data relating to the Borrower; The information or data
          relating to any Credit Line availed of/to be availed by the Borrower.
          Default, if any, committed by the Borrower; For protecting its
          interests to Income Tax authorities and other authorities; To Credit
          Rating Agencies or Credit Information Companies, or any other
          Government; Any other Regulatory Authorities/Bodies/
          Departments/authorities as and when so demanded. The Borrower hereby
          waives the privilege of privacy and defamation explicitly. The
          Borrower further confirms and gives specific consent to the Credit
          Line Finance Parties for disclosing/submitting the 'financial
          information' as defined in Section 3 (13) of the Insolvency and
          Bankruptcy Code, 2016 ('Code' for brief ) read with the relevant
          Regulations/ Rules framed under the Code, as amended and in force from
          time to time and as specified thereunder from time to time, in respect
          of the Credit/ Financial facilities availed from the Credit Line
          Finance Parties, from time to time, to any 'Information Utility' (
          'IU' for brief ) as defined in Section 3 (21) of the Code, in
          accordance with the relevant regulations framed under the Code, and
          directions issued by Reserve Bank of India from time to time and
          hereby specifically confirm to promptly authenticate the ‘financial
          information submitted by the Credit Line Finance Parties, as and when
          requested by the concerned ‘IU’. The Borrower shall not hold the
          Credit Line Finance Parties responsible for sharing and/or disclosing
          the information now or in the future and for any consequences suffered
          by the Borrower and/or other by reason thereof. The provisions of this
          clause shall survive termination of these Terms and the repayment of
          the Borrower’s Dues. The Borrower, as a precondition of the Credit
          Line given by the Credit Line Finance Parties, confirms that, in case,
          the Borrower commits default in the repayment on the Due Date, the
          Credit Line Finance Parties and/or the Reserve Bank of India, shall
          have an unqualified right to disclose or publish the name of the
          Borrower as ‘defaulters’ in such manner and through such medium as the
          Credit Line Finance Parties or Reserve Bank of India in their absolute
          discretion may think fit.
          <h1> ONEAGROW AS LENDER’S AGENT AND OTHER SERVICE PROVIDERS</h1> The
          Borrower confirms that they have no objection to OneAgrow acting as a
          Lender’s Agent and performing duties assigned by the Credit Line
          Finance Parties from time to time. The Borrower also confirms that
          instructions given by OneAgrow in relation to Credit Line shall be
          binding on them. The Borrower confirms that without prejudice to any
          rights of other Credit Line Finance Parties, OneAgrow shall be
          entitled to give instructions to the Borrower, issue notice to the
          Borrower, collect Repayment Instruments, repayment amount, etc. The
          Borrower expressly recognizes and accepts that the Credit Line Finance
          Parties shall jointly or severally without prejudice to their rights
          to perform such activities themselves or through their office
          employees be entitled and have full power and authority so to appoint
          one or more third parties including but not limited to OneAgrow
          (hereinafter referred to as “Service Providers") as the Credit Line
          Finance Parties may select and to delegate to such party all or any of
          their functions, rights, and power under Financing Documents and
          Greattr Terms relating to the sourcing, administration, monitoring of
          the Credit Line and to perform and execute all lawful acts, deeds,
          matters and things connected therewith and incidental thereto
          including sending notices contacting Borrower, receiving Repayment
          Instruments from the Borrower in favor of the Credit Line Finance
          Parties, etc. By availing the Credit Line and by accepting the terms
          herein mentioned, Borrower confirms as follows: While applying for the
          Credit Line through the Greattr Platform, the Borrower has visited the
          partner lending page on the Greattr Platform. The Borrower also
          confirms that they also visited the relevant Lender's website and have
          perused the relevant details required for understanding and
          confirmation to be granted for availing the Credit Line. The Borrower
          also confirms that they shall be obliged to keep themselves updated
          about the changes to these Terms made by the Greattr Platform and or
          any Credit Line Finance Parties. The Borrower also confirms that they
          shall have no objections in OneAgrow acting as an agent of the Lenders
          (s), Lender’s Agents, or on behalf of the Lender(s) or in such manner
          as may be decided by OneAgrow from time to time. The Borrower also
          confirms that they shall be bound by any contractual provisions
          undertaken to the Lender(s) by OneAgrow. The Borrower also confirms
          that OneAgrow will be entitled to act as customer support, collection
          agent, sales agent, technology support, and for such other
          support/services as deem fit by the OneAgrow. The Borrower also grants
          and agrees to consent to OneAgrow (as required) to perform such
          functions through electronic or non-electronic means, including the
          Greattr Platform or through its service providers or through such
          other means as deem fit by OneAgrow from time to time.{" "}
          <h1>ELECTRONIC INTERACTIONS</h1> The Borrower hereby confirms that the
          electronic acceptance of these Terms, Greattr Terms, or any Financing
          Documents results in a binding contract between the Borrower and the
          Credit Line Finance Parties (as may be applicable). The Borrower is
          aware that the transmission of these Terms, Greattr Terms or any of
          the Financing Documents, terms and conditions, instructions,
          acceptances, and communications can be through electronic means such
          as email, facsimile, SMS text messaging, websites, Greattr Platform,
          WhatsApp, Viber, telegram, Signal (or similar), online/electronic
          acceptance, etc. (“Electronic Form”). It is informed to the Borrower
          that interaction through Electronic Form involves several risks,
          including fraudulent alterations and incorrect transmissions and
          absence of confidentiality. The Borrower has taken note of such risk,
          and pursuant to that proceeded to confirm the Terms herein. The
          Borrower confirms that the Borrower shall be liable for any loss
          suffered by the Credit Line Finance Parties based on communication by
          the Borrower through Electronic Form. <h1>MISCELLANEOUS</h1>{" "}
          Notwithstanding any suspension or termination of any Credit Line, all
          rights, and remedies of the Credit Line Finance Parties as per
          Financing Documents and Greattr shall continue to survive until the
          receipt by the Credit Line Finance Parties of the Borrower’s Dues in
          full. The Borrower acknowledges that the financing transaction
          hereunder gives rise to a relationship of debtor and creditor between
          him/her/it and the Lender(s) and not in respect of any service
          rendered/to be rendered by the Lender(s). Accordingly, the provisions
          of the Consumer Protection Act of 1986 shall not apply to the
          transaction hereunder with the Lender(s). The Borrower hereby
          authorizes each of the Credit Line Finance Parties to verify all
          information and documents including, income proof documents, residence
          documents, address proof documents, identity documents and other such
          documents containing personal and financial information as are
          submitted by the Borrower for obtaining any Credit Line, and the
          Borrower also consents to subsequent retention of the same by the
          Credit Line Finance Parties. The Borrower hereby authorizes each of
          the Credit Line Finance Parties to perform reference checks about the
          Borrower in the manner satisfactory to the Credit Line Finance
          Parties. The Borrower acknowledges and authorizes each of the Credit
          Line Finance Parties to procure Borrower’s PAN No./ copy of Pan Card,
          other identity proof and Bank account details, from time to time and
          to generate/obtain CIBIL, Experian, Hunter reports and such other
          reports (including detailed background verification reports) as and
          when the Credit Line Finance Parties may deem fit. In the event of any
          disagreement or dispute between the Credit Line Finance Parties and
          the Borrower regarding the materiality of any matter including of any
          event occurrence, circumstance, change, fact information, document,
          authorization, proceeding, act, omission, claim, breach, default or
          otherwise, the opinion of the Credit Line Finance Parties as to the
          materiality of any of the foregoing shall be final and binding on the
          Borrower. The Borrower acknowledges that each of his/her/its
          Obligations under these Financing Documents and Greattr Terms is
          independent and severable from the rest. If any Obligation is found
          not enforceable by a court of law, for any reason, the Borrower shall
          continue to remain bound by the other Obligations stipulated herein.
          If any provision of the Financing Documents and Greattr Terms is
          prohibited or unenforceable in any jurisdiction, shall not invalidate
          the remaining provisions of the Financing Documents. All Credit Line
          and the Financing Documents shall be governed by and construed in
          accordance with the laws of India. All disputes, differences and/or
          claims arising out of these presents or as to the construction,
          meaning or effect hereof or as to the right and liabilities of the
          parties under the Financing Documents and Greattr Terms shall be
          settled by arbitration in accordance with the provision of the
          Arbitration and Conciliation Act, 1996 or any statutory amendments
          thereof or any statute enacted for replacement therefore and shall be
          referred to a sole Arbitrator to be appointed by the Credit Line
          Finance Parties. The place of arbitration shall be Mumbai. The
          Arbitrator may lay down from time to time the procedure to be followed
          by him in conducting arbitration proceedings and shall conduct
          arbitration proceedings in such manner as he considers appropriate and
          is hereby expressly authorized to adopt such summary/fast-track
          procedure for the conduct of the proceedings as she/he may deem fit,
          including dispensing with oral hearings and issue the award within six
          (6) months from the date the arbitral tribunal enters upon the
          reference. The awards, including interim awards of the arbitration,
          shall be final and binding on all parties concerned. The arbitrator
          may pass the award without stating any reasons for such an award.
          Further, the present clause shall survive the termination of Financing
          Documents and Greattr Terms. The Courts at Mumbai, India shall have
          exclusive jurisdiction (subject to the arbitration proceedings which
          are to be also conducted in Mumbai, India) over any or all disputes
          arising out of the Financing Documents. Any notice to be given to the
          Borrower in respect of Financing Documents shall be deemed to have
          been validly given if served on the Borrower. Any notice to the Credit
          Line Finance Parties shall be deemed to have been valid only if
          received by the respective Credit Line Finance Parties. The Borrower
          may approach OneAgrow in case of any grievances pertaining to the
          Credit Line. If not resolved, they can also contact the Lender(s). The
          Borrower shall not be entitled to jointly or severally transfer or
          assign all or any of their rights, Obligations or duties under the
          Financing Documents to any person directly or indirectly or create any
          third-party interest in favor of any person without the prior written
          consent of the Credit Line Finance Parties.
          Assignments/Securitization/Participation by Lender(s). Each Lender
          reserves the right to assign/sell/securitize the Credit Line forming
          part of the Credit Line, by transferring and/or assigning or otherwise
          all its right, title and interest which such Lender(s) deems
          appropriate and the Borrower hereby expressly confirms that in that
          event, the Lender(s) are not required to obtain any permission or give
          the Borrower any notice. The Borrower shall be bound to accept any
          such securitization and any such sale, assignment or transfer and the
          Borrower shall accept such other party/ies as creditors exclusively or
          as a joint creditor with the Lender(s), or as a creditor exclusively
          with the right of the Lender(s) to continue to exercise all powers
          hereunder on behalf of any such other party. In the event of default,
          any cost in this behalf, whether on account of such sale, assignment
          or transfer or enforcement of rights and recovery of outstanding dues
          shall be to the account of the Borrower. The Borrower undertakes to
          pay third parties the difference between the Credit Line outstanding
          and the amount received by the Lender(s) in the event of transfer to a
          third party. The Lender(s) may sell risk participations to one or more
          persons in all or a portion of its rights and Obligations under these
          Terms and other Financing Documents and Borrower consent to such
          arrangement by the Lender(s). The Borrower shall duly execute such
          other documents as may be requested by the Credit Line Finance Parties
          and the risk participating person. The Borrower shall not assign,
          transfer, or novate any interest in its rights and/or Obligations,
          without the prior written consent of the Credit Line Finance Parties.
          The Financing Documents and Greattr Terms shall be binding upon and
          inure to the benefit of the Borrower and the Credit Line Finance
          Parties (as may be applicable) and their respective successors and
          assigns, except that the Borrower shall not have the right to assign
          its rights hereunder or any interest therein without the prior written
          consent of the Credit Line Finance Parties. Each Borrower confirms/s
          that the Credit Line Sanction Terms, Credit Line Details Sheet, other
          Financing Documents, and Greattr Terms (as may be applicable)
          executed/confirmed by one of the Borrower shall be conclusive evidence
          of the withdrawal of the Credit Line. Each Borrower undertakes to
          perform corresponding Obligations and make a payment towards
          Borrower’s Dues. Any omission or delay on the part of any of the
          Credit Line Finance Parties, in exercising any of their rights, powers
          or remedy, upon failure by the Borrower in the due and punctual
          fulfilment of the Obligations of the Borrower hereunder, shall not be
          deemed to constitute a waiver by the Credit Line Finance Parties of
          any of their rights to require such due, a punctual and full
          performance by the Borrower. The Borrower shall pay all stamp duty,
          registration cost, fees, and out-of-pocket expenses incurred by it in
          connection with the preparation, execution, and delivery of the
          Financing Documents and the other documents to be delivered hereunder.
          The Borrower confirms to indemnify and hold harmless the Credit Line
          Finance Parties from and against all claims, damages, liabilities, and
          expenses (including fees of counsel) which may be incurred by or
          asserted against the Credit Line Finance Parties in connection with or
          arising out of any, investigation, litigation or proceeding (whether
          or not the Credit Line Finance Parties is a party thereto) related to
          any use or proposed use of the proceeds of the debt by the Borrower.
          The Borrower shall also indemnify the Credit Line Finance Parties and
          keep the Credit Line Finance Parties indemnified in respect of any
          actions, claims, costs, damages, demands, expenses, losses, and
          liabilities made against, suffered or incurred by the Credit Line
          Finance Parties arising directly or indirectly from or in connection
          with: Any failure by the Borrower to comply with the provisions of the
          Financing Documents. Any liability, including third-party liability.
          Any claims, losses, demands, actions, costs, expenses, and liabilities
          incurred or suffered by the Credit Line Finance Parties because of the
          representations and warranties given by the Borrower being false or
          untrue. In the case of multiple Borrowers, Each Borrower confirms to
          act as agent of the other, any Obligation undertaken by a Borrower in
          favor of the Credit Line Finance Parties shall be binding on the
          other. Each Borrower confirms that each of them shall have a joint and
          several liability and responsibility to discharge all and any
          Obligations towards the Credit Line Finance Parties as per terms of
          these Terms, other Financing Documents, and Greattr Terms. The grant
          of the Credit Line or services by the Lender(s) and other Credit Line
          Finance Parties, shall not vest in the Borrower or any other person a
          right to claim any damages from the Lender(s) and other Credit Line
          Finance Parties, for any reason whatsoever. All covenants,
          representations, warranties of the Borrower under the Financing
          Documents shall continue in full force and effect until all
          Obligations have been satisfied. The indemnification Obligations of
          the Borrower shall also survive the Financing Documents' and Greattr
          Documents’ termination and shall be deemed to be continuing and in
          full force and effect, subject to applicable laws. Further, all
          clauses of the Financing Documents, which are expressly stated as
          surviving termination of the Financing Documents, shall survive the
          Financing Documents' termination. THE BORROWER CONFIRMS HAVING
          PERUSED, UNDERSTOOD, AND CONFIRM TO THE ABOVE CLAUSES INCLUDING THE
          CREDIT LINE AND THE RELEVANT CREDIT LINE FINANCE PARTIES’ METHOD OF
          CALCULATING INSTALLMENT AND APPLICABLE CHARGES. THE TERMS ABOVE AND
          OTHER DOCUMENTS HAVE BEEN EXPLAINED TO THE BORROWER IN THE LANGUAGE
          UNDERSTOOD BY THE BORROWER, AND THE BORROWER HAS UNDERSTOOD THE ENTIRE
          MEANING OF THE VARIOUS CLAUSES STATED IN THESE TERMS. THE BORROWER IS
          AWARE THAT LENDER(S) HAS ACCEPTED TO GRANT CREDIT LINE TO BORROWER
          ONLY BASED ON THE REPRESENTATIONS AND WARRANTIES MADE BY THE BORROWER
          AND THE UNDERTAKING PROVIDED BY THE BORROWER TO ABIDE BY THESE TERMS.
        </div>
      </div>
    </>
  );
};

export default CreditLine;
