export const validateEmail = (value) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(value);
};

export const validateAadhaarNo = (value) => {
  const regex = /^\d{12}$/;
  return regex.test(value);
};

export const validatePanNo = (value) => {
  const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return regex.test(value);
};

export const validateMobileNo = (value) => {
  const regex = /^[789]\d{9}$/;
  return regex.test(value);
};

export const validateGstNumber = (value) => {
  const regex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Zz]{1}[A-Z\d]{1}$/;
  return regex.test(value);
};

export function maskAadhaar(aadhaarNumber) {
  // Remove hyphens from the Aadhaar number
  let cleanedAadhaar = aadhaarNumber.replace(/-/g, "");

  // Mask the state code (first two digits) with 'XX'
  let maskedAadhaar = "XXXXXXXX" + cleanedAadhaar.substring(8);

  return maskedAadhaar;
}

export const validateUdyamAadhar = (value) => {
  const regex = /^\d{12}$/;
  return regex.test(value);
};
