import React, { useState, useContext } from "react";
import "../OperatorLogin.scss";
import greattrlogo from "../assets/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  setToken,
  getToken,
  removeToken,
  setRefreshToken,
} from "../utils/auth";

import { validateMobileNo } from "../utils/validations";
import log12 from "../assets/original-suraksha.png";
import { GDSRecords, GESRecords, GES, GDS } from "../../Utilsx/Encrypt";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../utils/encryptionUtils";
import NavContext from "../../Utilsx/navUtils";
import LeftContent from "./components/LeftContent";
const SafetyCheckMerchantLogin = () => {
  const { isLogin, setisLogin, isOperatorLogin, setisOperatorLogin } =
    useContext(NavContext);
  const [MobileNumber, setMobileNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPVisible, setOTPVisible] = useState(false);
  const [isRequestedOtp, setIsRequestedOtp] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(1);
  let selectedCompanyName = "BPCL";
  const [company, setCompany] = useState("BPCL");
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  const handleSelect = (id) => {
    setSelectedCompany(id);
    selectedCompanyName =
      companies.find((company) => company.id === id)?.name || "None";
    console.log("select compnay ", selectedCompanyName);
    setCompany(selectedCompanyName);
  };

  const companies = [
    {
      id: 1,
      name: "BPCL",
      image: process.env.PUBLIC_URL + "/img/BPCL.png",
    },
    {
      id: 2,
      name: "TOTAL",
      image: process.env.PUBLIC_URL + "/img/TOTAL.png",
    },

    { id: 3, name: "HPCL", image: process.env.PUBLIC_URL + "/img/HPCL.png" },
    {
      id: 4,
      name: "IOCL",
      image: process.env.PUBLIC_URL + "/img/IOCL.png",
    },
  ];
  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleOTPChange = (e) => {
    if (e.target.value.length < 7) {
      setOTP(e.target.value);
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    if (MobileNumber === "8779096439" && OTP === "123456x") {
      //   navigate("/api-hub/introduction");
      alert("admin login");
      setToken("xyzsa");
      navigate("/DistSafetyCheckCompanyInfo", {
        state: {
          MobileNumber: "8779096439",
        },
      });
    } else {
      const encryptedData = GESRecords(
        {
          mobile: MobileNumber,
          otpinput: OTP,
        },
        apiEN
      );

      try {
        setisOperatorLogin(false);

        console.log(`${process.env.REACT_APP_API_LINK}/SafetyCheck/MerchantOtpLogin`);

        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/SafetyCheck/MerchantOtpLogin`,
          encryptedData
        );

        if (response.status === 200) {
          if (response?.data?.message) {
            if (response?.data?.message === "SUCCESS") {
              console.log('response.data: ', response.data);

              setToken(response.data.token);
              setisOperatorLogin(false);
              let GreattrDistID = response.data.GreattrDistID;
              let GreattrDealerID = response.data.GreattrDealerID;

              localStorage.setItem("MobileNumber", MobileNumber);
              localStorage.setItem("GreattrDistId", GreattrDistID);
              console.log('inside111');
              navigate(response.data.navigate, {
                state: {
                  MobileNumber: MobileNumber,
                  parentCompany: company,
                  GreattrDistId: GreattrDistID,
                  GreattrDistID: GreattrDistID,
                  GreattrDealerID: GreattrDealerID,
                  role: response?.data?.role || "Dist",
                },
              });
              window.location.reload();

              //     alert("admin login");
            } else {
              alert(response?.data?.message);
            }
          }
        }
        // Handle the response data (e.g., update state, display a message)
        console.log("Response data:", response.data);
      } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error("Error:", error);
      }
    }
  };

  const toggleOTPVisibility = () => {
    setOTPVisible(!OTPVisible);
  };
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateMobileNo(MobileNumber)) {
      alert("Please enter a valid 10 digit mobile number");
      return;
    }

    const encryptedData = GESRecords(
      {
        mobile: MobileNumber,
        OTP,
      },
      apiEN
    );

    if (MobileNumber === "8779096439" && OTP === "123456") {
      //   navigate("/api-hub/introduction");
      alert("admin login");
      setIsRequestedOtp(true);
      // setToken("xyzsa");
      // navigate("/PersonalDetails", {
      //     state: {
      //         MobileNumber: "8779096439",
      //     },
      // });
    } else {
      console.log("Production OTP login");
      try {
        console.log("inside1");
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/sms/GreattrMerchant/generateOTP`,
          encryptedData
        );

        console.log("response:", response);

        if (response.status === 200) {
          setIsRequestedOtp(true);

          const data = response.data;
          alert(data.message);
          //   console.log(data);
          //   setToken(data.token);
          //   console.log(data.token);
          //   setRefreshToken(data.refreshToken);
          //   navigate("/api-hub/introduction");
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);

          if (error.response.status === 401) {
            // Invalid OTP
            alert("Invalid OTP. Please check your OTP and try again.");
          } else if (
            error.response.status === 400 &&
            error.response.data.error === "User not found"
          ) {
            // User not found
            alert(
              "User not found. Please register before attempting to log in."
            );
          } else {
            // Generic error
            alert("Login failed. Please check your credentials and try again.");
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Request data:", error.request);
          alert("No response from the server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
          alert("An unexpected error occurred. Please try again.");
        }
      }
    }
  };
  let finalFunction =
    isRequestedOtp === true ? handleOTPSubmit : handleFormSubmit;
  return (
    <div className="login-container">
      <div className="left-side">
        <img
          src={log12}
          alt=""
          style={{ width: "100%", padding: '0px 1rem  0 12rem ', flex: 1, alignSelf: "center" }}
        />
      </div>
      <div className="right-side">
        <form className="login-form" onSubmit={finalFunction}>
          <div className="api-hub-info">
            <img
              // className="moblogo"
              style={{
                width: "80%",
              }}
              src={greattrlogo}
              alt=""
            />
            <h3 className="safety-check-form-title">Get ready for a Greattr experience!</h3>
            <p className="select-company">
              To begin this journey, please enter your mobile number.
            </p>
          </div>

          <p className="select-company" style={{ textAlign: "center" }}>
            Select Parent Company
          </p>
          <div className="companies">
            {companies.map((company) => (
              <div
                key={company.id}
                className={`company ${selectedCompany === company.id ? "selected" : ""
                  }`}
                onClick={() => handleSelect(company.id)}
                style={{
                  pointerEvents: (company.name === "HPCL" || company.name === "IOCL") ? "none" : "auto",
                  opacity: (company.name === "HPCL" || company.name === "IOCL") ? 0.2 : 1,
                }}
              >
                <img src={company.image} alt={company.name} />
              </div>
            ))}
          </div>

          <label htmlFor="MobileNumber" className="safety-check-form-label">
            Mobile Number
          </label>
          <input
            type="MobileNumber"
            id="MobileNumber"
            name="MobileNumber"
            placeholder="Enter your Mobile Number"
            className="safety-check-form-input"
            value={MobileNumber}
            onChange={handleMobileNumberChange}
          />
          <div
            style={{
              display: isRequestedOtp ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <label htmlFor="OTP" className="safety-check-form-label">
              OTP
            </label>
            <div className="password-input-container">
              <input
                type={OTPVisible ? "text" : "OTP"}
                id="OTP"
                name="OTP"
                placeholder="Enter your OTP"
                className="safety-check-form-input"
                value={OTP}
                onChange={handleOTPChange}
              />
            </div>
          </div>
          <div
            className="OTP-forgot"
            style={{
              display: isRequestedOtp ? "flex" : "none",
            }}
            onClick={handleFormSubmit}
          >
            <a className="safety-check-form-link">Resend OTP?</a>
          </div>

          <button type="submit" className="safety-check-form-button">
            Get Started
          </button>

          <div className="not-registered" style={{ textAlign: "center" }}>
            By signing up to create an account I accept
            <br />
            Greattr’s {""} Terms of Use & {""}
            <a href="/register" className="form-link">
              Privacy Policy.
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SafetyCheckMerchantLogin;
