import PropTypes from "prop-types";
import "./LeftContent.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import Flexible from "../../SvgComponents/Flexible";

const LeftContent = ({ className = "" }) => {
  return (
    <div className={`left-content ${className}`}>
      <div className="left-content1">
        <img className="logo-1-icon" loading="lazy" alt="" src="/logo-1.svg" />
      </div>
      <Swiper
        style={{ justifyContent: "center", alignItems: "center" }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        pagination={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="greattrlogo-icon"
            loading="lazy"
            alt="greattrlogo"
            src="/Flexible.svg"
            style={{
              marginBottom: 40,
            }}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="greattrlogo-icon"
            loading="lazy"
            alt="BuyNowPayLater"
            src="/BuyNowPayLater.svg"
            style={{ marginBottom: 40 }}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="greattrlogo-icon"
            loading="lazy"
            alt="paperless and easy"
            src="/paperless.svg"
            style={{ marginBottom: 40 }}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="greattrlogo-icon"
            loading="lazy"
            alt="No Collateral"
            src="/Collateral.svg"
            style={{ marginBottom: 40 }}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="greattrlogo-icon"
            loading="lazy"
            alt="Pay Bills"
            src="/Bill.svg"
            style={{ marginBottom: 40 }}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="greattrlogo-icon"
            loading="lazy"
            alt="Bulk LPG"
            src="/BulkLPG.svg"
            style={{ marginBottom: 40 }}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="greattrlogo-icon"
            loading="lazy"
            alt="Instant Processing"
            src="/Instant.svg"
            style={{ marginBottom: 40 }}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

LeftContent.propTypes = {
  className: PropTypes.string,
};

export default LeftContent;
