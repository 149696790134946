import styled from "styled-components";
import PropTypes from "prop-types";
import Grid from "./Grid";

const Section = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem 1.25rem 9.143rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 7.144rem;
  color: var(--text-default);
  font-family: var(--heading-h6);
  @media (max-width: 825px) {
    padding-bottom: 5.938rem;
  }
  @media (max-width: 450px) {
    padding-bottom: 3.875rem;
  }
`;

const WrapperDiv = styled.div`
  width: 85.713rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.5rem;
  max-width: 100%;
  @media (max-width: 825px) {
    gap: 2.75rem;
  }
  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }
  @media (max-width: 450px) {
    gap: 1.375rem;
  }
`;

const LeftColumn = styled.div`
  width: 42.856rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 4.018rem;
  box-sizing: border-box;
  min-width: 42.856rem;
  max-width: 100%;
  @media (max-width: 825px) {
    padding-top: 2.625rem;
    min-width: 100%;
  }
  @media (max-width: 1250px) {
    flex: 1;
  }
`;

const InnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  @media (max-width: 825px) {
    gap: 2.313rem;
  }
  @media (max-width: 450px) {
    gap: 1.125rem;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.287rem;
  max-width: 100%;
  @media (max-width: 825px) {
    gap: 1.125rem;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.143rem;
  max-width: 100%;
`;

const MainTitle = styled.div`
  position: relative;

  /* line-height: 110%; */
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
  font-family: "DM Sans";
  font-size: 8rem;
  @media (max-width: 825px) {
    font-size: 4.563rem;
    /* line-height: 4.688rem; */
  }
  @media (max-width: 450px) {
    font-size: 4.125rem;
    /* line-height: 3.125rem; */
  }
`;

const Subtitle = styled.div`
  position: relative;
  font-size: 2rem;
  line-height: 170%;
  font-weight: 500;
  font-family: "Inter";
  @media (max-width: 450px) {
    font-size: 1.375rem;
    line-height: 2.313rem;
  }
`;

const GridContainer = styled.div`
  width: 33.65rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.25rem;
  max-width: 100%;
  font-size: 1.288rem;
  font-family: var(--text-small);
  @media (max-width: 825px) {
    flex-wrap: wrap;
  }
`;

const RightColumn = styled.div`
      flex: 1;
    border-radius: 11.85px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 4.018rem;
    padding: 7.675rem 1.437rem 7.687rem;
    box-sizing: border-box;
    background: url("/banner@3x.png") top no-repeat;
    background-size: cover;
    min-width: 24.313rem;
    max-width: 100%;
    font-size: 3.25rem;
    color: var(--text-inverse);
  @media (max-width: 825px) {
    padding: 5.625rem 0;
    min-width: 100%;
  }
  @media (max-width: 450px) {
    padding-left: 8px;
  }
`;

const RightColumnTitle = styled.h1`
  margin: 0;
  width: 32.956rem;
  position: relative;
  color: #fff;
  text-align: left;
  line-height: 120%;
  font-weight: 700;
  font-family: "DM Sans";
  font-size: 5rem;
  display: inline-block;
  max-width: 100%;
  @media (max-width: 825px) {
    width: 34.956rem;
    font-size: 3.575rem;
    line-height: 4.063rem;
  }
  @media (max-width: 450px) {
    font-size: 2.563rem;
    line-height: 3.063rem;
  }
`;

const FrameComponent = ({ className = "" }) => {
  return (
    <Section className={className}>
      <WrapperDiv>
        <LeftColumn>
          <InnerDiv>
            <TitleDiv>
              <Title>
                <MainTitle>Buy Now,</MainTitle>
                <MainTitle>Pay Later</MainTitle>

                <Subtitle>
                  Enjoy the convenience of easy payments
                  <br />
                  with low cost or in EMIs
                </Subtitle>
              </Title>
              <GridContainer>
                <Grid
                  badgeName="radioGroup-1"
                  paperless="100% Paperless"
                  badgeName1="radioGroup-1"
                  noCollateralFree="Collateral Free"
                />
                <Grid
                  badgeName="radioGroup-2"
                  paperless="Flexible Tenure"
                  badgeName1="radioGroup-2"
                  noCollateralFree="Manage Your Bills"
                />
              </GridContainer>
            </TitleDiv>
          </InnerDiv>
        </LeftColumn>
        <RightColumn>
          <RightColumnTitle>
            The power to choose your own loan, now in your hands!
          </RightColumnTitle>
        </RightColumn>
      </WrapperDiv>
    </Section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
