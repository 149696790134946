import React, { useState } from "react";
import "./LicenseTemplate.scss";
import logo from "./assets/logo-Colored2.png";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
const PrivacyPolicyOneAgrow = () => {
  const navigate = useNavigate();
  const [isChecked, setisChecked] = useState(false);

  return (
    <>

      <div className="licensetemplate">
        <img src={logo} style={{ width: "80%" }} />
        <h1>PRIVACY POLICY FOR ONEAGROW</h1>
        <div className="licenseContainer">
          <b>INTRODUCTION</b>

          <ol>
            <li>The Borrower is informed as follows:</li>
            <ol type="a">
              <li>
                This website www.Greattr.com, its mobile phone application(s).
              </li>
              <li>
                other related internet-based applications including Greattr app
                (collectively referred to as "Greattr Platform") owned.
              </li>
              <li>operated by OneAgrow Fintech Private Limited.</li>
              <li>
                a company incorporated under the laws of the Republic of India
                having its Corporate.
              </li>
              <li>
                Office at Suite No. 501, Rustomjee Central Park, Andheri Kurla
                Road, Andheri East, Mumbai - 400093, India.
              </li>
              <li>
                General terms and conditions for the use of Greattr Platform and
                other terms/disclaimers.
              </li>
              <li>
                Greattr Platform are incorporated hereby way of this reference.
              </li>
              <li>They shall be read as an integral part hereof.</li>
            </ol>
          </ol>
          <b>General</b>

          <ol>
            <li>
              Greattr Platform asks for and uses your personal information.
            </li>
            <li>committed to keeping this information secure and protected.</li>
            <li>
              This Privacy Policy explains why we require personal information
              and how we collect, use.
            </li>
            <li>disclose and secure your personal information.</li>
            <li>
              This Privacy Policy applies to the personal information we collect
              from you and third parties.
            </li>
            <li>
              both online and offline. However, it does not apply to any
              personal information collected by third parties from you.
            </li>
            <li>
              By accessing or using the Greattr Platform or any of its services.
            </li>
            <li>
              you signify that you have read, understood and confirm to be bound
              by this Privacy Policy and its modifications.
            </li>
            <li>
              We reserve the right to update or modify this Privacy Policy at
              any time without prior notice.
            </li>
            <li>
              Therefore, we encourage you to review this Policy each time you
              access our Services on Greattr Platform.
            </li>
            <li>
              We may also notify you of material changes to the Privacy Policy
              by e-mail or by a notice on our home page.
            </li>
            <li>
              The following terms and definitions have been set out in this
              privacy policy.
            </li>
            <li>
              "Greattr", "we", "us", and "our" means OneAgrow Fintech Private
              Limited.
            </li>
            <li>
              "you" and "your" means a user of the Greattr Platform and the
              products and services provided via the Greattr Platform.
            </li>
            <li>
              "Services" includes lending and any other related services
              facilitated by us via Greattr Platform.
            </li>
          </ol>
          <b>Collection of User Information</b>
          <ol>
            <li>
              To create an account on Greattr Platform, you must provide us with
              the basic details.
            </li>
            <li>
              information required as part of our Customer Identification
              process.
            </li>
            <li>
              you confirm our General Terms and Conditions for the use of
              Greattr Platform
            </li>
            <li>
              this Privacy Policy and other terms, which govern how we treat
              your information.
            </li>
            <li>
              During the use of our Services, Greattr Platform, we may collect
              information about you from you and third parties.
            </li>
            <li>
              While some data has to be mandatorily provided, others are
              optional.
            </li>
            <li>
              Certain portions of the data will remain private, and some will be
              shared with associated third parties.
            </li>
            <li>All information you provide to us is voluntary.</li>
            <li>
              This information is including but not limited to, the following.
            </li>
            <li>
              Information about you when you register or when you use our
              Greattr platform.
            </li>
            <li>
              Personal and financial information like your name, e-mail address,
              contact number, gender, date of birth, contact information.
            </li>
            <li>
              address, employment information, payslips, academic qualification,
              PAN no., Driving License no., Aadhaar no., bank statements.
            </li>
            <li>
              Credit-related information that is collected from other sources
              like credit bureaus.
            </li>
            <li>
              Information about your transaction history like spend data,
              through our app.
            </li>
            <li>
              We may also collect other personal information such as SMS,
              contacts, location data, mobile device data.
            </li>
            <li>
              Your unique identifiers, such as username and password preferences
              information and transaction history.
            </li>
            <li>
              Information collected through your Facebook, Twitter, LinkedIn or
              other social media accounts which have been linked to the account
              on the Greattr Platform.
            </li>
            <li>
              We acquire information from other sources to update or supplement
              the information you provided.
            </li>
            <li>
              We collected automatically. Local law may require that you
              authorize the third party to share your information with us before
              We can acquire it.
            </li>
            <li>
              Information that you provide when you write directly to us
              (including by e-mail) or provide us over the telephone.
            </li>
            <li>
              Any other information that Greattr is required to collect per a
              specific mandate from any bank or as a requirement under
              applicable law.
            </li>
            <li>Electronic bank statements through a 3rd party provider.</li>
            <li>
              Aggregate information and electronic data - like the pages viewed,
              how you navigate the website, and interact with web pages.
            </li>
            <li>
              Permissions given by you through android or iPhone operating
              system or other operating systems based on your informed consent
              inter alia for access to maps.
            </li>
            <li>
              device information like IMEI, android id/iPhone id/operating
              system based ID, network sockets.
            </li>
            <li>
              information about networks, information about Wi-Fi networks,
              camera, read-only access to phone state.
            </li>
            <li>
              including the current cellular network information, the status of
              any ongoing calls, user SMS data, user contacts
            </li>
            <li>
              data in external storage like the image for selfie storage, data
              from external storage, allow the application to use fingerprint
              biometric services.
            </li>
            <li>
              details of installed applications on your device/s and other
              permission as may be specifically sought.
            </li>
            <li>
              We may collect and monitor each installed applications metadata
              information that includes application and package name, installed
              and updated time.
            </li>
            <li>
              version name and version code for all applications installed on
              your device.
            </li>
            <li>
              These details about installed applications on your device is
              collected in order to decide your creditworthiness.
            </li>
            <li>provide customized offers and facilitate faster approval.</li>
            <li>
              Such details may be collected after the registration is complete
              and such data is not shared with any individual or third-party
              application other than for the services sought by you.
            </li>
            <li>
              We use various tools and technologies, such as cookies, flash
              cookies and web beacons.
            </li>
            <li>
              including when you visit Greattr Platform or use Greattr Platform
              on third-party sites or platforms.
            </li>
            <li>
              to collect your personal information and non-personal information
              from the device from which you access the Platform and learn about
              your activities taking place when you use Greattr Platform.
            </li>
            <li>
              Such non-personal information could include your IP address,
              device ID and type, your browser type and language.
            </li>
            <li>
              operating system used by your device, access times, your device
              geographic location and the referring website address.
            </li>
            <li>
              We may use web beacons and other similar technologies to track
              your use of Greattr Platform and to deliver or communicate with
              cookies.
            </li>
            <li>
              Advertising networks service providers, web traffic analysis
              service providers, our business partners and vendors and other
              third parties may also use cookies.
            </li>
            <li>
              which is beyond our control. These would most likely be
              analytical/performance cookies or targeting cookies.
            </li>
            <li>
              Blocking such cookies is possible by activating the appropriate
              settings on your device browser.
            </li>
            <li>However, this may affect the functionality of the Platform.</li>
            <li>
              We acquire information from other sources to update or supplement
              the information you provided, or We collected automatically.
            </li>
            <li>
              Local law may require that you authorize the third party to share
              your information with us before We can acquire it.
            </li>
            <li>
              The information we collect about you will depend on the products
              and services we offer on an ongoing basis.
            </li>
            <li>
              If you do not allow us to collect all the information we request,
              we may not be able to deliver all of these services effectively.
            </li>
            <li>
              You understand that, by creating an account or by registering
              through third party sites, we and others will be able to identify
              you by your Greattr profile.
            </li>
            <li>
              However, we will not be liable under any circumstance for the
              generation of your profile or the information you provide therein.
            </li>
            <li>
              Greattr will also not be responsible and liable for any
              information collected in the form of SMS, contact.
            </li>
            <li>
              media or any other sources that the users might upload, the data
              collection process may extract.
            </li>
            <li>
              the use and misuse thereof. We will ask for your bank account
              details only for the Service.
            </li>
            <li>
              All the information that you shall provide us is voluntary,
              including sensitive personal information.
            </li>
            <li>
              you understand that Greattr may use certain information of yours,
              which has been designated as 'sensitive personal data or
              information' under the applicable regulations to provide you the
              Services directly or through third parties.
            </li>
          </ol>
          <li>
            {" "}
            Use of your information
            <br />
          </li>

          <ol>
            <li>
              We may use the information you provide in the following ways.
            </li>
            <li>
              To establish and confirm identity for assessing applications.
            </li>
            <li>
              To capture the user information given in the application forms and
              any information uploaded on Greattr Platform by you, such as KYC
              documentation, bank slips and credit information.
            </li>
            <li>
              Share the information with partner Banks, NBFCs, multiple
              financial institutions, and other lenders to customize offers and
              provide services.
            </li>
            <li>
              To manage our customer relationships, get in touch with customers
              to send communications, notifications, information, or process
              queries and applications made by customers on Greattr Platform as
              well as to you about other products or services we think you might
              find of some use.
            </li>
            <li>
              Design our products, offer our products and services, manage our
              risks, including the risk of fraud.
            </li>
            <li>
              Analyze how the Greattr Platform is used, diagnose service or
              technical problems and maintain security.
            </li>
            <li>Monitor, improve and administer Greattr Platform.</li>
            <li>Comply with country laws and regulations.</li>
            <li>Use this information in other ways permitted by law.</li>
            <li>
              We use personal information to provide you with services &
              products you explicitly requested for, to resolve disputes,
              troubleshoot concerns, help promote safe services, collect money,
              measure consumer interest in our services, inform you about
              offers, products, services, updates, customize your experience,
              detect & protect us against error, fraud and other criminal
              activity, enforce our terms and conditions.
            </li>
            <li>
              We also use your contact information to send you offers based on
              your previous orders and interests.
            </li>
            <li>
              We may occasionally ask you to complete optional online surveys.
              These surveys may ask you for contact information and demographic
              information (like zip code, age, gender, etc.).
            </li>
            <li>
              We use data submitted by you to customize your experience at
              Greattr Platform, providing you with content that we think you
              might be interested in and display content according to your
              preferences.
            </li>
          </ol>

          <li>
            {" "}
            Third-party sharing of your information
            <br />
          </li>

          <ol>
            <li>
              We will take the utmost care to ensure that your personal
              information is not shared with the public and that your
              transactions on the Greattr Platform are confidential. If we use
              any third parties for any of these transactions, we will also
              ensure that confidentiality is maintained with these authorized
              third parties. We only share your personal information with
              unaffiliated third parties as permitted or required by law. When
              we share your personal information with vendors and service
              providers who perform functions on our behalf, they informed to
              keep your information confidential and secure and use it only as
              is reasonably necessary to carry out their work or comply with
              applicable law.
            </li>
            <li>
              We may share your personal information with affiliates and joint
              marketing campaigns with other financial companies. Also, personal
              information may be disclosed or transferred to our affiliates and
              as part of, or during negotiations of, a merger, consolidation,
              sale of company assets or equity, financing, acquisition,
              strategic alliance or in any other situation where personal
              information may be transferred as one of the business assets of
              Greattr.
            </li>
          </ol>

          <li>
            {" "}
            Protecting your information
            <br />
          </li>

          <ol>
            <li>
              We maintain administrative, technical and physical safeguards
              designed to protect the security, confidentiality and integrity of
              your personal information. We store all the customer data on AWS
              cloud servers to reduce the risks of loss, misuse, unauthorized
              access, disclosure and alteration. Some of the safeguards we use
              are firewalls, SSL data encryption and access authorization
              controls. Also, your personal information resides on servers or in
              areas of facilities that only selected Greattr personnel and
              contractors have access to.
            </li>
            <li>
              We maintain administrative, technical and physical safeguards
              designed to protect the security, confidentiality and integrity of
              your personal information. We store all the customer data on AWS
              cloud servers to reduce the risks of loss, misuse, unauthorized
              access, disclosure and alteration. Some of the safeguards we use
              are firewalls, SSL data encryption and access authorization
              controls. Also, your personal information resides on servers or in
              areas of facilities that only selected Greattr personnel and
              contractors have access to.
            </li>
          </ol>

          <li>
            {" "}
            Your choices regarding your personal information
            <br />
          </li>

          <ol>
            <li>
              Personal information not collected on the Greattr Platform or in
              connection with the Service is not generally subject to this
              Privacy Policy. The Greattr Platform may contain links to other
              websites that may collect personal information. For example,
              Greattr may have relationships with various service providers
              whose services you might find useful and available to you from the
              Greattr Platform, from websites linked to the Greattr Platform, or
              in connection with the Service. Those providers may require you to
              provide personal information to use their services. Unless
              expressly provided to the contrary, the privacy policy applicable
              to that website, and not this Privacy Policy, will govern the
              collection, use, disclosure and security of your personal
              information.
            </li>
            <li>
              You can access all of your personal and personally identifiable
              information that we collect online and maintain by logging in to
              your account and going to "My Profile". This section of the site
              is password-protected to safeguard your information better. To
              protect the confidentiality of such personal data, you should not
              divulge your password to anyone. You are responsible for all uses
              of the Greattr Platform by any person using your customer ID and
              password. As a registered user, you can update, physical address,
              phone numbers, education and employment information at any time on
              the website. If you need to change any other information in your
              profile, contact us through the chat feature on the Greattr
              Platform.
            </li>
            <li>
              You can contact us or mute the notification yourself in our
              application for withdrawing your consent to provide the personal
              information.
            </li>
          </ol>

          <li>
            {" "}
            Tracking, use of cookies, web beacons and similar tracking devices
            <br />
          </li>

          <ol>
            <li>
              Cookies are small data files that a website stores on your
              computer. Greattr Platform uses cookies for security and to
              personalize your web browsing experience.
            </li>
            <li>We may use cookies for some situations, such as</li>
            <li>
              allocate an identification number to your Internet browser (you
              cannot be identified from this number).
            </li>
            <li>To make Greattr Platform more user friendly.</li>
            <li>
              To determine if you have previously visited the Greattr Platform.
            </li>
            <li>
              To track and report on Greattr Platform and online campaign
              performance.
            </li>
            <li>
              To identify other pages or third parties' websites you have
              accessed, and.
            </li>
            <li>For fraud detection and security purposes.</li>
            <li>
              You can configure your browser so that it does not receive
              cookies. However, this means you will be unable to use secure
              services that require cookies to participate.
            </li>
          </ol>

          <li>
            {" "}
            Communication
            <br />
          </li>

          <ol>
            <li>
              When you contact Greattr or Greattr contacts you directly or by
              service providers of Greattr through phone, e-mail or electronic
              means, at the discretion of Greattr such communications may be
              stored or recorded by the Greattr or its service providers for the
              purpose of improvements in customer engagement and to offer more
              services.
            </li>
            <li>
              Use of Greattr Platform in any manner by a user shall be deemed as
              an acceptance to the above by the concerned user.
            </li>
          </ol>

          <li>
            {" "}
            Contacting Greattr
            <br />
          </li>

          <ol>
            <li>
              In case you need any details or clarifications or redressal of
              grievances or have any feedback regarding our Privacy Policy or
              believe that Greattr has not complied with this Privacy Policy
              concerning your personal information, please contact us at
              www.Greattr.com/contact-us. If you are reporting noncompliance
              with this Privacy Policy, in your e-mail or letter, please
              describe in as much detail as possible (but without disclosing any
              sensitive information about you or third parties) how you believe
              that the Privacy Policy has not been complied with.
            </li>


          </ol>
        </div>
        <h1> Contact Us </h1> Name: ONEAGROW FINTECH PRIVATE LIMITED Address:
        Suite No. 501, Rustomjee Central Park, Andheri Kurla Road, Andheri East,
        Mumbai - 400093 Email Id: grievance.officer@Jainexfin.com Contact No:
        +91-84220 54444 Contact Person: Mr. Shubham Srivastava Nodal/ Grievance
        Officer: Mr. Shubham Srivastava
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default PrivacyPolicyOneAgrow;
