import PropTypes from "prop-types";
import "./LeftContentLogin.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Flexible from "../assets/Flexible.svg";
import paperless from "../assets/paperless.svg";
import Bill from "../assets/Bill.svg";
import Collateral from "../assets/Collateral.svg";
import Instant from "../assets/Instant.svg";
import BulkLPG from "../assets/BulkLPG.svg";
import BNPL from "../assets/BuyNowPayLater.svg";

import { Pagination, Autoplay } from "swiper/modules";

const LeftContentLogin = ({ className = "" }) => {
  const slides = [
    { src: Flexible, alt: "greattrlogo" },
    { src: BNPL, alt: "BuyNowPayLater" },
    { src: paperless, alt: "paperless and easy" },
    { src: Collateral, alt: "No Collateral" },
    { src: Bill, alt: "Pay Bills" },
    { src: BulkLPG, alt: "Bulk LPG" },
    { src: Instant, alt: "Instant Processing" },
  ];
  return (
    <div className={`login-content ${className}`}>
      <div className="content-wrapper">
        <img
          className="logo-container"
          loading="lazy"
          alt=""
          src="/logo-1.svg"
        />
      </div>
      <Swiper
        style={{ justifyContent: "center", alignItems: "center" }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        pagination={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className="Sliderlogo-image"
              loading="lazy"
              alt={slide.alt}
              src={slide.src}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

LeftContentLogin.propTypes = {
  className: PropTypes.string,
};

export default LeftContentLogin;
