import React from "react";
import styled from "styled-components";
import Card from "./Card";
import PropTypes from "prop-types";

const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2.287rem;
  max-width: 100%;
  text-align: left;
  font-size: 2.319rem;
  color: var(--text-default);
  font-family: var(--heading-h6);

  @media (max-width: 825px) {
    gap: 1.125rem;
  }

  @media (max-width: 1575px) {
    flex-wrap: wrap;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem;
  box-sizing: border-box;
  gap: 2.318rem;
  min-width: 43.938rem;
  max-width: 100%;
  flex-shrink: 0;

  @media (max-width: 825px) {
    gap: 1.188rem;
  }

  @media (max-width: 1250px) {
    min-width: 100%;
  }
`;

const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem;
  box-sizing: border-box;
  gap: 2.4rem;
  max-width: 100%;
  flex-shrink: 0;

  @media (max-width: 825px) {
    gap: 1.188rem;
  }

  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }
`;

const Item = styled.div`
  flex: 1;
  border-radius: 24.03px;
  background-color: #f8f8f8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.206rem 2.437rem;
  box-sizing: border-box;
  min-width: 24.438rem;
  max-width: 100%;

  @media (max-width: 825px) {
    min-width: 100%;
  }
`;

const ItemContent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.6rem;
`;

const ItemTitle = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 2.813rem;
  font-weight: 500;
  color: #000000;

  @media (max-width: 825px) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  @media (max-width: 450px) {
    font-size: 1.375rem;
    line-height: 1.688rem;
  }
`;

const ItemDescription = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 1.35rem;
  line-height: 180%;
  font-weight: 500;
  font-family: var(--text-small);
  color: #000000;

  @media (max-width: 450px) {
    font-size: 1.063rem;
    line-height: 1.938rem;
  }
`;

const FeaturesContainer = ({ className = "" }) => {
  return (
    <Container className={className}>
      <LeftColumn>
        <Row>
          <Item>
            <ItemContent>
              <ItemTitle>Increased Sales</ItemTitle>
              <ItemDescription>
                Offer BNPL options to attract more customers and boost sales.
              </ItemDescription>
            </ItemContent>
          </Item>
          <Item>
            <ItemContent>
              <ItemTitle>Customer Support</ItemTitle>
              <ItemDescription>
                Guide customers seamlessly through loans and repayments.
              </ItemDescription>
            </ItemContent>
          </Item>
        </Row>
        <Row>
          <Item>
            <ItemContent>
              <ItemTitle>Enhanced Loyalty</ItemTitle>
              <ItemDescription>
                Build customer trust and loyalty with flexible financial
                solutions.
              </ItemDescription>
            </ItemContent>
          </Item>
          <Item>
            <ItemContent>
              <ItemTitle>Business Growth</ItemTitle>
              <ItemDescription>
                Broaden your customer base with flexible payment options.
              </ItemDescription>
            </ItemContent>
          </Item>
        </Row>
      </LeftColumn>
      <Card
        forConsumers="For Merchants"
        enjoyFlexiblePaymentOptio="Increase sales and customer satisfaction"
        suitYourBudget="with BNPL options."
        app="/app-1@2x.png"
        propPadding="4.568rem 1.25rem 0.031rem 1.375rem"
      />
    </Container>
  );
};

FeaturesContainer.propTypes = {
  className: PropTypes.string,
};

export default FeaturesContainer;
