// encryptionUtils.js

import CryptoJS from 'crypto-js';

const ENKey = 'KAGEYAMATAIGI'; 

export const encryptData = (data) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), ENKey).toString();
    return encryptedData;
};

export const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData,ENKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};
export const hashAadhaar  = (aadhaar) => {
    var salt ='66742c22d3d93b50d200709665e94e98'
    console.log("namak",salt);
    console.log('Aadhaar:', aadhaar);
    var hashedAadhaar=CryptoJS.SHA256(aadhaar + salt).toString(CryptoJS.enc.Hex)
  console.log('Hashed Aadhaar:', hashedAadhaar);
    return  hashedAadhaar;
  }
  