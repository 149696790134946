import React from "react";
import styled from "styled-components";
import Column1 from "./Column1";
import PropTypes from "prop-types";

const Section = styled.section`
  align-self: stretch;
  display: flex;
  background-color: #fff;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  padding-top: 0;
  padding-left: 4.187rem;
  padding-right: 4.187rem;
  padding-bottom: 11.431rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 4.569rem;
  color: #ffffff;
  font-family: var(--heading-h6);
margin-top:3rem;
  @media (max-width: 1250px) {
    padding-left: 2.063rem;
    padding-right: 2.063rem;
    padding-bottom: 7.438rem;
  }

  @media (max-width: 476px) {
    padding-bottom: 1.813rem;
  }
`;

const Container = styled.div`
  flex: 1;
  border-radius: 22.86px;
  background-color: #3f0261;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 6.856rem;
  padding-bottom: 0;
  padding-right: 15.125rem;
  padding-left: 5.375rem;
  box-sizing: border-box;
  max-width: 100%;
  gap: 1.25rem;

  @media (max-width: 825px) {
    padding-left: 1.313rem;
    padding-top: 4.438rem;
    padding-right: 3.75rem;
  }

  @media (max-width: 1250px) {
    padding-left: 2.688rem;
    padding-right: 3.563rem;
  }

  @media (max-width: 450px) {
    padding-right: 2.25rem;
  }

  @media (max-width: 1575px) {
    flex-wrap: wrap;
  }
`;

const Content = styled.div`
  width: 37.431rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 3.431rem;
  min-width: 37.431rem;
  max-width: 100%;

  @media (max-width: 825px) {
    gap: 1.688rem;
  }

  @media (max-width: 1250px) {
    min-width: 100%;
  }

  @media (max-width: 1575px) {
    flex: 1;
  }
`;

const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 2.287rem;
  max-width: 100%;

  @media (max-width: 825px) {
    gap: 1.125rem;
  }
`;

const Headline = styled.div`
  width: 42.856rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  max-width: 115%;
  font-size: 4.569rem;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.03em;

  @media (max-width: 825px) {
    font-size: 3.625rem;
    line-height: 4rem;
  }

  @media (max-width: 450px) {
    font-size: 2.75rem;
    line-height: 3rem;
  }
`;

const Subtitle = styled.div`
  align-self: stretch;
  font-size: 1.288rem;
  font-weight: 500;
  line-height: 180%;
  color: #fff;

  @media (max-width: 450px) {
    font-size: 1rem;
    line-height: 1.875rem;
  }
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  gap: 4.568rem;
  max-width: 100%;
  font-size: 1.144rem;

  @media (max-width: 825px) {
    flex-wrap: wrap;
    gap: 2.313rem;
  }
`;

const StoreImage = styled.img`
  height: 3.569rem;
  width: 12.056rem;
  overflow: hidden;

   @media (max-width: 476px) {
    flex: 1;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

const CTAImage = styled.img`
  width: 30rem;
  max-height: 60%;
  overflow: hidden;
  max-width: 100%;

  @media (max-width: 1575px) {
    // flex: 1;
  }

  @media (max-width: 476px) {
    display: none;
  }
`;

const FeatureGrid = ({ className = "" }) => {
  return (
    <Section className={className}>
      <Container>
        <Content>
          <Title>
            <Headline>
              Small Loans,<br />
              Big Changes
            </Headline>
            <Subtitle>
              Enjoy the convenience of easy payments<br />
              with low cost or in EMIs
            </Subtitle>
          </Title>
          <FeatureList>
            <Column1
              badgeName="radioGroup-3"
              paperless="100% Paperless"
              noCollateralFree="No Collateral Free"
            />
            <Column1
              badgeName="radioGroup-4"
              paperless="Flexible Tenure"
              noCollateralFree="Manage Your Bills"
            />
          </FeatureList>
          <StoreImage loading="lazy" alt="" src="/google-store.svg" />
        </Content>
        <CTAImage loading="lazy" alt="" src="/ctaimage.svg" />
      </Container>
    </Section>
  );
};

FeatureGrid.propTypes = {
  className: PropTypes.string,
};

export default FeatureGrid;
