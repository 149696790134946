import React from "react";
import "./LicenseTemplate.scss";
import logo from "../assets/logo-Colored2.png";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
const GeneralTermsandConditions = () => {
  return (
    <>
      <div className="licensetemplate">
        <img src={logo} style={{ width: "80%" }} />
        <br />
        <h1>
          <br />
          General Terms and Conditions for the use of Greattr Platform
          <br />
        </h1>
        <div className="licenseContainer">
          <li>Introduction:</li>
          <br />
          <ol type="a">
            <li>
              This Greattr Platform www.Greattr.com its mobile phone and
              internet-based application(s) (“Greattr Platform”) owned and
              operated by OneAgrow Fintech Private Limited, a company
              incorporated under the Laws of the Republic of India having its
              Corporate Office at Suite No. 501, Rustomjee Central Park, Andheri
              Kurla Road, Andheri East, Mumbai - 400093 India (collectively
              referred to as “Greattr”, “we”, “us”, “our”, as the context may
              apply).
            </li>
            <li>
              Each user of Greattr Platform (hereinafter referred to as “User”,
              “Greattr User”, “you”, “your”, as the context may apply) is
              requested to read these terms before them using Greattr Platform.
            </li>
            <li>
              Each Greattr User is also requested to note and be updated about
              the services its terms, schedule of charges and the list of
              Lender(s) in Greattr Platform and the respective terms conditions
              applicable for the same.
            </li>
          </ol>
          <li>
            Each Greattr User by using any of the Greattr Platform understands
            and confirms that they:
          </li>
          <ol type="a">
            <li>
              may need to recognize and comply with additional terms and
              conditions for availing services offered in Greattr Platform as
              may be applicable from time to time.
            </li>
            <li>
              {" "}
              Greattr reserves the right to modify these terms and other
              policies applicable in general and/or to specific areas of
              services in Greattr Platform or to a particular service, which
              shall also be considered part of these terms, at any time without
              giving you any prior notice.
            </li>
            <li>
              They shall be required to re-visit these terms from time to time
              to stay abreast of any changes that Greattr Platform may introduce
              to these terms.
            </li>
            <li>
              Shall not accept these terms if you are a person who is either
              barred or otherwise legally prohibited or not authorized to use
              the same.
            </li>
            <li>
              Are advised to obtain independent professional advice before
              purchasing or availing any product and/or service via Greattr
              Platform.
            </li>
            <li>
              By using the Greattr Platform, you consent to the use of your
              information, as we outlined in our privacy policy. Our services
              use cookies; by using our services or confirming to these terms,
              you consent to our use of cookies in accordance with the terms of
              our privacy policy.
            </li>
            <li>
              Not to use the Greattr Platform for any purpose that is unlawful,
              illegal, or forbidden by these terms, or any local laws that might
              apply to you.
            </li>
            <li>
              Greattr Platform may provide you access to its online platforms
              for bringing together consumers, financial institutions, data
              partners, utilities, market places and other partners. However,
              Greattr informs you that we are not a lender or a financial
              institution and hence, have no part in lending or credit decisions
              or provide any financial services. Greattr may act as a bridge
              that connects you to lenders who may consider granting you Credit
              facilities in India.
            </li>
            <li>
              Notwithstanding anything to the contrary, Greattr does not
              undertake any product liability/obligation or services obligation
              or represents or warrants or guarantee on behalf of any third
              person/s.
            </li>
            <li>The role of Greattr is strictly limited to intermediation.</li>
            <li>
              Greattr disclaims any responsibility or liability for any services
              or offerings on Greattr Platform.
            </li>
          </ol>
          <li>
            We may translate these terms into other languages for your
            convenience. Nevertheless, the English version governs your
            relationship with us, and any inconsistencies among the different
            versions will be resolved in favour of the English version.
          </li>
          <li>
            If you disagree with any part of these terms and conditions, please
            do not use Greattr Platform.
          </li>

          <li>USING THE GREATTR PLATFORM AND SERVICES:</li>
          <br />
          <ol type="a">
            <li>
              Acceptance: By using the Greattr Platform, you confirm to
              unconditionally accept and affirm to comply with and be bound by
              these terms. Subject to the aforesaid, we permit you to use the
              Greattr Platform subject to the restrictions in these terms. Your
              use of the Greattr Platform and services is at your own risk.
            </li>
            <li>
              User Account: You may create an account and provide certain
              information about yourself to use some of the features offered
              through the Greattr Platform. You confirm that you are solely
              responsible for maintaining such User Accounts as respective
              end-user terms applicable to the same.
            </li>
            <li>
              Submission of Information: While creating your account, you or
              while using the Greattr Platform you will be asked to provide
              certain information, which is mandatory, also there will be other
              details, which you can afford at your discretion. In both cases,
              we ask you to provide complete and accurate information about
              yourself to bolster your credibility. You confirm and warrant that
              the details submitted by you are true and correct, and Greattr can
              rely on it to provide the Services sought by you through the
              Greattr Platform.
            </li>
            <li>
              Communications: By creating an account or by using Greattr
              Platform, you confirm to receive certain notifications in
              connection with the Greattr Platform and its Services. By using
              the Greattr Platform and/or registering yourself our Greattr
              Platform you authorize us, our affiliates, our associates,
              partners to contact you via e-mail or phone call or SMS and offer
              you their services for the product you have opted for, imparting
              product knowledge, offer promotional offers running on Greattr
              Platform & offers provided by the associated third parties, for
              which reasons, as well as web aggregation, personally identifiable
              information, may be collected as detailed below. And irrespective
              of the fact if also you have registered yourself under DND or DNC
              or NCPR service, you still authorize us to give you a call from
              us, our affiliates, our associates, partners for the
              above-mentioned purposes. We may also pass your information to
              third party financial services companies for the use of them,
              telling you about their services or products.
            </li>
            <li>
              Service Fee: You confirm that you shall pay the fee for the
              services as per terms of the specific services provided in this
              Greattr Platform. The fee once paid shall be non-refundable,
              unless confirmed by us in writing. The payment of fee or
              application made in our Greattr Platform does not assure you that
              a grant of loan or credit line or other services mentioned in our
              Greattr Platform will be subject to the satisfaction of other
              formalities required for the specific services.
            </li>
            <li>
              Availability of Greattr Platform or any Services: Our Greattr
              Platform or Services may be modified, updated, interrupted,
              suspended, or discontinued at any time without notice or
              liability.
            </li>
            <li>
              7. By using the Greattr Platform or any its services, you confirm
              and declare that (a) you have no objections in Greattr acting as
              an agent of the Lenders (s)Lender’s Agents or on behalf of the
              Lender or in such manner as may be decided by Greattr from time to
              time (b) you will be bound by any contractual provisions
              undertaken to the Lender(s) Greattr Platform.
            </li>
          </ol>

          <li>PROPRIETARY RIGHTS:</li>
          <br />
          <ol type="a">
            <li>
              You do not have the right to use Greattr's trade names,
              trademarks, service marks, logos, domain names, and other
              distinctive brand features. You do not have the right to copy and
              use the software, text, images, graphics, video, and audio used on
              this Greattr Platform ("Content"). You do not have the right to
              remove, obscure, or alter any proprietary rights notices
              (including trademark and copyright notices), which may be affixed
              to or contained within the Services. You will not copy or transmit
              any of the Content.
            </li>
            <li>
              Greattr neither represents nor warrants that your use of materials
              displayed at the Greattr Platform will infringe third parties'
              rights.
            </li>
          </ol>

          <li>PROHIBITED CONDUCT:</li>
          <br />
          <ol type="a">
            <li>
              By using our Greattr Platform, you confirm that you shall not:
            </li>
          </ol>
          <ol type="a">
            <li>
              use our Services and Greattr Platform for spamming or any other
              illegal purposes;
            </li>
            <li>
              infringe Greattr’s or any third party's intellectual property
              rights, rights of publicity or privacy;
            </li>
            <li>
              post or transmit any message which is libellous, defamatory or
              which discloses private or personal matters concerning any person;
            </li>
            <li>
              post or transmit any message, data, image, or program which
              violates any law;
            </li>
            <li>
              refuse to cooperate in an investigation or provide confirmation of
              your identity or any other information you provide to Greattr;
            </li>
            <li>
              remove, circumvent, disable, damage, or otherwise interfere with
              security-related features of our Greattr Platform or features that
              enforce limitations on the use of our Services and Greattr
              Platform;
            </li>
            <li>
              upload any content that constitutes negligent advice or contains
              any negligent statement, an incitement to commit a crime or
              contains instructions for the commission of a crime or the
              promotion of criminal activity; or any content which is in
              contempt of any court, or in breach of any court order; or
              discriminates on the basis of age, sex, religion, race, gender;
              harassing,invasive of another's privacy, blasphemous; in breach of
              any contractual obligations or depicts violence or is
              pornographic, paedophilic, obscene, suggestive or sexually
              explicit; or consists of or contains any instructions, advice or
              other information which may be acted upon and could, if acted
              upon, cause illness, injury or death, or any other loss or damage;
              or constitutes spam; or is grossly harmful, offensive, deceptive,
              fraudulent, threatening, abusive, hateful, harassing, anti-social,
              menacing, hateful, discriminatory or inflammatory; or causes
              annoyance, inconvenience or needless anxiety to any person; or
              racially, ethnically objectionable, disparaging, relating or
              encouraging money laundering or gambling, or harm minors in any
              way or otherwise unlawful in any manner whatever
            </li>
            <li>
              upload any content that threatens the unity, integrity, defence,
              security or sovereignty of any country, or public order or causes
              incitement to the commission of any cognizable offense or prevents
              investigation of any offense or is insulting any nation;
            </li>
            <li>
              upload any content that contains software viruses or any other
              computer code, files or programs designed to interrupt, destroy,
              or limit the functionality of any computer resource;
            </li>
            <li>
              reverse engineer, decompile, disassemble, or otherwise attempt to
              discover the source code of our Services and Greattr Platform or
              any part thereof or infringe any patent, trademark, copyright, or
              other proprietary rights;
            </li>
            <li>
              use our Services and Greattr Platform in any manner that could
              damage, disable, overburden, or impair, including, without
              limitation, using our Services and Greattr Platform in an
              automated manner;
            </li>
            <li>
              modify, adapt, translate, or create derivative works based upon
              our Services and Greattr Platform or any part thereof;
            </li>
            <li>
              intentionally interfere with or damage operation of our Services
              and Greattr Platform or any other User’s use of our Services and
              Greattr Platform, by any means, including uploading or otherwise
              disseminating viruses, adware, spyware, worms, or other malicious
              code or file with contaminating or destructive features;
            </li>
            <li>
              use any robot, spider, other automatic devices, or manual process
              to monitor or copy our Services and Greattr Platform without prior
              written permission of Greattr;
            </li>
            <li>
              interfere or disrupt our Services and Greattr Platform or networks
              connected therewith;
            </li>
            <li>
              take any action that imposes an unreasonably or disproportionately
              large load on our infrastructure/network;
            </li>
            <li>
              use any device, software, or routine to bypass our Services and
              Greattr Platform robot exclusion headers, or interfere or attempt
              to interfere, with our Services and Greattr Platform;
            </li>
            <li>
              forge headers or manipulate identifiers or other data to disguise
              the origin of any content transmitted through our Services and
              Greattr Platform or to manipulate your presence on our Services
              and Greattr Platform;
            </li>
            <li>
              use the facilities and capabilities of our Services and Greattr
              Platform to conduct any activity or solicit the performance of any
              illegal activity or other activity which infringes the rights of
              others;
            </li>
            <li>breach these Terms or any other policies of Greattr;</li>
            <li>
              provide false, inaccurate, or misleading information to our
              Services and Greattr Platform;
            </li>
            <li>
              use our Services and Greattr Platform to collect or obtain
              personal information, including without limitation, personal
              information about other Users of our Services and Greattr
              Platform.
            </li>
            <li>
              Although Greattr may from time-to-time monitor or review postings,
              transmissions, and the like on the Greattr Platform, Greattr is
              under no obligation to do so and assumes no responsibility or
              liability arising from the content of any such locations nor for
              any error, defamation, libel, slander, omission, falsehood,
              obscenity, pornography, profanity, danger, or inaccuracy contained
              in any information within such locations on the Greattr Platform.
            </li>
            <li>
              You are prohibited from posting or transmitting any unlawful,
              threatening, libellous, defamatory, obscene, scandalous,
              inflammatory, pornographic, or profane material or any material
              that could constitute or encourage conduct that would be
              considered a criminal offense, give rise to civil liability, or
              otherwise violate any law.
            </li>
            <li>
              Greattr retains the right to remove any such posting and fully
              cooperate with any law enforcement authorities or court order to
              request or direct Greattr to disclose the identity of anyone
              posting any such information or materials.
            </li>
          </ol>
          <li>DISPLAY/ ADVERTISEMENTS/ WEB LINKS:</li>
          <br />
          <ol type="a">
            <li>
              Greattr has not reviewed any sites that may be linked to the
              Greattr Platform and is not responsible for the content of any
              off-site pages or any other sites linked to the Greattr Platform.
              Your linking to any other off-site pages or other websites is at
              your own risk.
            </li>
            <li>
              Greattr's display on or through the Greattr Platform of various
              services or product options offered by third parties does not
              imply, suggest, or constitute any sponsorship, recommendation or
              approval or advice of Greattr of any such third parties or their
              products.
            </li>
            <li>
              You confirm that Greattr is in no way responsible for the
              accuracy, timeliness, or completeness of information it may obtain
              from these third parties. Your interaction with any third party
              accessed through the Greattr Platform is at your own risk, and
              Greattr will have no liability with respect to the acts,
              omissions, errors, representations, warranties, breaches, or
              negligence of any such third parties or for any personal injuries,
              death, property damage, or other damages or expenses resulting
              from your interactions with the third parties.
            </li>
            <li>
              You confirm that you may need to confirm with the terms and
              conditions of such third parties by accessing their Greattr
              Platform, and the same will be at your sole risk and
              responsibility. Further, the obligations that may arise.
            </li>
          </ol>

          <li>INACCURACIES AT THE GREATTR PLATFORM:</li>
          <br />
          <ol type="a">
            <li>
              While Greattr uses reasonable efforts to include accurate and up
              to date information at the Greattr Platform, Greattr makes no
              warranties or representations as to the Greattr Platform's
              accuracy. Greattr disclaims all liability for the accuracy,
              completeness, or correctness of such information.
            </li>
          </ol>

          <li>SECURITY AT THE GREATTR PLATFORM:</li>
          <br />
          <ol type="a">
            <li>
              While Greattr uses reasonable efforts to safeguard the security of
              the Site, there can be no guaranty that such safeguards will
              successfully prevent unauthorized alterations in the content or
              functionality of the Site. Greattr assumes no liability or
              responsibility for any unauthorized changes in the content or
              functionality of the Site.
            </li>
          </ol>

          <li>USE INFORMATION ON THIS GREATTR PLATFORM:</li>
          <br />
          <ol type="a">
            <li>
              Except as expressly permitted by these Terms, no portion of the
              information on Greattr Platform may be reproduced in any form, or
              by any means, without our prior written permission.
            </li>
          </ol>

          <li>GOVERNING LAW, JURISDICTION, AND DISPUTE RESOLUTION:</li>
          <br />
          <ol type="a">
            <li>
              The laws of India, without regard to its conflict of laws rules,
              will govern these Terms, as well as your and our observance of
              them.
            </li>
            <li>
              If you take any legal action relating to your use of the Greattr
              Platform or these Terms, the same shall be subject to the
              exclusive jurisdiction of courts located in Mumbai, India.
            </li>
            <li>
              In case you have any disputes with Greattr, you may raise your
              issues to Greattr and request for mediation.
            </li>
            <li>
              If the matter is not resolved through such mediation, the same may
              then be settled by arbitration by a single Arbitrator appointed by
              Greattr. The arbitration shall be held in accordance with the
              provisions of the Arbitration and Conciliation Act, 1996. The
              language of the arbitration shall be in English, and the place of
              Arbitration shall be Mumbai India.
            </li>
          </ol>

          <li>CONTACT US/GRIEVANCE REDRESSAL/ FEEDBACK:</li>
          <br />
          <ol type="a">
            <li>
              In case you need any details or clarifications or redressal of
              grievances or have any feedback, you can contact us on
              www.Greattr.com/contact-us
            </li>
            <li>
              Also, we believe that your feedback makes use of Greattr Platform
              and services better; please feel free to share it with us on
              www.Greattr.com/contact-us Unless admitted explicitly by us or as
              required by law, all feedback shall be non-confidential.
            </li>
            <li>
              We will assume no responsibility for reviewing unsolicited ideas
              and will not incur any liability due to any similarities between
              those ideas and materials that may appear in future programs of
              Greattr.
            </li>
            <li>
              Please do not reveal trade secrets or other confidential
              information in your messages to Greattr. All rights to materials
              submitted to us become the exclusive property of Greattr.
            </li>
            <li>
              You understand and confirm that Greattr may record any calls or
              communication made to it by you or vice versa.
            </li>
          </ol>

          <li>SERVICES, USE OF INFORMATION AND CONSENT:</li>
          <br />
          <ol type="a">
            <li>
              Greattr will hereinafter be engaged in the provision of the
              following services:
            </li>
          </ol>
          <ol type="a">
            <li>
              Provision of credit score, report, and analysis: Your credit
              score, along with the report and analysis, may be available on
              Greattr Platform. Further, we may monitor and update these credit
              scores, reports, and analyses periodically.
            </li>
            <li>
              Social Scoring: Provision of a social score based on User’s
              Facebook, Twitter, LinkedIn, or any other social media accounts
              linked with the user's account on Greattr Platform.
            </li>
            <li>
              The Greattr provides a Platform through Greattr Platform wherein
              the buyers can choose various Products of the sellers and purchase
              the same. The Greattr also offers other services as may be
              required from time to time, including the data analytics services.
            </li>
            <li>
              Greattr Platform displays a catalogue of financial products
              belonging to different Users, which are being facilitated through
              Greattr Platform. You may browse through the products and provide
              us with your details. The representatives of the Greattr will
              contact You through phone on the phone number provided by You at
              the time as a request by Greattr Platform. Further, the Greattr
              reserves the right to provide you with further information about
              the products at such time as You may be willing to be contacted.
            </li>
            <li>
              By registering with Greattr or beginning an application on the
              Greattr Platform for any product or service, you are consenting to
              be contacted by us through written notices, e-mail messages, text
              messages, WhatsApp, Telegram or telephone, and, in connection with
              any such telephone calls, you consent to the use of
              pre-recorded/artificial voice messages and/or automatic dialling
              devices, at any telephone number associated with your Greattr
              account, including mobile telephone numbers we have for you in our
              records or from other public and non-public databases we may
              lawfully access. You hereby allow Greattr to contact other
              individuals via your phone contact list and/or phone address book
              to provide updated employment, location, and contact information
              for you if Greattr is unable to reach you on your registered
              mobile number. You understand that anyone with access to your
              telephone or e-mail account may listen to or read the messages
              Greattr leaves or sends you, and you confirm that Greattr will
              have no liability for anyone accessing such messages. You
              expressly authorize Greattr to monitor and record your calls with
              Greattr. You confirm that Greattr may contact you using the
              information you provided with information and offers of services
              available through Greattr Platform and mobile application
            </li>
            <li>
              The Greattr Platform may contain services such as e-mail, chat,
              text messages, automated IVR calls, calendars, and/or other
              messages. The user confirms and undertakes to use these
              communication services to post, send and receive messages and
              material that are proper and related to the communication.
            </li>
            <li>
              The user declares and confirms that any benefits based on the
              Greattr referral program(s) will be available only if the user or
              the individual(s) who have been referred by the user to Greattr
              have paid their outstanding dues on or before the due date / EMI
              date and there are no defaults of any nature by the user or such a
              person who is referred. The user declares and confirms that this
              clause shall prevail and override over other provisions of these
              terms or any other terms and conditions.
            </li>
          </ol>

          <li>DISCLAIMER OF WARRANTIES:</li>
          <br />
          <ol type="a">
            <li>
              You expressly understand and confirm that: Your use of the service
              and all information, products, and other content (including that
              of third parties) included in or accessible from the service is at
              your sole risk. The service is provided on an "as is" and "as
              available" basis. Greattr and service provider expressly disclaim
              all warranties of any kind as to the service and all information,
              products, and other content (including that of third parties)
              contained in or accessible from the service, whether express or
              implied, including, but not limited to the implied warranties of
              merchantability, fitness for a particular purpose and
              non-infringement.
            </li>
            <li>Greattr and service provider make no warranty that:</li>
          </ol>
          <ol type="a">
            <li>The service will meet your requirements.</li>
            <li>
              The service will be uninterrupted, timely, secure, or error-free.
            </li>
            <li>
              The results that may be obtained from the use of the service will
              be accurate or reliable.
            </li>
            <li>
              The quality of any products, services, information, or other
              material purchased or obtained by you will meet your expectations
              through the service.
            </li>
            <li>Any errors in the technology will be corrected.</li>
            <li>
              And you are solely responsible for any damage to your computer
              system or loss of data that results from the download of any such
              material. Whether oral or written, no advice or information
              obtained by you from Greattr or service provider through or from
              the service will create any warranty not expressly stated in these
              terms.
            </li>
          </ol>

          <li>LIMITATION OF LIABILITY:</li>
          <br />
          <ol type="a">
            <li>
              You confirm that neither Greattr nor service provider nor any of
              their affiliates, account providers or any of their affiliates
              will be liable for any harms, which lawyers and courts often call
              direct, indirect, incidental, special, consequential, or exemplary
              damages, including, but not limited to, damages for loss of
              profits, goodwill, use, data or other intangible losses, even if
              Greattr or service provider has been advised of the possibility of
              such damages, resulting from:
            </li>
          </ol>
          <ol type="a">
            <li>The use or the inability to use the service.</li>
            <li>The cost of getting substitute goods and services.</li>
            <li>
              Any products, data, information, or services purchased or obtained
              or messages received or transactions entered, through or from the
              service.
            </li>
            <li>
              Unauthorized access to or alteration of your transmissions or
              data.
            </li>
            <li>Statements or conduct of anyone on the service.</li>
            <li>
              The use, inability to use, unauthorized use, performance, or
              non-performance of any third-party account provider site, even if
              the provider has been advised previously of the possibility of
              such damages.
            </li>
            <li>Any other matter relating to the service.</li>
          </ol>

          <li>INDEMNIFICATION:</li>
          <br />
          <ol type="a">
            <li>
              You confirm to protect and fully compensate Greattr and its
              service providers and affiliates from all third-party claims,
              liability, damages, expenses and costs (including, but not limited
              to, reasonable attorney fees) caused by or arising from your use
              of the service, your violation of these terms or your
              infringement, or infringement by any other user of your account,
              of any intellectual property or other rights of anyone.
            </li>
            <li>
              You confirm that Service Provider is a third-party beneficiary of
              the above provisions, with all rights to enforce such rules as if
              Service Provider were a party to these terms.
            </li>
          </ol>

          <li>DOCUMENTS POLICY:</li>
          <br />
          <ol type="a">
            <li>
              Greattr does not return any customer documents including but not
              limited to IDs, Address proofs, bank statements and photographs.
              Greattr may disclose the customer information/documents to credit
              agencies/bureaus, RBI or any other financial institution / third
              party that may need the information deemed necessary by Greattr /
              RBI or any other regulatory/legal authority. In this regard, the
              customer waives the privilege of privacy.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default GeneralTermsandConditions;
