import React, { useState } from "react";

import "../distOnboarding/DistPersonalDetails.scss"
import greattrlogo from "../assets/logo.png";
import docuinfo from "../assets/docinfo.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const SafetyCheckEnterAadhaar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const role = state?.role;
  console.log("state", state);
  const [selectedFiles, setSelectedFiles] = useState({
    selfie: null,
    // cheque: null,
    // gst: null,
    // msme: null,
    pan: null,
    aadhaar: null,
  });

  const fileSizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const inputChange = (e, type) => {
    const file = e.target.files[0];

    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      alert(`${file.name} is too large. Please select a file less than 5MB.`);
      return;
    }
    if (!file.type.match(/image\/(jpg|jpeg|png|gif|svg)|application\/pdf/)) {
      alert(
        `${file.name} is not a supported format. Please select an image or PDF.`
      );
      return;
    }

    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [type]: file,
    }));
  };

  const deleteFile = (type) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: null,
      }));
    }
  };
  const [aadhaarNo, setAadhaarNo] = useState("");

  const aadhaarSubmit = async (e) => {
    e.preventDefault();
    var MobileNumber = localStorage.getItem("MobileNumber");
    var GreattrOperatorId = localStorage.getItem(
      "GreattrOperatorId"
    );
    console.log({
      MobileNumber: state?.MobileNumber || MobileNumber,
      AadhaarNumber: aadhaarNo,
      GreattrOperatorId: GreattrOperatorId,
      ...state,
    });

    localStorage.setItem('state', JSON.stringify({
      MobileNumber: state?.MobileNumber || MobileNumber,
      AadhaarNumber: aadhaarNo,
      GreattrOperatorId: GreattrOperatorId,
      ...state,
    }));

    if (role === "Dist") {
      navigate("/DistFinalSet", {
        state: {
          MobileNumber: state?.MobileNumber || MobileNumber,
          AadhaarNumber: aadhaarNo,
          GreattrOperatorId: GreattrOperatorId,
          ...state,
        },
      });
    } else if (role === "Dealer") {
      navigate("/DistFinalSet", {
        state: {
          MobileNumber: state?.MobileNumber || MobileNumber,
          AadhaarNumber: aadhaarNo,
          GreattrOperatorId: GreattrOperatorId,
          ...state,
        },
      });
    } else {
      navigate("/Finalset", {
        state: {
          MobileNumber: state?.MobileNumber || MobileNumber,
          AadhaarNumber: aadhaarNo,
          GreattrOperatorId: GreattrOperatorId,
          ...state,
        },
      });
    }
  };

  return (
    <div className="uploaddocs_company-info">
      <div className="uploaddocs_company-info-container">
        <img src={greattrlogo} className="greattrlogo" alt="GreatTR Logo" />
        <br />
        <p style={{ margin: 0 }}>5 / 5</p>
        <h3 style={{ margin: 0 }}>Enter Aadhaar</h3>
        <p className="sub-heading">Enter Aadhaar for Verification</p>

        <form onSubmit={aadhaarSubmit}>
          <div className="indi-form-group">
            <label htmlFor="aadhaarNo" className="label-font">
              Aadhaar Number*
            </label>
            <br />
            <input
              style={{ padding: 13, borderRadius: 4, fontSize: 16 }}
              type="text"
              id="aadhaarNo"
              name="aadhaarNo"
              className="placeholder-font"
              value={aadhaarNo}
              placeholder="Enter Aadhaar Number"
              onChange={(e) => setAadhaarNo(e.target.value)}
              required
            />
          </div>
          <br />
          <button
            type="submit"
            className="btn btn-primary form-submit dc-doc-btns-submit-button"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default SafetyCheckEnterAadhaar;
