import React, { useState } from "react";
import "./LicenseTemplate.scss";
import logo from "../assets/logo-Colored2.png";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
const EndUserLicense = () => {
  return (
    <>

      <div className="licensetemplate">
        <img src={logo} style={{ width: "80%" }} />
        <br />
        <h1>END USER TERMS FOR GREATTR USER ACCOUNT</h1>
        <div className="licenseContainer">
          <ol type="1">
            <li>
              <b>General</b>
            </li>
            <br />
            <ol type="I">
              <li>
                This website www.Greattr.com its mobile phone and internet-based
                application(s) (“Greattr Platform”) is owned and operated by
                OneAgrow Fintech Private Limited (“OneAgrow”), a company
                incorporated under the Laws of the Republic of India having its
                Corporate Office at Suite No. 501, Rustomjee Central Park,
                Andheri Kurla Road, Andheri East, Mumbai - 400093, India
                (collectively referred to as “Greattr”, “we”, “us”, “our”, as
                the context may apply).
              </li>
              <li>
                The General Terms and Conditions for the said Greattr Platform,
                Privacy Policy, this End User terms for Greattr User Account,
                Credit Line General Terms and Conditions, General terms for
                customer enrollment form Disclaimers, Notifications used by
                Greattr in Greattr Platform are deemed to be incorporated hereby
                way of this reference and shall be read as an integral part
                hereof.
              </li>
              <li>
                Each User of Greattr Platform (hereinafter referred to as
                “User”, “Greattr User”, “you”, “your”, as the context may apply)
                is requested to read the terms herein and confirm acceptance to
                register with Greattr while creating and for using Greattr User
                Account (hereinafter defined) or for availing any services from
                Greattr through Greattr Platform.
              </li>
              <li>
                Each Greattr User is also requested to note and be updated about
                the services, schedule of charges and the list of Lender(s) in
                Greattr Platform and the respective terms conditions as updated
                there from time to time. Each Greattr User confirms to be bound
                by those terms.
              </li>
              <li>
                Each Greattr User understands and confirms that they shall not
                be entitled to delete the user account if there is any pending
                payment towards Greattr or its Lender(s) or for any services
                availed using Greattr Platform.
              </li>
              <li>
                Each Greattr User understands and confirms that this is a
                legally binding contract for the use of Greattr Platform and
                establishing the terms and conditions under which a Greattr User
                Account can be used.
              </li>
              <li>
                Each Greattr User understands and confirms that they may need to
                recognize and comply with additional terms and conditions for
                availing services offered in Greattr Platform as may be
                applicable from time to time.
              </li>
              <li>
                Each Greattr User by accepting these terms understand and
                confirm that Greattr reserve the right to modify these and other
                policies applicable in general and/or to specific areas of
                services in Greattr Platform or to a particular service which
                shall also be considered as part of these terms, at any time
                without giving you any prior notice, and such changes shall be
                binding on you.
              </li>
              <li>
                Each Greattr User understands and confirm that Greattr can
                facilitate your lending journey.
              </li>
              <li>
                Each Greattr User understands that Greattr is authorized by the
                User for collection of the installments.
              </li>
              <li>
                Each Greattr User understands that Greattr can authorize any
                affiliate or third party for collection of processing
                fees/EMIs/fees/charges and/or send email reminders to customers.
              </li>
              <li>
                Each Greattr User understands that disbursement from Greattr is
                made net of processing fees.
              </li>
              <li>
                Each Greattr User, by accepting these terms understand and
                confirm that they shall be required to re-visit these terms from
                time to time to stay abreast of any changes that Greattr
                Platform may introduce to these terms.
              </li>
              <li>
                You shall not accept these terms if you are a person who is
                either barred or otherwise legally prohibited or not authorized
                to use the same.
              </li>
              <li>
                You shall not impersonate any person or entity or falsely state
                or otherwise misrepresent age, identity or affiliation with any
                person or entity.
              </li>
              <li>
                Notwithstanding anything contained herein, we reserve the right
                to deny your registration as a Greattr User and deny our
                services on Greattr Platform without assigning any reason.
              </li>
              <li>
                We strongly advise that independent professional advice be
                obtained before you purchase or avail any product and/or service
                via Greattr Platform.
              </li>
              <li>
                By using the Greattr Platform, you consent to the use of your
                information, as we outlined in our privacy policy. Our services
                use cookies; by using our services or confirming to these terms,
                you consent to our use of cookies in accordance with the terms
                of our privacy policy.
              </li>
              <li>
                By accepting these terms, you confirm to unconditionally accept
                and confirm to comply with and be bound by these terms. You
                confirm not to use the Greattr Platform for any purpose that is
                unlawful, illegal or forbidden by these terms, or any local laws
                that might apply to you.
              </li>
              <li>
                We may translate these terms into other languages for your
                convenience. Nevertheless, the English version governs your
                relationship with us, and any inconsistencies among the
                different versions will be resolved in favor of the English
                version.
              </li>
            </ol>
            <br />
            <li>
              <b>Role of:</b>{" "}
            </li>
            <br />
            <ol type="I">
              <li>
                <b>Greattr</b>
              </li>
              <ol type="a">
                <li>
                  Greattr informs you that we are not a lender or a financial
                  institution and hence, have no part in lending or credit
                  decisions. Greattr does not lend money to anyone, rather,
                  Greattr acts as a bridge that connects you to lender(s) who
                  may consider granting you a Credit Line in India (hereinafter
                  referred to as “Lender(s)”).
                </li>
                <li>
                  Greattr Platform may provide services by Greattr or other
                  persons.
                </li>
                <li>
                  Not with standing anything to the contrary, Greattr does not
                  undertake any product liability/obligation or services
                  obligation or represents or warrants or guarantee on behalf of
                  any third person/s. The Role of Greattr is strictly limited to
                  intermediation. Greattr disclaims any responsibility or
                  liability for any services or offerings on Greattr Platform.
                </li>
              </ol>

              <li>
                <b>Jainex Finance Limited (“Jainex”)</b>
              </li>
              <ol type="a">
                <li>
                  Greattr platform is powered by Jainex. Jainex as part of the
                  its arrangements with other lenders may provide credit
                  enhancements, which may include funding to the Users or
                  provide guarantees on their behalf.
                </li>
              </ol>
            </ol>
            <br />
            <li>
              <b>Greattr User Account</b>
            </li>
            <br />
            <ol type="I">
              <li>
                To use the offerings on Greattr Platform, you have to register
                on Greattr Platform and create a user account (“Greattr User
                Account” or “User Account” or “Account”) and provide your
                details (“User Information”) together to avail the Credit Line
                from Lender(s) or for availing other services in the Greattr
                Platform (as may be applicable).
              </li>
              <li>
                You shall take note that a few of the information is mandatory
                in nature, and few will be optional. You confirm to exercise
                diligence while creating your Greattr User Account and while
                submitting the User Information. You understand and confirm that
                you have the choice not to provide any such details and avoid
                the creation of a Greattr User Account. However, all details
                submitted shall be true and correct. You further
                declare/undertake that the User Information provided is true and
                accurate and shall be responsible for any error whatsoever.
              </li>
              <li>
                You understand and that the Lender(s) and Greattr can rely on
                such information for a grant or reject your request for a grant
                of Credit Line or any services on Greattr Platform.
              </li>
              <li>
                You shall, at all times, keep the password and user ID of your
                Greattr User Account confidential and shall be solely
                responsible for the user activities through your account.
              </li>
              <li>
                The services which are available with your Greattr User Account
                are available on the Greattr Platform.
              </li>
              <li>
                During the period while you are availing the services which we
                provide in the Greattr Platform, we may grant
                licenses/permissions for the use of
                technology/services/materials and User Account through Greattr
                Platform. The same may be canceled at our discretion.
              </li>
              <li>
                You understand that Greattr Platform or services may be
                modified, updated, interrupted, suspended, or discontinued at
                any time without notice or liability.
              </li>
              <li>
                Other than as provided in these terms or in the Greattr
                Platform(s), the services/products/licenses from our side will
                be available to you only during the validity period, which will
                be based on the fee paid by you, or before the termination of
                these terms or before us we discontinuing the
                services/products/licenses or the respective Greattr Platform
                (as may be applicable).
              </li>
              <li>
                Notwithstanding anything to the contrary, you shall not hold
                Greattr liable or responsible for services on Greattr Platform
                from any third person.
              </li>
            </ol>
            <br />
            <li>
              <b>Service Charges</b>
            </li>
            <br />
            <ol type="I">
              <li>
                You shall be required to pay service charges as mentioned under
                the head Schedule of Charges in the Greattr Platform for the
                services availed by you through Greattr Platform.
              </li>
              <li>
                You confirm to make payment to Greattr as per intimations from
                Greattr from time to time for availing services through Greattr
                Platform.
              </li>
              <li>
                You understand that the services in Greattr Platform may be
                denied by Greattr if there is a non-payment of applicable
                charges
              </li>
            </ol>
            <br />
            <li>
              <b>Grant of Authority</b>
            </li>
            <br />
            <ol type="I">
              <li>
                By creating Greattr User Account and submitting User
                Information, you are explicitly confirming that you are
                requesting Credit Line from the Lender(s) or for other services
                through Greattr Platform. Further, you grant authority for the
                following:
              </li>
              <ol type="a">
                <li>
                  For Lender(s), other services providers and Greattr to reach
                  out to you through e-mail, post, telephone or through any
                  other media (including electronic means) now or in future to
                  assist you in getting the Credit Line or for providing other
                  services through Greattr Platform directly or by third
                  parties.
                </li>
                <li>
                  To provide information concerning your interest in Credit Line
                  to third-party marketing companies to offer additional
                  services or products.
                </li>
                <li>
                  To provide credit checks or background checks based on the
                  User Information and submit the same to the Lender(s) or for
                  providing other services through Greattr Platform directly or
                  by third parties, you understand and confirm that Greattr
                  shall in no way be responsible for the outcome of the checks.
                </li>
                <li>
                  To know through phone calls, or SMS, or e-mails on User’s
                  mobile number, various Credit Line offer schemes or Credit
                  Line promotional schemes or any other promotional schemes
                  including that for services through Greattr Platform directly
                  or by third parties.
                </li>
                <li>
                  Confirm that laws concerning the unsolicited communication
                  referred to in “NATIONAL DO NOT CALL REGISTRY” as laid down by
                  TELECOM REGULATORY AUTHORITY OF INDIA will not be applicable
                  for such communication/ calls/ SMS/e-mails received from
                  Greattr. It’s employees, agents, and/or associates.
                </li>
                <li>
                  To co-ordinate for recovery of Credit Line in case of any
                  delay in repayment.
                </li>
                <li>
                  To co-ordinate for providing other services through Greattr
                  Platform directly or by third parties.
                </li>
              </ol>
              <li>
                We may, as a result of your interaction with the Greattr
                Platform, hold and process information obtained about you when
                you access the Greattr Platform.
              </li>
              <li>
                III. By executing these terms, you hereby grant us authority and
                power and authorize us or our authorized representatives to do
                all acts on your behalf as may be necessary to provide the
                services sought by you through the options available in our
                Greattr Platform.
              </li>
              <li>
                IV. By using the Greattr Platform or any its services, you
                confirm and declare that:
              </li>
              <ol type="a">
                <li>
                  You have no objections in Greattr acting as an agent of the
                  Lenders (s), Lender’s Agents or on behalf of the Lender or in
                  such manner as may be decided by Greattr from time to time;
                </li>
                <li>
                  You will be bound by any contractual provisions undertaken to
                  the Lender(s) Greattr Platform;
                </li>
                <li>
                  You confirm your understanding that OneAgrow will be entitled
                  to act as customer support, collection agent, sales agent,
                  technology support and for such other support/services as deem
                  fit by OneAgrow;
                </li>
                <li>
                  You confirm your consent to OneAgrow to perform such functions
                  through electronic or non-electronic means, including Greattr
                  Platform or through its service providers or through such
                  other means as deem fit by OneAgrow from time to time.
                </li>
              </ol>
            </ol>
            <br />
            <li>
              <b>Greattr User Account</b>
            </li>
            <br />
            <ol type="I">
              <li>
                Your Greattr User Account will provide information about your
                interest to avail Credit Line to the Lenders for further
                processes and details of Credit Line offered by Lender(s) and
                its indicative terms.
              </li>
              <li>
                Your Greattr User Account will help you avail services through
                Greattr Platform directly or by third parties.
              </li>
              <li>
                Your Greattr User Account will provide charges for the services
                to you through Greattr Platform directly or by third parties.
              </li>
              <li>
                Your User Information will be submitted with Lender(s) and
                service providers in Greattr Platform, among other details as
                may be required by the Lender(s) or by other services providers
                in Greattr Platform or by Greattr.
              </li>
              <li>
                In case any information is inadequate, you may reach out to the
                Greattr customer case through your Greattr User Account.
              </li>
              <li>
                You understand that Greattr shall not be bound to return any
                documents or details back to you.
              </li>
            </ol>
            <br />
            <li>
              <b>Grant of Credit Line</b>
            </li>
            <br />
            <ol type="I">
              <li>
                You are required to note that all Lender(s) may not offer a
                Credit Line
              </li>
              <li>
                The decision of lending, i.e., to sanction a Credit Line or
                rejection of your Credit Line application as well as the Credit
                Line depends solely on the Lender(s).
              </li>
              <li>
                You confirm that while applying for the Credit Line through
                Greattr Platform, you have visited the partner lending page on
                Greattr Platform and you have also visited the relevant Lender’s
                website and have perused the relevant details required for your
                understanding and confirmation to be granted for availing the
                Credit Line.
              </li>
              <li>
                Once a Lender(s) approves the Credit Line, the terms prescribed
                by the Lender(s) shall be, and you may approve or choose to
                accept it.
              </li>
              <li>
                You may also consult a professional before making your decision.
              </li>
              <li>
                You will be informed about approval or rejection or status of
                your interest to avail Credit Line. Upon sanction of Credit Line
                by Lender(s), you may approve or reject the terms of Credit Line
                sanction through the Greattr Platform.
              </li>
              <li>
                Once you confirm to accept terms of Credit Line sanction by the
                Lender(s) through the Greattr Platform, you shall execute the
                necessary documents as a borrower with the Lender(s) and Greattr
                as the case may require.
              </li>
            </ol>
            <br />
            <li>
              <b>Terms of Disbursement</b>
            </li>
            <br />
            <ol type="I">
              <li>
                You confirm that the terms of disbursement and purpose of Credit
                Line shall be as mentioned on the Greattr Platform and as may be
                deemed fit by the Lender(s) and Greattr from to time.
              </li>
              <li>
                The disbursement will be permitted only as per terms informed by
                the Lender(s) and as required by Greattr.
              </li>
              <li>
                Deviations, if any given, shall be cured by you with the
                timelines intimated by Greattr from time to time, failing which
                it shall be deemed as a default which entitles Greattr or the
                Lender(s) to initiate legal actions without any further
                intimation.
              </li>
              <li>
                Greattr can adjust disbursement amount due to any of you due to
                any Lender(s) or for payment for any services availed by your
                through the Greattr Platform. The same shall be binding on you.
              </li>
            </ol>
            <br />
            <li>
              <b>The rate of interest and charges for Credit Line.</b>
            </li>
            <br />
            <ol type="I">
              <li>
                The Rate of interest for Credit Line and charges will be
                governed as mentioned on the Greattr Platform.
              </li>
              <li>
                You shall be bound by the terms of the Schedule of Charges
                applicable from time to time
              </li>
            </ol>
            <br />
            <li>
              <b>Other General representation, warranties, and covenants</b>
            </li>
            <br />
            <ol type="I">
              <li>The User herein confirms the following:</li>
              <ol type="a">
                <li>
                  The User is an Indian resident as per Foreign Exchange
                  Management Act, 1999 and Income Tax Act, 1961.
                </li>
                <li>
                  Meet the criteria prescribed in the Indian Contract Act, 1872,
                  to enter into a contract and have legal competence and
                  capacity.
                </li>
                <li>
                  Has sufficient income to repay the Credit Line applied from
                  Lender(s).
                </li>
                <li>
                  Has identity proof and the same submitted to Greattr is true
                  and correct.
                </li>
                <li>
                  Has income proof, and the same is adequate to repay the Credit
                  Line or for making payments for any services availed through
                  the Greattr Platform.
                </li>
                <li>Has a valid and active bank account in India.</li>
                <li>
                  The users shall comply with all the laws applicable to them,
                  respectively, for using the Greattr Platform at territory, the
                  User is located, and in India.
                </li>
                <li>
                  The details submitted through the Greattr Platform are true
                  and correct.
                </li>
                <li>
                  You do not have the right to use any of our trade names,
                  trademarks, service marks, logos, domain names, and other
                  distinctive brand features. You do not have the right to copy
                  and use the software, text, images, graphics, video, and audio
                  used on Greattr Platform (“Content”). You do not have the
                  right to remove, obscure, or alter any proprietary rights
                  notices (including trademark and copyright notices), which may
                  be affixed to or contained within the services. You will not
                  copy or transmit any of the services.
                </li>
                <li>
                  You understand and confirm that we neither represent nor
                  warrants that your use of materials displayed at the Greattr
                  Platform will not infringe on third parties’ rights.
                </li>
              </ol>
              <li>
                By using Greattr Platform, you confirm that you shall not:
              </li>
              <ol type="a">
                <li>
                  use our services and Greattr Platform for spamming or any
                  other illegal purposes;
                </li>
                <li>
                  infringe ours or any third party’s intellectual property
                  rights, rights of publicity or privacy;
                </li>
                <li>
                  post or transmit any message which is libelous, defamatory or
                  which discloses private or personal matters concerning any
                  person;
                </li>
                <li>
                  post or transmit any message, data, image or program which
                  violates any law;
                </li>
                <li>
                  refuse to cooperate in an investigation or provide
                  confirmation of your identity or any other information you
                  provide to us;
                </li>
                <li>
                  remove, circumvent, disable, damage or otherwise interfere
                  with security-related features of Greattr Platform or features
                  that enforce limitations on the use of our services and
                  Greattr Platform;
                </li>
                <li>
                  upload any content that constitutes negligent advice or
                  contains any negligent statement, an incitement to commit a
                  crime or contains instructions for the commission of a crime
                  or the promotion of criminal activity; or any content which is
                  in contempt of any court, or in breach of any court order; or
                  discriminates on the basis of age, sex, religion, race,
                  gender; harassing, invasive of other’s privacy, blasphemous;
                  in breach of any contractual obligations or depicts violence
                  or is pornographic, pedophilic, obscene, suggestive or
                  sexually explicit; or consists of or contains any
                  instructions, advice or other information which may be acted
                  upon and could, if acted upon, cause illness, injury or death,
                  or any other loss or damage; or constitutes spam; or is
                  grossly harmful, offensive, deceptive, fraudulent,
                  threatening, abusive, hateful, harassing, anti-social,
                  menacing, hateful, discriminatory or inflammatory; or causes
                  annoyance, inconvenience or needless anxiety to any person; or
                  racially, ethnically objectionable, disparaging, relating or
                  encouraging money laundering or gambling, or harm minors in
                  any way or otherwise unlawful in any manner whatever;
                </li>
                <li>
                  upload any content that threatens the unity, integrity,
                  defense, security or sovereignty of any country, or public
                  order or causes incitement to the commission of any cognizable
                  offense or prevents investigation of any offense or is
                  insulting any nation;
                </li>
                <li>
                  upload any content that contains software viruses or any other
                  computer code, files or programs designed to interrupt,
                  destroy or limit the functionality of any computer resource;
                </li>
                <li>
                  reverse engineer, decompile, disassemble or otherwise attempt
                  to discover the source code of our services and Greattr
                  Platform or any part thereof or infringe any patent,
                  trademark, copyright or other proprietary rights;
                </li>
                <li>
                  use our services and Greattr Platform in any manner that could
                  damage, disable, overburden, or impair, including, without
                  limitation, using our services and Greattr Platform in an
                  automated manner;
                </li>
                <li>
                  modify, adapt, translate or create derivative works based upon
                  our services and Greattr Platform or any part thereof;
                </li>
                <li>
                  intentionally interfere with or damage the operation of our
                  services and Greattr Platform or any other User’s use of our
                  services and Greattr Platform, by any means, including
                  uploading or otherwise disseminating viruses, adware, spyware,
                  worms, or other malicious code or file with contaminating or
                  destructive features;
                </li>
                <li>
                  use any robot, spider, other automatic devices, or manual
                  process to monitor or copy our services and Greattr Platform
                  without our prior written permission;
                </li>
                <li>
                  interfere or disrupt our services and Greattr Platform or
                  networks connected therewith;
                </li>
                <li>
                  take any action that imposes an unreasonably or
                  disproportionately large load on our infrastructure/network;
                </li>
                <li>
                  use any device, software or routine to bypass our services and
                  Greattr Platform robot exclusion headers, or interfere or
                  attempt to interfere, with our services and Greattr Platform;
                </li>
                <li>
                  forge headers or manipulate identifiers or other data to
                  disguise the origin of any content transmitted through our
                  services and Greattr Platform or to manipulate your presence
                  on our services and Greattr Platform;
                </li>
                <li>
                  use the facilities and capabilities of our services and
                  Greattr Platform to conduct any activity or solicit the
                  performance of any illegal activity or other activity which
                  infringes the rights of others;{" "}
                </li>
                <li>
                  breach these terms or any other policies which we may adopt
                  from time to time;
                </li>
                <li>
                  provide false, inaccurate or misleading information to our
                  services and Greattr Platform; and
                </li>
                <li>
                  use our services and Greattr Platform to collect or obtain
                  personal information, including without limitation, personal
                  information about other Users of our services, and Greattr
                  Platform.
                </li>
              </ol>
              <li>
                Although we may from time to time monitor or review postings,
                transmissions, and the like on the Greattr Platform, we are
                under no obligation to do so and assume no responsibility or
                liability arising from the content of any such locations nor for
                any error, defamation, libel, slander, omission, falsehood,
                obscenity, pornography, profanity, danger, or inaccuracy
                contained in any information within such locations on the
                Greattr Platform.
              </li>
              <li>
                You are prohibited from posting or transmitting any unlawful,
                threatening, libelous, defamatory, obscene, scandalous,
                inflammatory, pornographic, or profane material or any material
                that could constitute or encourage conduct that would be
                considered a criminal offense, give rise to civil liability, or
                otherwise violate any law.
              </li>
              <li>
                We retain the right to remove any such posting and fully
                cooperate with any law enforcement authorities or court order to
                request or direct us to disclose the identity of anyone posting
                any such information or materials.
              </li>
              <li></li>
            </ol>
            <br />
            <li>
              <b>Disclaimers</b>
            </li>
            <br />
            <ol type="I">
              <li>
                Greattr may advertise certain Credit Line products of the
                Lenders or other services in the Greattr Platform; however, that
                does not mean that Greattr warrants or represents quality or
                features of any the services of any Lender(s) or such persons.
              </li>
              <li>
                The Credit Line conditions offered by the Lender(s) may not
                always suit your requirements. If you are unsure about any
                aspect of the Credit Line given by the Lender(s), Greattr takes
                no responsibility towards the offer made by the Lender(s), and
                there is no involvement of Greattr whatsoever in these
                decisions.
              </li>
              <li>
                Furthermore, Greattr has no role in determining how a Financial
                Service Provider may use the information given by you. By using
                the Greattr Platform, you are acknowledging that Greattr is not
                liable for any damages or costs, financial or otherwise,
                incurred by you due to your transaction with any Lender(s).
              </li>
              <li>
                Greattr does not confirm the suitability of any other services
                offered through the Greattr Platform for any of your particular
                needs; you shall solely be responsible for any cost incurred by
                you for availing the same. In no event, notwithstanding anything
                contained here or in any of the Greattr Platform, you shall not
                hold Greattr responsible or liable for the same.
              </li>
              <li>
                You understand and confirm that Greattr Platform hosts
                information provided by third parties. We are in no manner
                responsible to you for the accuracy, legitimacy, correctness of
                the information hosted. We take reasonable care to ensure such
                precision, but we are not responsible for the furnished
                information. You confirm not to hold us liable for the
                falsification of any such provided information.
              </li>
            </ol>
            <br />
            <li>
              <b>Indemnification</b>
            </li>
            <br />
            <ol type="I">
              <li>
                I. As consideration for entering into this contract, you confirm
                to indemnify and hold Greattr and its Lender(s) or any service
                providers on the Greattr Platform harmless from and against any
                and all claims, action, liability, cost, loss, damage, endured
                by Greattr by User’s access to the Greattr Platform in violation
                to the listed terms of service and also to the applicable laws,
                rules and regulations or contracts prevailing from time to time.
              </li>
            </ol>
            <br />
            <li>
              <b>Display/ Advertisements/ Web Links</b>
            </li>
            <br />
            <ol type="I">
              <li>
                We have not reviewed any sites that may be linked to the Greattr
                Platform and are not responsible for the content of any off-site
                pages or any other sites linked to the Greattr Platform. Your
                linking to any other off-site pages or other websites is at your
                own risk.
              </li>
              <li>
                Our display on or through the Greattr Platform of various
                services or product options offered by third parties does not in
                any way imply, suggest, or constitute any sponsorship,
                recommendation, or approval or advice from us for any such third
                parties or their products.
              </li>
              <li>
                You confirm that we are in no way responsible for the accuracy,
                timeliness, or completeness of information it may obtain from
                these third parties. Your interaction with any third party
                accessed through the Greattr Platform is at your own risk, and
                we will have no liability with respect to the acts, omissions,
                errors, representations, warranties, breaches or negligence of
                any such third parties or for any personal injuries, death,
                property damage, or other damages or expenses resulting from
                your interactions with the third parties.
              </li>
              <li>
                You confirm that you may need to confirm with the terms and
                conditions of such third parties by accessing their Greattr
                Platform, and the same will be at your sole risk and
                responsibility.
              </li>
            </ol>
            <br />
            <li>
              <b>Inaccuracies at the Greattr Platform</b>
            </li>
            <br />
            <ol type="I">
              <li>
                While we use reasonable efforts to include accurate and up to
                date information at the Greattr Platform, we make no warranties
                or representations as to the Greattr Platform ’s accuracy. We
                disclaim any and all liability for the accuracy, completeness,
                or correctness of such information
              </li>
            </ol>
            <br />
            <li>
              <b>Security at the Greattr Platform</b>
            </li>
            <br />
            <ol type="a">
              <li>
                While we use reasonable efforts to safeguard the Greattr
                Platform security, there can be no guarantee that such
                safeguards will successfully prevent unauthorized alterations in
                the content or functionality of the Greattr Platform. We assume
                no liability or responsibility for any unauthorized changes in
                the content or functionality of the Greattr Platform.
              </li>
            </ol>
            <br />
            <li>
              <b>Disclaimer of warranty</b>
            </li>
            <br />
            <ol type="i">
              <li>
                I. The Greattr Platform and all content and services provided on
                the Greattr Platform are provided on an “as is” and “as
                available” basis. We expressly disclaim all warranties of any
                kind, whether express or implied, including, but not limited to,
                the implied warranties of merchantability, fitness for a
                particular purpose, title, non-infringement, and security and
                accuracy, as well as all warranties arising by usage of trade,
                course of dealing, or course of performance. We make no
                warranty, and expressly disclaims any obligation, that:
              </li>
              <ol type="a">
                <li>
                  the content will be up-to-date, complete, comprehensive,
                  accurate or applicable to your circumstances;
                </li>
                <li>
                  the Greattr Platform will meet your requirements or be
                  available on an uninterrupted, timely, secure, or error-free
                  basis
                </li>
                <li>
                  the results that may be obtained from the use of the Greattr
                  Platform or any services offered through the site will be
                  accurate or reliable; or
                </li>
                <li>
                  the quality of any products, services, information, or other
                  material obtained by you through the Greattr Platform will
                  meet your expectations.
                </li>
              </ol>
            </ol>
            <br />
            <li>
              <b>Limitation of Liability</b>
            </li>
            <br />
            <ol type="I">
              <li>
                We (including its officers, directors, employees,
                representatives, affiliates, and providers) will not be
                responsible or liable for (a) any injury, death, loss, claim, an
                act of god, accident, delay, or any direct, special, exemplary,
                punitive, indirect, incidental or consequential damages of any
                kind (including without limitation lost profits or lost
                savings), whether based in contract, tort, strict liability or
                otherwise, that arise out of or is in any way connected with (i)
                any failure or delay (including without limitation the use of or
                inability to use any component of the Greattr Platform), or (ii)
                any use of the Greattr Platform or content, or (iii) the
                performance or non-performance by us or any provider, even if we
                have been advised of the possibility of damages to such parties
                or any other party, or (b) any damages to or viruses that may
                infect your computer equipment or other property as the result
                of your access to the Greattr Platform or your downloading of
                any content from the Greattr Platform.
              </li>
            </ol>
            <br />
            <li>
              <b>Use information on Greattr Platform</b>
            </li>
            <br />
            <ol type="i">
              <li>
                Except as expressly permitted by these terms, no portion of the
                information on the Greattr Platform may be reproduced by you in
                any form, or by any means, without our prior written permission.
              </li>
            </ol>
            <br />
            <li>
              <b>Governing law and Jurisdiction</b>
            </li>
            <br />
            <ol type="I">
              <li>
                The laws of India, without regard to its conflict of laws rules,
                will govern these terms, as well as your and our observance of
                them.
              </li>
              <li>
                If you take any legal action relating to your use of the Greattr
                Platform or these terms, you confirm to file such action only in
                the courts located in Mumbai, Republic of India.
              </li>
            </ol>
            <br />
            <li>
              <b>Governing Law, Jurisdiction, and Dispute Resolution</b>
            </li>
            <br />
            <ol type="I">
              <li>
                I. The laws of India, without regard to its conflict of laws
                rules, will govern these Terms, as well as your and our
                observance of them.
              </li>
              <li>
                II. If you take any legal action relating to your use of the
                Greattr Platform or these Terms, the same shall be subject to
                the exclusive Jurisdiction of courts located in Mumbai, India.
              </li>
              <li>
                III. In case you have any disputes with Greattr, you may raise
                your issues to Greattr and request for mediation.
              </li>
              <li>
                IV. If the matter is not resolved through such mediation, the
                same may then be settled by Arbitration by a single Arbitrator
                appointed by Greattr. The Arbitration shall be held in
                accordance with the provisions of the Arbitration and
                Conciliation Act, 1996. The language of the Arbitration shall be
                in English, and the place of Arbitration shall be Mumbai, India.
              </li>
            </ol>
            <br />
            <li>
              <b>Breach of the terms</b>
            </li>
            <br />
            <ol type="I">
              <li>
                Without prejudice to our other rights under these terms, if you
                breach these terms and, in any way, or if we suspect that you
                have breached these terms in any way, we may
              </li>
              <ol type="a">
                <li>send you one or more formal warnings;</li>
                <li>
                  temporarily suspend your access to Greattr Platform and
                  services;
                </li>
                <li>
                  permanently prohibit you from accessing Greattr Platform and
                  services;
                </li>
                <li>
                  block computers using your IP address from accessing Greattr
                  Platform and services;
                </li>
                <li>
                  contact any or all of your internet service providers and
                  request that they block your access to Greattr Platform and
                  services;
                </li>
                <li>
                  commence legal action against you, whether for breach of
                  contract or recovery of amounts due or damages or otherwise
                </li>
                <li>
                  suspend or delete your account on Greattr Platform and
                  services;
                </li>
              </ol>
              <li>
                Notwithstanding anything to the contrary contained this these
                terms after termination or suspension of services or
                discontinuance of your User Account on Greattr Platform pursuant
                to your breach or otherwise, you will not be entitled to refund
                of any fee or charges paid, nor shall be discharged from any
                pending obligation accrued in favor or Greattr or towards any
                Lender(s) or by availing any services through Greattr Platform.
              </li>
            </ol>
            <br />
            <li>
              <b>References</b>
            </li>
            <br />
            <ol type="I">
              <li>
                Any reference to a statutory provision shall include such
                provision from time to time modified or re-enacted or
                consolidated so far as such modification or re-enactment or
                consolidation applies or is capable of using it to any
                transactions entered into hereunder.
              </li>
            </ol>
            <br />
            <li>
              <b>Assignment</b>
            </li>
            <br />
            <ol type="I">
              <li>
                We shall be permitted to assign, transfer, and subcontract its
                rights and/or obligations under these terms without any
                notification or consent required. However, you shall not be
                permitted to assign, transfer, or subcontract any of your rights
                and/or obligations under these terms.
              </li>
            </ol>
            <br />
            <li>
              <b>Contact us/Grievance Redressal/ Feedback</b>
            </li>
            <br />
            <ol type="I">
              <li>
                In case you need any details or clarifications or redressal of
                grievances or have any feedback, you can contact us on
                www.Greattr.com/contact-us.
              </li>
              <li>
                Also, we believe that your feedback makes use of Greattr
                Platform and services better; please feel free to share it with
                us on www.Greattr.com/contact-us. Unless admitted explicitly by
                us or as required by law, all feedback shall be
                non-confidential.
              </li>
              <li>
                We will assume no responsibility for reviewing unsolicited ideas
                and will not incur any liability due to any similarities between
                those ideas and materials that may appear in future programs of
                Greattr.
              </li>
              <li>
                Please do not reveal trade secrets or other confidential
                information in your messages to Greattr. Any and all rights to
                materials submitted to us become the exclusive property of
                Greattr.
              </li>

            </ol>
          </ol>
        </div>

        <br />
        <h1> Contact Us </h1> Name: ONEAGROW FINTECH PRIVATE LIMITED
        <br />
        Address: Suite No. 501, Rustomjee Central Park, Andheri Kurla Road,
        Andheri East, Mumbai - 400093 <br />
        Email Id: grievance.officer@Jainexfin.com <br />
        Contact No: +91-84220 54444 <br />
        Contact Person: Mr. Shubham Srivastava Nodal/ Grievance Officer: Mr.
        Shubham Srivastava
        <br />
      </div>
    </>
  );
};

export default EndUserLicense;
