import React from "react";
import "./DistAccountCreatedSuccessfully.scss";
import AccountCreatedLogo from "../assets/AccountCreatedLogo.png";

const DistAccountCreatedSuccessfully = () => {
  return (
    <div className="company-info">
      <div className="company-info-container success-container">
        <div className="logo-container">
          <img
            className="success-logo"
            src={AccountCreatedLogo}
            alt="Account Created Successfully"
          />
        </div>
        <h1 className="success-title">Account created successfully!</h1>
        <div className="success-message" style={{ marginTop: 10 }}>
          <p>
            Congratulations! Your account has been created successfully. Your
            account is currently under review. We'll send a login link once it's
            approved.
          </p>
          <p>
            We look forward to supporting your business growth with our micro
            Buy Now, Pay Later services. You'll receive a login link shortly to
            your registered email.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DistAccountCreatedSuccessfully;
