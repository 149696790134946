// NavContext.js
import { faL } from "@fortawesome/free-solid-svg-icons";
import React, { useState, createContext, useEffect } from "react";

const NavContext = createContext();

export const NavProvider = ({ children }) => {
  const [isLogin, setisLogin] = useState(false);
  const [isOperatorLogin, setisOperatorLogin] = useState(false);

  // Load initial state from localStorage (if available)
  useEffect(() => {
    const storedIsOperatorLogin = localStorage.getItem("isOperatorLogin");
    if (storedIsOperatorLogin !== null) {
      setisOperatorLogin(JSON.parse(storedIsOperatorLogin));
    }
  }, []);

  // Update localStorage when isOperatorLogin changes
  useEffect(() => {
    localStorage.setItem("isOperatorLogin", JSON.stringify(isOperatorLogin));
  }, [isOperatorLogin]);

  return (
    <NavContext.Provider
      value={{ isLogin, setisLogin, isOperatorLogin, setisOperatorLogin }}
    >
      {children}
    </NavContext.Provider>
  );
};

export default NavContext;
