import PropTypes from "prop-types";
import styled from "styled-components";

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.143rem;
  text-align: left;
  font-size: 1.288rem;
  color: var(--text-default);
  font-family: var(--font-text-small);
  ${(props) => props.className}
`;

const GridItem = styled.div`
  display: flex;
  flex-row: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.143rem;
`;

const Badge = styled.div`
  height: 1.856rem;
  width: 1.856rem;
  border-radius: 114.29px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.281rem 0.25rem 0.287rem;
  box-sizing: border-box;
`;

const BadgeImage = styled.img`
  height: 1.288rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`;

const Text = styled.div`
  position: relative;
  leading-trim: both;
  text-edge: cap;
  line-height: 180%;
  font-weight: 500;
  font-family: "Inter";
  font-size: 1.5rem;

  @media (max-width: 450px) {
    font-size: 1rem;
    line-height: 1.875rem;
  }
`;

const Grid = ({
  className = "",
  badgeName,
  paperless,
  badgeName1,
  noCollateralFree,
}) => {
  return (
    <GridContainer className={className}>
      <GridItem>
        <Badge name={badgeName}>
          <BadgeImage loading="lazy" alt="" src="/richeckfill.svg" />
        </Badge>
        <Text>{paperless}</Text>
      </GridItem>
      <GridItem>
        <Badge name={badgeName1}>
          <BadgeImage loading="lazy" alt="" src="/richeckfill.svg" />
        </Badge>
        <Text>{noCollateralFree}</Text>
      </GridItem>
    </GridContainer>
  );
};

Grid.propTypes = {
  className: PropTypes.string,
  badgeName: PropTypes.string,
  paperless: PropTypes.string,
  badgeName1: PropTypes.string,
  noCollateralFree: PropTypes.string,
};

export default Grid;
