import React, { useState } from "react";
import "../UploadDocuments.scss";
import greattrlogo from "../assets/logo.png";
import docuinfo from "../assets/docinfo.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { faL } from "@fortawesome/free-solid-svg-icons";

const DistUploadDocuments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  console.log(state);
  const [selectedFiles, setSelectedFiles] = useState({
    selfie: null,
    // cheque: null,
    // gst: null,
    // msme: null,
    pan: null,
    aadhaar: null,
  });

  const fileSizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const inputChange = (e, type) => {
    const file = e.target.files[0];

    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      alert(`${file.name} is too large. Please select a file less than 5MB.`);
      return;
    }
    if (!file.type.match(/image\/(jpg|jpeg|png|gif|svg)|application\/pdf/)) {
      alert(
        `${file.name} is not a supported format. Please select an image or PDF.`
      );
      return;
    }

    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [type]: file,
    }));
  };

  const deleteFile = (type) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: null,
      }));
    }
  };

  const fileUploadSubmit = async (e) => {
    setisLoading(true);
    e.preventDefault();

    const allFilesSelected = Object.values(selectedFiles).every(
      (file) => file !== null
    );

    if (!allFilesSelected) {
      alert("Please select all files before submitting.");
      return;
    }

    const formData = new FormData();
    Object.keys(selectedFiles).forEach((key) => {
      formData.append(key, selectedFiles[key]);
    });

    formData.append("GreattrDealerID", state?.GreattrDealerID);
    formData.append("GreattrDistID", state?.GreattrDistID);

    try {
      const route =
        state.role === "Dist"
          ? "/uploadMerchantDocuments"
          : "/uploadDealerDocuments";
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}${route}`,
        // `https://api.greattr.com/uploadOperatorDocuments`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle success response here
      console.log("Upload successful:", response.data);

      if (response.status === 200 && response.data.results) {
        console.log("Upload successful:", response.data);
        alert("Files uploaded successfully!");
        navigate("/DistPersonalDetails", {
          state: {
            MobileNumber: state.MobileNumber,
            ...state,
          },
        });
        // Reset form or redirect as needed
      } else {
        throw new Error("Upload failed or no files were uploaded");
      }
    } catch (error) {
      // Handle error
      console.error("Error uploading files:", error);
      alert("Error uploading files. Please try again later.");
    } finally {
      setisLoading(false);
    }
  };

  const renderFileInput = (label, type) => (
    <div className="dc-file-upload-section">
      <label className="Dist-upload-dc_label">{label}</label>
      <div className="kb-file-upload">
        {!selectedFiles[type] && (
          <div className="Greattr-file-upload-box">
            <input
              type="file"
              id="fileupload"
              className="file-upload-input"
              onChange={(e) => inputChange(e, type)}
            />
            <span className="file-link2">
              Drag and drop or{" "}
              <span className="file-link">Choose your file</span>
            </span>
          </div>
        )}
      </div>

      {selectedFiles[type] && (
        <div className="dc-file-preview">
          <div className="dc-file-detail">
            <h6>{selectedFiles[type].name}</h6>
            <p>
              <span>Size: {fileSizes(selectedFiles[type].size)}</span>
              <span className="ml-2">
                Modified Time:{" "}
                {selectedFiles[type].lastModifiedDate?.toLocaleString("en-IN")}
              </span>
            </p>
            <div className="dc-file-actions">
              <button
                type="button"
                className="dc-file-action-btn"
                onClick={() => deleteFile(type)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="uploaddocs_company-info">
      <div className="uploaddocs_company-info-container">
        <img className="greattrlogo" src={greattrlogo} alt="GreatTR Logo" />
        <br />
        <p style={{ margin: 0 }}>3 / 6</p>
        <h3 style={{ margin: 0, fontSize: 22 }}>Document Upload</h3>
        <h5 style={{ marginTop: 2, fontSize: 15, maxWidth: 600 }}>
          To keep our platform safe and secure, we need to verify your business.
          Please upload the required documents. This helps us ensure compliance
          and authenticity.
        </h5>

        <h5 style={{ marginTop: 2, fontSize: 15, fontWeight: 700 }}>
          {" "}
          Please refer to the points below while uploading the documents
        </h5>
        <img
          className="DocumentInstructionImage"
          src={docuinfo}
          alt="Document Info"
        />
        <form onSubmit={fileUploadSubmit}>
          <div className="dc-doc-btns-form layout">
            <div className="5">{renderFileInput("Buisness Pan*", "pan")}</div>
            <div className="6">{renderFileInput("Your Pan*", "aadhaar")}</div>

            <div className="3">
              {renderFileInput("GST Certificate*", "gst")}
            </div>
            <div className="4">
              {renderFileInput("Udyam Aadhaar*", "Udyam Aadhaar")}
            </div>
            <div className="1">
              {renderFileInput("Applicant Selfie OR Passport Size*", "selfie")}
            </div>
            <div className="2">
              {renderFileInput("Cancelled Cheque*", "cheque")}
            </div>
          </div>
          <div className="dc-kb-buttons-box dc-doc-btns-buttons-box">
            <button
              type="submit"
              className="btn btn-primary form-submit dc-doc-btns-submit-button"
            >
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DistUploadDocuments;
