import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import StyleSheet from "react";
import axios from "axios";
import { GDSRecords, GESRecords, GES, GDS } from "../Utilsx/Encrypt";
import "./surveyOTP.css";
const SurveyOTP = ({ route, navigation }) => {
  // const [otpinput, setotpinput] = useState('');
  // const [timer, setTimer] = useState(60);
  // const [loading, setLoading] = useState(false);

  const [otp, setOTP] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { state } = location;

  const [isLoading, setisLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // State for captcha
  const [isOtpSent, setisOtpSent] = useState(false);
  let domain = `https://api.greattr.com`;
  // let domain = `http://localhost:3000`;

  const [okycdata, setokycdata] = useState(null);
  console.log("Location State:", state);

  const language = location.state?.language || "English";
  const mobile = location.state?.mobileNumber;
  const Aadhar = location.state?.Aadhar;

  console.log("aadhar Number from State:", Aadhar);

  const navigate = useNavigate();
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  const [transactionId, settransactionId] = useState(null);
  const [fwdp, setfwdp] = useState(null);
  const [codeVerifier, setcodeVerifier] = useState(null);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate("/survey", { replace: true });
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const generateCaptcha = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_LINK}/kyc/generate-captcha`,
        method: "POST",
        responseType: "json",
        data: {
          uid: state?.Aadhar.replace(/-/g, ""),
          uniqueId: state?.MobileNumber || state?.GreattrOperatorId,
        },
      });
      if (response.status === 500) {
        alert("Some problem has occured . Please try again later");
      } else {
        console.log("/kyc/generate-captcha", response?.data);
        setCaptchaValue("");
        setokycdata(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const getOtp = async () => {
    try {
      if (!captchaValue) {
        alert("Please complete the captcha.");
        return;
      }

      setisLoading(true);
      let domain = `https://api.greattr.com`;
      // let domain = `http://localhost:3000`;
      // Verify the captcha
      const captchaResponse = await axios.post(`${domain}/kyc/generate-otp`, {
        captcha: captchaValue,
        uid: Aadhar,

        ...okycdata,
      });

      console.log("get otp response", captchaResponse);

      console.log("response data", captchaResponse.data);

      if (captchaResponse.data.statusCode === 200) {
        setokycdata(captchaResponse.data);
      }
      console.log("OKYC data", okycdata);

      if (captchaResponse.data.statusCode !== 200) {
        alert("Captcha verification failed. Please try again.");
        return;
      }
      if (captchaResponse.status === 200) {
        setisOtpSent(true);
        alert("OTP Sent Successfully");

        return;
      } else {
        alert("Captcha verification timed out. Please reload the page.");
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setisLoading(false);
    }
  };
  function maskAadhaar(aadhaarNumber) {
    // Remove hyphens from the Aadhaar number
    let cleanedAadhaar = aadhaarNumber.replace(/-/g, "");

    // Mask the state code (first two digits) with 'XX'
    let maskedAadhaar = "XXXXXXXX" + cleanedAadhaar.substring(8);

    return maskedAadhaar;
  }
  const submitOkycOtp = async () => {
    try {
      console.log("lassii");
      let domain = `https://api.greattr.com`;
      // let domain = `http://localhost:3000`;
      const OkycSubmitData = GESRecords(
        {
          ...okycdata,
          maskedAadharNumber: maskAadhaar(state?.Aadhar.replace(/-/g, "")),
          otp: otp,
          MobileNumber: state.mobileNumber,
          p1data: {
            ...state,
            MobileNumber: state.mobileNumber,

            GreattrOperatorId:
              state?.GreattrOperatorId ||
              localStorage.getItem("GreattrOperatorId"),
            maskedAadharNumber: maskAadhaar(state?.Aadhar.replace(/-/g, "")),
          },
        },
        apiEN
      );
      console.log("okyc submit data -----------", OkycSubmitData);
      const SubmitOkycResponse = await axios.post(
        `${domain}/kyc/surveyCustomer-download-okyc`,
        OkycSubmitData
      );

      console.log(SubmitOkycResponse);

      if (SubmitOkycResponse.status === 200) {
        console.log("----------------- Submit OTP response");
        navigate("/allset");
        // console.log("response data ", response.data);
      } else {
        alert("There seems to be a problem . Please try again later");
      }
    } catch (error) {
      console.log(error);
      alert("error");
      throw error;
    }
  };

  const validateOTP = (number) => {
    const OTPRegex = /^\d{6}$/;
    return OTPRegex.test(number);
  };

  const submitOtp = async (e) => {
    e.preventDefault();

    const isValidOTP = validateOTP(otp);

    if (!isValidOTP) {
      alert("Please enter a valid OTP");
      return;
    }

    let body = {
      mobile: mobile,
      GreattrOperatorId: localStorage.getItem("GreattrOperatorId"),
      otp: otp,
      codeVerifier: codeVerifier,
      transactionId: transactionId,
      fwdp: fwdp,
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/survey-Aadhar-okyc-submit-otp`,
        {
          ...body,
        }
      );
      if (response.status === 200) {
        setMessage("OTP verified successfully");
        navigate("/allset", { state: language });
      } else {
        setMessage("Failed to verify OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setMessage("Error verifying OTP");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        padding: "5%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          color: "black",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/img/svg/Logo.svg"}
          alt="SVG Icon"
          width={300}
          style={{ padding: "2%" }}
        />

        {!isOtpSent && (
          <p
            style={{
              fontSize: 20,
              textAlign: "center",
              margin: "0 auto",
              marginTop: 20,
            }}
            className="sub-heading"
          >
            Enter the Captcha code below to get KYC process started
          </p>
        )}

        {!isOtpSent && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5%",
              justifyContent: "space-between",
              height: "50vh",
            }}
          >
            <div
              style={{
                display: isOtpSent ? "none" : "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
              >
                <img
                  src={`data:image/png;base64,${okycdata?.encodedCaptcha}`}
                  alt="captcha"
                  style={{ width: 250, height: 150 }}
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/reload.svg"}
                  alt="SVG Icon"
                  onClick={generateCaptcha}
                  width={40}
                  className="img-responsive"
                />
              </div>
              <br />
              <label
                htmlFor="captchaInput"
                className="CaptchaLabel"
                style={{
                  fontSize: "1.8rem",
                  fontStyle: "Manrope-Regular",
                  fontWeight: "900",
                }}
              >
                Enter Captcha Value:
              </label>
              <input
                type="text"
                id="captchaInput"
                value={captchaValue}
                style={{
                  padding: "4%",
                  fontSize: "2rem",
                  marginBottom: "1%",
                }}
                className="input2"
                onChange={(e) => setCaptchaValue(e.target.value)}
                placeholder="Enter Captcha Value"
                required
              />
              <br />
              <button
                style={{ padding: 15 }}
                className="continue-button"
                onClick={getOtp}
                // disabled={isOtpSent ? true : false}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Get OTP"
                )}
              </button>
            </div>
          </div>
        )}

        {isOtpSent && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5%",
              justifyContent: "space-between",
              height: "50vh",
            }}
          >
            <p
              style={{
                fontSize: "3rem",
                fontStyle: "Manrope-Regular",
                fontWeight: "900",
              }}
            >
              {language === "English"
                ? `Enter the code we texted you`
                : `कृपया कोड दर्ज करें |`}
            </p>
            <p>
              {language === "English"
                ? `We’ve sent an SMS to your number linked to your Aadhar number.`
                : `हमने आपके आधार नंबर से जुड़े आपके नंबर पर एक एसएमएस भेजा है।`}
            </p>

            <input
              type="text"
              placeholder={
                language === "English" ? `Aadhaar OTP` : `आधार ओ.टी.पी`
              }
              style={{
                padding: "4%",
                fontSize: "2rem",
                marginBottom: "1%",
              }}
              value={otp}
              onChange={(e) => {
                if (e.target.value.length < 7) {
                  setOTP(e.target.value);
                }
              }}
              className="input2"
            />
            <p
              style={{
                fontSize: "1.55rem",
                fontStyle: "Manrope-Regular",
                color: "gray",
              }}
            >
              {language === "English"
                ? `Your 6 digit code is on its way. This can sometimes take a few moments to arrive.`
                : `आपका 6 अंकों का कोड आने वाला है। इसे आने में कभी-कभी कुछ क्षण लग सकते हैं।`}
            </p>
            <TimerContainer>
              {(timer, setTimer) => (
                <>
                  {timer < 1 ? (
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        getOtp();
                        setTimer(60);
                      }}
                    >
                      <p
                        style={{
                          color: "#573A87",
                          fontSize: "2rem",
                          fontStyle: "Manrope-Regular",
                          fontWeight: "900",
                          cursor: "pointer",
                        }}
                      >
                        Resend OTP
                      </p>
                    </button>
                  ) : (
                    <Timer timer={timer} />
                  )}
                </>
              )}
            </TimerContainer>
          </div>
        )}
      </div>

      {isOtpSent && (
        <button
          onClick={submitOkycOtp}
          style={{
            backgroundColor: "#573A87",
            color: "white",
            padding: "4%",
            fontWeight: "600",
            width: "100%",
            borderWidth: 1,
            borderColor: "white",
            borderRadius: 6,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="spinner"
                style={{
                  border: "4px solid #f3f3f3",
                  borderTop: "4px solid #3498db",
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                  animation: "spin 2s linear infinite",
                }}
              ></div>
              <span style={{ marginLeft: "8px" }}>
                {language === "English" ? `Submitting...` : `दर्ज कर रहा है...`}
              </span>
            </div>
          ) : language === "English" ? (
            `Submit`
          ) : (
            `दर्ज करें`
          )}
        </button>
      )}
    </div>
  );
};

const Timer = ({ timer }) => (
  <div
    style={{
      color: "gray",
      fontSize: "2rem",
      fontStyle: "Manrope-Regular",
      fontWeight: "900",
      cursor: "pointer",
    }}
  >
    Resend OTP({timer})
  </div>
);

const TimerContainer = ({ children }) => {
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return children(timer, setTimer);
};
export default SurveyOTP;
