import React from "react";
import styled from "styled-components";
import Card from "./Card";
import PropTypes from "prop-types";

const Container = styled.div`
  align-self: stretch;
  display: flex;
  color: "black";
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2.287rem;
  max-width: 100%;
  text-align: center;
  font-size: 2.856rem;

  font-family: var(--heading-h6);

  @media (max-width: 825px) {
    gap: 1.125rem;
  }

  @media (max-width: 1575px) {
    flex-wrap: wrap;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem;
  box-sizing: border-box;
  gap: 2.318rem;
  min-width: 43.938rem;
  max-width: 100%;
  flex-shrink: 0;
  text-align: left;
  font-size: 2.319rem;

  @media (max-width: 825px) {
    gap: 1.188rem;
  }

  @media (max-width: 1250px) {
    min-width: 100%;
  }
`;

const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem;
  box-sizing: border-box;
  gap: 2.4rem;
  max-width: 100%;
  flex-shrink: 0;

  @media (max-width: 825px) {
    gap: 1.188rem;
  }

  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }
`;

const Item = styled.div`
  flex: 1;
  border-radius: 24.03px;
  background-color: #f8f8f8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.206rem 2.437rem;
  box-sizing: border-box;
  min-width: 24.438rem;
  max-width: 100%;

  @media (max-width: 825px) {
    min-width: 100%;
  }
`;

const ItemContent = styled.div`
  align-self: stretch;
  display: flex;
  color: "black";
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.6rem;
`;

const ItemTitle = styled.div`
  align-self: stretch;
  color: #000000;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 2.813rem;
  font-weight: 500;

  @media (max-width: 825px) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  @media (max-width: 450px) {
    font-size: 1.375rem;
    line-height: 1.688rem;
  }
`;

const ItemDescription = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 1.35rem;
  line-height: 180%;
  font-weight: 500;
  font-family: var(--text-small);
  color: #000;

  @media (max-width: 450px) {
    font-size: 1.063rem;
    line-height: 1.938rem;
  }
`;

const ConsumerMerchantItems = ({ className = "" }) => {
  return (
    <Container className={className}>
      <Card
        forConsumers="For Consumers"
        enjoyFlexiblePaymentOptio="Enjoy flexible payment options that "
        suitYourBudget="suit your budget."
        app="/app@2x.png"
      />
      <RightColumn>
        <Row>
          <Item>
            <ItemContent>
              <ItemTitle>Ease of Use</ItemTitle>
              <ItemDescription>
                Apply for loans in minutes with our intuitive app interface.
              </ItemDescription>
            </ItemContent>
          </Item>
          <Item>
            <ItemContent>
              <ItemTitle>Financial Flexibility</ItemTitle>
              <ItemDescription>
                Manage your cash flow better with flexible repayment plans.
              </ItemDescription>
            </ItemContent>
          </Item>
        </Row>
        <Row>
          <Item>
            <ItemContent>
              <ItemTitle>Instant Transactions</ItemTitle>
              <ItemDescription>
                Access credit to make necessary purchases promptly.
              </ItemDescription>
            </ItemContent>
          </Item>
          <Item>
            <ItemContent>
              <ItemTitle>Convenient Access</ItemTitle>
              <ItemDescription>
                Access through a user-friendly app, anytime and anywhere.
              </ItemDescription>
            </ItemContent>
          </Item>
        </Row>
      </RightColumn>
    </Container>
  );
};

ConsumerMerchantItems.propTypes = {
  className: PropTypes.string,
};

export default ConsumerMerchantItems;
