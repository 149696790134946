import React, { useState } from 'react';
import './BusinessDetails.scss';
import greattrlogo from "./assets/logo.png";

const BusinessDetailsForm = () => {
    const [agencyName, setAgencyName] = useState('');
    const [businessEmail, setBusinessEmail] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [businessPan, setBusinessPan] = useState('');
    const [udyamAadhar, setUdyamAadhar] = useState('');
    const [institutionType, setInstitutionType] = useState('');
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');

    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};

        if (!agencyName) newErrors.agencyName = "Agency Name is required";
        if (!businessEmail) newErrors.businessEmail = "Business Email is required";
        if (!gstNumber) newErrors.gstNumber = "GST Number is required";
        if (!businessPan) newErrors.businessPan = "Business PAN is required";
        if (!udyamAadhar) newErrors.udyamAadhar = "Udyam Aadhar is required";
        if (!institutionType) newErrors.institutionType = "Institution Type is required";
        if (!address) newErrors.address = "Address is required";
        if (!state) newErrors.state = "State is required";
        if (!city) newErrors.city = "City is required";
        if (!pincode) newErrors.pincode = "Pincode is required";

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
            // Handle form submission logic here
            console.log({
                agencyName,
                businessEmail,
                gstNumber,
                businessPan,
                udyamAadhar,
                institutionType,
                address,
                state,
                city,
                pincode
            });
        }
    };

    return (
        <div className="business-company-info">
            <div className="business-company-info-container">
                <img
                    style={{
                        width: "20%",
                    }}
                    src={greattrlogo}
                    alt=""
                />
                <p className='paragraph-el'>2 / 5</p>
                <h3>Business Details</h3>
                <p className='sub-heading paragraph-el'>Enter your personal information below to proceed.</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <label className='label-font'>
                            Agency Name*
                            <input
                                type="text"
                                value={agencyName}
                                onChange={(e) => setAgencyName(e.target.value)}
                                placeholder='Enter Agency Name'
                                className="placeholder-font"
                                required
                            />
                            {errors.agencyName && <span className="error">{errors.agencyName}</span>}
                        </label>
                        <label className='label-font'>
                            Business Email ID*
                            <input
                                type="email"
                                value={businessEmail}
                                onChange={(e) => setBusinessEmail(e.target.value)}
                                placeholder='Enter Business Email ID'
                                className="placeholder-font"
                                required
                            />
                            {errors.businessEmail && <span className="error">{errors.businessEmail}</span>}
                        </label>
                    </div>
                    <div className="form-row">
                        <label className='label-font'>
                            GST Number*
                            <input
                                type="text"
                                value={gstNumber}
                                onChange={(e) => setGstNumber(e.target.value)}
                                placeholder='Enter GST Number'
                                className="placeholder-font"
                                required
                            />
                            {errors.gstNumber && <span className="error">{errors.gstNumber}</span>}
                        </label>
                        <label className='label-font'>
                            Business PAN*
                            <input
                                type="text"
                                value={businessPan}
                                onChange={(e) => setBusinessPan(e.target.value)}
                                placeholder='Enter Business PAN'
                                className="placeholder-font"
                                required
                            />
                            {errors.businessPan && <span className="error">{errors.businessPan}</span>}
                        </label>
                    </div>
                    <div className="form-row">
                        <label className='label-font'>
                            Udyam Aadhar Number*
                            <input
                                type="text"
                                value={udyamAadhar}
                                onChange={(e) => setUdyamAadhar(e.target.value)}
                                placeholder='Enter Udyam Aadhar Number'
                                className="placeholder-font"
                                required
                            />
                            {errors.udyamAadhar && <span className="error">{errors.udyamAadhar}</span>}
                        </label>
                        <label className='label-font'>
                            Institution Type*
                            <select
                                value={institutionType}
                                onChange={(e) => setInstitutionType(e.target.value)}
                                className="placeholder-font"
                                required
                            >
                                <option value="" >Select Institution Type</option>
                                <option value="type1">Type 1</option>
                                <option value="type2">Type 2</option>
                                <option value="type3">Type 3</option>
                            </select>
                            {errors.institutionType && <span className="error">{errors.institutionType}</span>}
                        </label>
                    </div>
                    <div className="form-row">
                        <label className='label-font'>
                            Address*
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder='Enter Address'
                                className="placeholder-font"
                                required
                            />
                            {errors.address && <span className="error">{errors.address}</span>}
                        </label>
                    </div>
                    <div className="form-row">
                        <label className='label-font'>
                            State*
                            <select
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                placeholder='Select State'
                                className="placeholder-font"
                                required
                            >
                                <option value="" disabled>Select State</option>
                                <option value="state1">State 1</option>
                                <option value="state2">State 2</option>
                                <option value="state3">State 3</option>
                            </select>
                            {errors.state && <span className="error">{errors.state}</span>}
                        </label>
                        <label className='label-font'>
                            City*
                            <input
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder='Select City'
                                className="placeholder-font"
                                required
                            />
                            {errors.city && <span className="error">{errors.city}</span>}
                        </label>
                        <label className='label-font'>
                            Pincode*
                            <input
                                type="text"
                                value={pincode}
                                onChange={(e) => setPincode(e.target.value)}
                                placeholder='Enter Pincode'
                                className="placeholder-font"
                                required
                            />
                            {errors.pincode && <span className="error">{errors.pincode}</span>}
                        </label>
                    </div>
                    <button className='business-button' type="submit">Continue</button>
                </form>
            </div>
        </div>
    );
};

export default BusinessDetailsForm;
