import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.431rem;
  min-width: 17.625rem;
  max-width: 100%;
  text-align: left;
  font-size: 1.431rem;
  color: var(--gray);
  font-family: var(--font-heading-h6);
`;

const Icon = styled.img`
  width: 3.569rem;
  height: 3.569rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`;

const TextContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 1.714rem;
  font-weight: 500;

  @media (max-width: 450px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

const Description = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 1.069rem;
  line-height: 1.982rem;
`;

const StepIconsParent = ({
  className = "",
  googlePlaySvgrepoCom1,
  heading3SpecialFinancingO,
  getStartedByDownloadingTh,
}) => {
  return (
    <Container className={className}>
      <Icon loading="lazy" alt="" src={googlePlaySvgrepoCom1} />
      <TextContainer>
        <Heading>{heading3SpecialFinancingO}</Heading>
        <Description>{getStartedByDownloadingTh}</Description>
      </TextContainer>
    </Container>
  );
};

StepIconsParent.propTypes = {
  className: PropTypes.string,
  googlePlaySvgrepoCom1: PropTypes.string,
  heading3SpecialFinancingO: PropTypes.string,
  getStartedByDownloadingTh: PropTypes.string,
};

export default StepIconsParent;
