import React from "react";
import "./LicenseTemplate.scss";
import logo from "../assets/logo-Colored2.png";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";

const FairPracticeCode = () => {
  return (
    <>

      <div className="licensetemplate">
        <img src={logo} style={{ width: "80%" }} />
        <br />
        <h1>
          <br />
          Fair Practice Code (“FPC” or “Code”)
          <br />
        </h1>
        <div className="licenseContainer">
          The Reserve Bank of India (RBI) has issued guidelines on Fair
          Practices Code for Non-Banking Finance Companies (NBFCs) thereby
          setting standards for fair business and corporate practices while
          dealing with their customers vide RBI Master Directions
          DNBR.PD.007/03.10.119/2016-17 dated September 01, 2016, as amended,
          and updated from time to time.
          <br />
          <br />
          Jainex Finance Limited (“Company”) has adopted all the best practices
          prescribed by Reserve Bank of India and shall make appropriate
          modifications, if any necessary, to this Code to conform to the
          standards so prescribed. The Company will also communicate its Fair
          Practice Code (“FPC” or “Code”) to its customers by uploading the FPC
          on its website/mobile application.
          <br />
          <br />
          The Company is having customer interface and shall therefore, adopt
          the following guidelines:
          <br />
          <br />
          <ol type="1">
            <li>
              <b>Applications for loans and their processing:</b>
            </li>
            <br />
            <ol type="a">
              <li>
                All communications to the borrower shall be in the vernacular
                language or a language as understood by the borrower.
              </li>
              <li>
                Loan application forms shall include necessary information which
                affects the interest of the borrower, so that a meaningful
                comparison with the terms and conditions offered by other NBFCs
                can be made and informed decision can be taken by the borrower.
              </li>
              <li>
                The loan application form shall indicate the documents required
                to be submitted with the application form.
              </li>
              <li>
                The Company shall devise a system of giving acknowledgement for
                receipt of all loan applications. Preferably, the time frame
                within which loan applications will be disposed of shall also be
                indicated in the acknowledgement.
              </li>
            </ol>
            <li>
              <b>Loan appraisal and terms/conditions:</b>
            </li>
            <br />
            <ol type="a">
              <li>
                The Company shall convey in writing to the borrower in the
                vernacular language as understood by the borrower by means of
                sanction letter or otherwise, the amount of loan sanctioned
                along with the terms and conditions including annualised rate of
                interest and method of application thereof and keep the
                acceptance of these terms and conditions by the borrower on its
                record. As complaints received against NBFCs generally pertain
                to charging of high interest / penal interest, the Company shall
                mention the penal interest charged for late repayment in bold in
                the loan agreement.
              </li>
              <li>
                The Company shall furnish a copy of the loan agreement or
                enclosures quoted in the loan agreement. The Company shall
                furnish a copy of the loan agreement as understood by the
                borrower along with a copy each of all enclosures quoted in the
                loan agreement to all the borrowers at the time of sanction /
                disbursement of loans.
              </li>
            </ol>
            <li>
              <b>
                Disbursement of loans including changes in terms and conditions:
              </b>
            </li>
            <br />
            <ol type="a">
              <li>
                a. The Company shall give notice to the borrower in the
                vernacular language or a language as understood by the borrower
                of any change in the terms and conditions including disbursement
                schedule, interest rates, service charges, prepayment charges
                etc. The Company shall also ensure that changes in interest
                rates and charges are affected only prospectively. A suitable
                condition in this regard must be incorporated in the loan
                agreement.
              </li>
              <li>
                b. Decision to recall / accelerate payment or performance under
                the agreement shall be in consonance with the loan agreement.
              </li>
              <li>
                c. The Company shall release all securities on repayment of all
                dues or on realisation of the outstanding amount of loan subject
                to any legitimate right or lien for any other claim they may
                have against borrower. If such right of set off is to be
                exercised, the borrower shall be given notice about the same
                with full particulars about the remaining claims and the
                conditions under which the Company is entitled to retain the
                securities till the relevant claim is settled/ paid.
              </li>
            </ol>
            <li>
              <b>General</b>
            </li>
            <br />
            <ol type="a">
              <li>
                The Company shall refrain from interference in the affairs of
                the borrower except for the purposes provided in the terms and
                conditions of the loan agreement (unless information, not
                earlier disclosed by the borrower, has been noticed).
              </li>
              <li>
                In case of receipt of a request from the borrower for transfer
                of the borrowed account, the consent or otherwise i.e. objection
                of the Company, if any, shall be conveyed within 21 days from
                the date of receipt of the request. Such transfer shall be as
                per transparent contractual terms in consonance with law.
              </li>
              <li>
                In the matter of recovery of loans, the Company shall not resort
                to undue harassment viz; persistently bothering the borrowers at
                odd hours, use muscle power for recovery of loans, etc. In case
                the complaints from customers include rude behaviour from the
                staff of the companies, the Company shall ensure that the staff
                is adequately trained to deal with the customers in an
                appropriate manner.
              </li>
              <li>
                As a measure of customer protection and in order to bring in
                uniformity about prepayment of various loans by borrowers of
                banks and NBFCs, the Company shall not charge foreclosure
                charges/ pre-payment penalties on any floating rate term loan
                sanctioned for purposes other than business to individual
                borrowers, with or without co-obligant(s).
              </li>
            </ol>
            <br />
            <li>
              <b>Responsibility of Board of Directors:</b>
            </li>
            The Board of Directors of the Company shall also lay down the
            appropriate grievance redressal mechanism within the organization.
            Such a mechanism shall ensure that all disputes arising out of the
            decisions of lending institutions' functionaries are heard and
            disposed of at least at the next higher level. The Board of
            Directors shall also provide for a periodical review of the
            compliance of the Fair Practices Code and the functioning of the
            grievance’s redressal mechanism at various levels of management. A
            consolidated report of such reviews shall be submitted to the Board
            at regular intervals, as may be prescribed by it.
            <li>
              <b>Grievance Redressal Officer:</b>
            </li>
            The Company shall display the following information prominently, for
            the benefit of their customers, at their branches / places where
            business is transacted:
            <br />
            <ol type="a">
              <li>
                The name and contact details (Telephone / Mobile nos. as also
                email address) of the Grievance Redressal Officer who can be
                approached by the public for resolution of complaints against
                the Company.
              </li>
              <li>
                If the complaint / dispute is not redressed within a period of
                one month, the customer may appeal to the Officer-in-Charge of
                the Regional Office of Department of Non-Banking Supervision of
                the Bank (with complete contact details), under whose
                jurisdiction the registered office of the Company falls.
              </li>
            </ol>
            <li>
              <b>Nodal Officer/ Principal Nodal Officer:</b>
            </li>
            The Company shall appoint Nodal Officer/ Principal Nodal Officer, if
            required in accordance with law.
            <li>
              <b>Regulation of excessive interest charged by the Company:</b>
            </li>
            <ol type="a">
              <li>
                The Board of the Company shall adopt an interest rate model
                considering relevant factors such as cost of funds, margin and
                risk premium and determine the rate of interest to be charged
                for loans and advances. The rate of interest and the approach
                for gradations of risk and rationale for charging different rate
                of interest to different categories of borrowers shall be
                disclosed to the borrower or customer in the application form
                and communicated explicitly in the sanction letter.
              </li>
              <li>
                The rates of interest and the approach for gradation of risks
                shall also be made available on the web-site of the companies or
                published in the relevant newspapers. The information published
                in the website or otherwise published shall be updated whenever
                there is a change in the rates of interest.
              </li>
              <li>
                The rate of interest must be annualised rate so that the
                borrower is aware of the exact rates that would be charged to
                the account.
              </li>
            </ol>
            <li>
              <b>Complaints about excessive interest charged by the Company:</b>
            </li>
            Boards of the Company shall lay out appropriate internal principles
            and procedures in determining interest rates and processing and
            other charges. In this regard the guidelines indicated in the Fair
            Practices Code about transparency in respect of terms and conditions
            of the loans are to be kept in view.
            <li>
              <b>
                Boards of the Company shall lay out appropriate internal
                principles and procedures in determining interest rates and
                processing and other charges. In this regard the guidelines
                indicated in the Fair Practices Code about transparency in
                respect of terms and conditions of the loans are to be kept in
                view.
              </b>
            </li>
            <ol type=" a">
              <li>
                For no fee or interest charged to them, if the payment is made 5
                days prior to the first payment date.
              </li>
              <li>
                Post the first payment date the customer shall be required to
                made payment with applicable interest (based on the tenure
                customer has chosen at the time of applying).
              </li>
            </ol>
            <li>
              <b>
                Pursuant to Outsourcing policy issued by Reserve Bank of India,
                the Company has outsourced certain activities (including but not
                limited to lead generation, customer support, servicing,
                collections, business correspondent activities, technology
                services etc) to OneAgrow Fintech Private Limited, which is an
                affiliated / associate entity within the same corporate group of
                the Company.
              </b>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default FairPracticeCode;
