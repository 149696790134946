import React, { useState } from "react";
import "./UploadDocuments.scss";
import greattrlogo from "./assets/logo.png";
import docuinfo from "./assets/docinfo.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const UploadDocuments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(() => {
    // Load the state from local storage or use a default value
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  console.log("state", state);
  const [selectedFiles, setSelectedFiles] = useState({
    selfie: null,
    // cheque: null,
    // gst: null,
    // msme: null,
    pan: null,
    aadhaar: null,
  });

  console.log(Object.values(selectedFiles));
  const fileSizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const inputChange = (e, type) => {
    const file = e.target.files[0];

    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      alert(`${file.name} is too large. Please select a file less than 5MB.`);
      return;
    }
    if (!file.type.match(/image\/(jpg|jpeg|png|gif|svg)|application\/pdf/)) {
      alert(
        `${file.name} is not a supported format. Please select an image or PDF.`
      );
      return;
    }

    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [type]: file,
    }));
  };

  const deleteFile = (type) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: null,
      }));
    }
  };

  const fileUploadSubmit = async (e) => {
    e.preventDefault();

    const requiredFiles = ["selfie", "aadhaar"];
    const allFilesSelected = requiredFiles.every(
      (fileKey) => selectedFiles[fileKey] !== null
    );

    if (!allFilesSelected) {
      alert("Please select all required files before submitting.");
      return;
    }

    const formData = new FormData();
    Object.keys(selectedFiles).forEach((key) => {
      if (selectedFiles[key]) {
        formData.append(key, selectedFiles[key]);
      }
    });

    formData.append("GreattrOperatorId", state?.GreattrOperatorId);

    try {
      const response = await axios.post(
        `https://api.greattr.com/uploadOperatorDocuments`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle success response here
      console.log("Upload successful:", response.data);

      if (response.status === 200 && response.data.results) {
        alert("Documents uploaded successfully!");
        navigate("/FinalsET", {
          state: {
            MobileNumber: state.MobileNumber,
            ...state,
          },
        });
        // Reset form or redirect as needed
      } else {
        throw new Error("Documents upload failed or no files were uploaded");
      }
    } catch (error) {
      // Handle error
      console.error("Error uploading files:", error);
      alert("Error uploading files. Please try again later.");
    }
  };

  const renderFileInput = (label, type) => (
    <div className="dc-file-upload-section">
      <label className="dc_label">{label}</label>
      <div className="kb-file-upload">
        {!selectedFiles[type] && (
          <div className="file-upload-box">
            <input
              type="file"
              id="fileupload"
              className="file-upload-input"
              onChange={(e) => inputChange(e, type)}
            />
            <span className="file-link2">
              Drag and drop or{" "}
              <span className="file-link">Choose your file</span>
            </span>
          </div>
        )}
      </div>

      {selectedFiles[type] && (
        <div className="dc-file-preview">
          <div className="dc-file-detail">
            <h6>{selectedFiles[type].name}</h6>
            <p>
              <span>Size: {fileSizes(selectedFiles[type].size)}</span>
              <span className="ml-2">
                Modified Time:{" "}
                {selectedFiles[type].lastModifiedDate?.toLocaleString("en-IN")}
              </span>
            </p>
            <div className="dc-file-actions">
              <button
                type="button"
                className="dc-file-action-btn"
                onClick={() => deleteFile(type)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="uploaddocs_company-info">
      <div className="uploaddocs_company-info-container">
        <img style={{ width: "20%" }} src={greattrlogo} alt="GreatTR Logo" />
        <br />
        <p style={{ margin: 0 }}>3 / 4</p>
        <h3 style={{ margin: 0 }}>Document Upload</h3>
        <p className="sub-heading">
          Please refer to the points below while uploading the documents
        </p>
        <img
          className="UploadDocumentInstructionImg"
          src={docuinfo}
          alt="Document Info"
        />
        <form onSubmit={fileUploadSubmit}>
          <div className="dc-doc-btns-form">
            {renderFileInput("Applicant Selfie*", "selfie")}
            {/* {renderFileInput("Cancelled Cheque*", "cheque")}
            {renderFileInput("GST*", "gst")}
            {renderFileInput("MSME Certificate*", "msme")} */}
            {renderFileInput("PAN", "pan")}
            {renderFileInput("Aadhaar Card*", "aadhaar")}
          </div>
          <div className="dc-kb-buttons-box dc-doc-btns-buttons-box">
            <button
              type="submit"
              className="btn btn-primary form-submit dc-doc-btns-submit-button"
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadDocuments;
