import styled from "styled-components";
import StepIconsParent from "./StepIconsParent";

const Section = styled.section`
  background: #f5f1fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 4rem;
  padding-left: 4.187rem;
  padding-right: 4.187rem;
  padding-bottom: 4rem;
  box-sizing: border-box;
  // min-height: 43.313rem;
  max-width: 100%;
  text-align: center;
  font-size: 5.713rem;
  color: var(--text-default);
  font-family: var(--heading-h6);
  @media (max-width: 825px) {
    gap: 1.313rem;
    padding-bottom: 5.188rem;
  }
  @media (max-width: 1250px) {
    gap: 2.688rem;
    padding-left: 2.063rem;
    padding-right: 2.063rem;
  }
`;

const InnerDiv = styled.div`
  border-radius: 18.29px;
  background: "#FFF";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 3.568rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 8.212rem;
  box-sizing: border-box;
  gap: 5.5rem;
  max-width: 100%;
  @media (max-width: 825px) {
    gap: 2.75rem;
    padding-top: 2.313rem;
    padding-bottom: 5.313rem;
  }
  @media (max-width: 450px) {
    gap: 1.375rem;
  }
`;

const TitleDiv = styled.div`
  // width: 83rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
`;

const Title = styled.div`
  position: relative;
  tracking: -0.03em;
  line-height: 6.313rem;
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
  @media (max-width: 825px) {
    font-size: 2.875rem;
    line-height: 3.75rem;
  }
  @media (max-width: 450px) {
    font-size: 1.688rem;
    line-height: 2.5rem;
  }
`;

const StepIconsContainer = styled.div`
  // width: 98.794rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5.287rem;
  max-width: 100%;
  text-align: left;
  font-size: 1.431rem;
  color: var(--gray);
  @media (max-width: 825px) {
    gap: 1.625rem;
  }
`;

const Apply = ({ className = "" }) => {
  return (
    <Section id="HowToApply" className={className}>
      <InnerDiv>
        <TitleDiv>
          <Title>How to apply?</Title>
        </TitleDiv>
        <StepIconsContainer>
          <StepIconsParent
            googlePlaySvgrepoCom1="/applyicon.svg"
            heading3SpecialFinancingO="Download Greattr App"
            getStartedByDownloadingTh="Get started by downloading the Greattr app from the App Store or Google Play Store."
          />
          <StepIconsParent
            googlePlaySvgrepoCom1="/applyicon-1.svg"
            heading3SpecialFinancingO="Create Your Account"
            getStartedByDownloadingTh="Create your account and provide basic information to set up your profile."
          />
          <StepIconsParent
            googlePlaySvgrepoCom1="/applyicon-2.svg"
            heading3SpecialFinancingO="Choose Your Product"
            getStartedByDownloadingTh="From daily needs to big requirements, choose the type of product you need."
          />
          <StepIconsParent
            googlePlaySvgrepoCom1="/applyicon-3.svg"
            heading3SpecialFinancingO="Apply For Loan"
            getStartedByDownloadingTh="Once applied, you'll receive confirmation and approval details from our team."
          />
        </StepIconsContainer>
      </InnerDiv>
    </Section>
  );
};

export default Apply;
