import React from "react";
import "./LicenseTemplate.scss";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/logo-Colored2.png";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
const PlatformDeclaration = () => {
  return (
    <>

      <div className="licensetemplate">
        <img src={logo} style={{ width: "80%" }} />
        <br />
        <h1>
          <br />
          Platform Declaration
          <br />
        </h1>
        <div className="licenseContainer">
          I confirm my understanding that OneAgrow Fintech Private Limited
          ("OneAgrow" or "Greattr" or Greattr Platform(s)) is not a financial
          institution or a Non-Banking Financial Services Company and therefore
          does not provide or is obliged to provide any financial services or
          credit facilities. I confirm my understanding that Greattr
          Platform(s), which include the website www.Greattr.com or the Greattr
          App, is operated by OneAgrow, and I have no objections in OneAgrow
          acting as a Lender's Agent or in any other capacity as OneAgrow deem
          fit. I confirm my understanding that through Greattr Platform(s),
          OneAgrow is acting as a Lender's Agent and also offer internet-based
          technology support as found in the Greattr Platform(s) (without any
          warranties). I confirm my understanding that OneAgrow will be entitled
          to act as customer support, collection agent, sales agent, technology
          support and for such other support/services as deem fit by the
          OneAgrow. I confirm my consent to OneAgrow to perform such functions
          through electronic or non-electronic means, including Greattr
          Platform(s) or through its service providers or through such other
          means as deem fit by OneAgrow from time to time. I confirm that while
          applying for the Credit Line through Greattr Platform(s), I have
          visited the partner lending page on Greattr Platform(s) and I have
          also visited the relevant lender's website and have perused the
          relevant details required for my understanding and confirmation to be
          granted for availing the Credit Line. I will pay a platform fee to
          Greattr as may be applicable from time to time. I also authorize
          Greattr to deduct the same from my accounts as and when required, and
          I declare that such deductions shall be binding on me. I have the
          ability and have understood each and every term of the Financing
          Documents and Greattr Terms for availing Credit Line through Greattr
          Platform(s) and have the capacity to fulfill Obligations pursuant to
          me availing the Credit Line through Greattr Platform(s). I have read
          and understood each of the terms applicable for my use of Greattr
          Platform(s) and for availing Credit Line from Lenders(s) on Greattr
          Platform(s), and wherever required, I have understood the same in my
          vernacular language and pursuant to that I have given my confirmations
          to such terms.
        </div>

      </div>
    </>
  );
};

export default PlatformDeclaration;
