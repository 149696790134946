import React, { useState, useEffect } from "react";
import "./CompanyInfo.scss";
import greattrlogo from "./assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { GES, GESRecords } from "../Utilsx/Encrypt";
import { maskAadhaar } from "./utils/validations";
import axios from "axios";
const FinalSet = () => {
  let domain = `https://api.greattr.com`;
  // let domain = `http://localhost:3000`;
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(() => {
    // Load the state from local storage or use a default value
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  console.log("state", state);
  const [fwdp, setfwdp] = useState(null);
  const [transactionId, settransactionId] = useState(null);
  const [otp, setOtp] = useState(new Array(6).fill("")); // Initialize OTP state
  const [otpError, setOtpError] = useState(null); // State for error messages
  const [isLoading, setisLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // State for captcha
  const [isOtpSent, setisOtpSent] = useState(false);
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;
  const [okycdata, setokycdata] = useState(null);
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false; // Only allow numeric input
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus on the next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const generateCaptcha = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_LINK}/kyc/generate-captcha`,
        method: "POST",
        responseType: "json",
        data: {
          uid: state?.AadhaarNumber.replace(/-/g, ""),
          uniqueId: state?.MobileNumber || state?.GreattrOperatorId,
        },
      });
      if (response.status !== 200) {
        alert("Some problem has occured . Please try again later");
      } else {
        console.log("/kyc/generate-captcha", response?.data);
        setCaptchaValue("");
        setokycdata(response?.data);
      }
    } catch (err) {
      if (err.response) {
        // Server responded with a status other than 2xx
        alert(err.response.data.error);
      } else if (err.request) {
        // Request was made but no response was received
        alert("No response received. Please check your network connection.");
      } else {
        // Something else happened
        alert("An error occurred. Please try again.");
      }
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const submitOkycOtp = async () => {
    try {
      console.log("lassii");
      setisLoading(true);
      console.log({
        ...okycdata,
        maskedAadharNumber: maskAadhaar(state?.AadhaarNumber.replace(/-/g, "")),
        otp: otp.join(""),
        p1data: {
          ...state,
          maskedAadharNumber: maskAadhaar(
            state?.AadhaarNumber.replace(/-/g, "")
          ),
        },
      });
      const SubmitOkycResponse = await axios.post(
        `${domain}/kyc/operator-download-okyc`,
        {
          ...okycdata,
          maskedAdharNumber: maskAadhaar(state?.AadhaarNumber),
          otp: otp.join(""),
          p1data: GESRecords(
            {
              ...state,
              maskedAadharNumber: maskAadhaar(
                state?.AadhaarNumber.replace(/-/g, "")
              ),
            },
            apiEN
          ),
        }
      );

      console.log(SubmitOkycResponse);

      if (SubmitOkycResponse.status === 200) {
        console.log("----------------- Submit OTP response");
        navigate("/AccountCreatedSuccessfully");
        setisLoading(false);
        // console.log("response data ", response.data);
      } else {
        alert("An error occurred. Please try again.");
      }
    } catch (error) {
      console.log(error);
      alert("error");
      throw error;
    } finally {
      setisLoading(false);
    }
  };

  const getOtp = async () => {
    try {
      if (!captchaValue) {
        alert("Please complete the captcha.");
        return;
      }

      setisLoading(true);

      // Verify the captcha
      const captchaResponse = await axios.post(
        `${process.env.REACT_APP_API_LINK}/kyc/generate-otp`,
        {
          captcha: captchaValue,
          uid: state.AadhaarNumber,

          ...okycdata,
        }
      );

      console.log("get otp response", captchaResponse);

      console.log("response data", captchaResponse.data);

      if (
        captchaResponse.data.statusCode === 200 &&
        captchaResponse.data.status === true &&
        captchaResponse.data.message ===
        "Successfully generated One Time Password (OTP)."
      ) {
        setokycdata(captchaResponse.data);
        setisOtpSent(true);
        alert("OTP Sent Successfully");

        return;
      }
      console.log("OKYC data", okycdata);

      if (captchaResponse.data.statusCode !== 200) {
        alert("Captcha verification failed. Please try again.");
        return;
      } else {
        alert(captchaResponse.data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setisLoading(false);
    }
  };

  // const ResendAadharOtp = async () => {
  //   try {
  //     alert(okycdata?.model.transactionId);
  //     const response = await axios({
  //       // url: `${process.env.REACT_APP_API_LINK}/okyc-initiate-otp`,
  //       url: "https://api.greattr.com/operator-okyc-resend-otp",
  //       method: "POST",
  //       responseType: "json",
  //       data: {
  //         uid: state?.AadhaarNumber.replace(/-/g, ""),
  //         uniqueId: state?.MobileNumber || state?.GreattrOperatorId,
  //         transactionId: okycdata?.model.transactionId,
  //         fwdp: okycdata?.model.fwdp,
  //       },
  //     });
  //     if (response.status === 500) {
  //       alert("Some problem has occured . Please try again later");
  //     } else {
  //       alert(response?.data?.message);
  //       console.log(response?.data?.response);
  //       // setokycdata(response?.data?.response);
  //       setOtp(new Array(6).fill(""));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const ResendAadharOtp = async () => {
    setisOtpSent(false);
    generateCaptcha();
    setOtp(new Array(6).fill(""));
  };

  useEffect(() => {
    generateCaptcha();
  }, []);
  return (
    <div className="company-info">
      <div className="company-info-container">
        <img className="greattrlogo" src={greattrlogo} alt="" />
        <p>4 / 4</p>
        <h3
          style={{
            padding: "2rem",
          }}
        >
          Final Step
        </h3>
        <p className="sub-heading">
          Enter the OTP you just received on your mobile number linked to your
          aadhar number
        </p>
        <div
          style={{
            display: isOtpSent ? "none" : "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <img
              src={`data:image/png;base64,${okycdata?.encodedCaptcha}`}
              alt="captcha"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/svg/reload.svg"}
              alt="SVG Icon"
              onClick={generateCaptcha}
              width={40}
              className="img-responsive"
            />
          </div>

          <br />
          <input
            type="text"
            value={captchaValue}
            onChange={(e) => setCaptchaValue(e.target.value)}
            placeholder="Enter Captcha Value"
            className="placeholder-font"
            required
          />
          <br />
          <button
            style={{ padding: 15 }}
            className="continue-button"
            onClick={getOtp}
            disabled={isLoading}
          // disabled={isOtpSent ? true : false}
          >
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Get OTP"
            )}
          </button>
        </div>

        {isOtpSent && (
          <div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1.5rem",
                flex: 1,
                height: "10%",
                padding: 10,
                width: "100%",
              }}
            >
              {otp.map((data, index) => (
                <input
                  key={index}
                  className="otpstyle"
                  type="text"
                  name="otp"
                  maxLength="1"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            {otpError && <p className="error">{otpError}</p>}
            <p className="sub-heading" onClick={ResendAadharOtp}>
              Resend OTP?
            </p>

            <br />
            <button
              style={{ padding: 15 }}
              className="continue-button"
              onClick={submitOkycOtp}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalSet;
